import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { Input, WhiteP } from "../../../components/index";
import Wrapper from "../Components/Wrapper";
import messages from "./messages";
import { required } from "../../../validators/index";
import PasswordVerification from "../Components/PasswordVerification";
import ButtonWrapper from "../Components/ButtonWrapper";
import { SmallPinkButton, SmallGrayButton } from "../../../components/Buttons";
import { status } from "../../../auth/reducer";
import { colors } from "../../../constants/styling-constants";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

const StyledError = styled.p`
  color: ${colors.pink};
`;

class ChangePassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: "",
    };

    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
  }

  onPasswordChange(e, fieldValue) {
    this.setState({ password: fieldValue });
  }

  goToLogin() {
    this.props.resetAuth();
    this.props.history.push("/login");
  }

  renderError() {
    if (this.props.auth.status === status.CHANGE_PASSWORD_FAILED) {
      let message = messages.error[this.props.auth.error.code];
      if (!message) {
        message = messages.error.default;
        console.warn("label not found for error:", this.props.auth.error.code);
      }
      return <StyledError><FormattedMessage {...message} /></StyledError>;
    }

    return null;
  }

  render() {
    if (!this.props.auth.user) {
      return <Redirect to="/login" />;
    }
    if (this.props.auth.status === status.LOGGED_IN) {
      return <Redirect to="/my-pages/events" />;
    }

    return (
      <Wrapper>
        <WhiteP><FormattedMessage {...messages.infoText} /></WhiteP>
        <form
          onSubmit={this.props.handleSubmit}
          noValidate
        >
          <Field
            name="newPassword"
            placeholder={this.props.intl.formatMessage(messages.form.newPassword)}
            type="password"
            component={Input}
            validate={[required]}
            onChange={this.onPasswordChange}
          />
          <PasswordVerification
            intl={this.props.intl}
            password={this.state.password}
          />
          {this.renderError()}
          <ButtonWrapper>
            <SmallPinkButton
              busy={this.props.auth.status === status.CHANGING_PASSWORD}
              label={this.props.intl.formatMessage(messages.changePasswordButton)}
            />
            <SmallGrayButton onClick={this.goToLogin}><FormattedMessage {...messages.loginButton} /></SmallGrayButton>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  }

}

ChangePassword.propTypes = {
  auth: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Redux form function that will call this.props.onSubmit if form is valid
};

let ChangePasswordForm = reduxForm({
  form: "change-password",
})(injectIntl(ChangePassword));

ChangePasswordForm = connect(
  state => ({
    initialValues: {
      user: state.auth.user,
    },
  })
)(ChangePasswordForm);

export default ChangePasswordForm;

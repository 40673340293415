import { defineMessages } from 'react-intl';

export default defineMessages({
  headerTitle: {
    id: 'faq.header-title',
  },
  volunteerPageTitle: {
    id: 'faq-volunteer.page-title',
  },
  organizerPageTitle: {
    id: 'faq-organizer.page-title',
  },
});

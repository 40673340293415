import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Input, WhiteP } from "../../../components/index";
import { SmallPinkButton } from "../../../components/Buttons/index";
import { SmallGrayLinkButton, WhiteLink } from "../../../components/Links";
import { status } from "../../../auth/reducer";
import { required } from "../../../validators/index";
import Wrapper from "../Components/Wrapper";
import ButtonWrapper from "../Components/ButtonWrapper";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import connect from "react-redux/lib/connect/connect";
import { colors } from "../../../constants/styling-constants";
import styled from "styled-components";

const StyledError = styled.p`
  color: ${colors.pink};
`;

class Login extends Component {
  renderError() {
    if (this.props.auth.status === status.LOGIN_FAILED) {
      // If UserNotConfirmedException, redirect to confirmation page
      if (this.props.auth.error.code === "UserNotConfirmedException") {
        return <Redirect to="/verify-email" />;
      }

      let message = messages.error[this.props.auth.error.code];
      if (!message) {
        message = messages.error.default;
        console.warn("label not found for error:", this.props.auth.error.code);
      }
      return <StyledError><FormattedMessage {...message} /></StyledError>;
    }

    return null;
  }

  renderResetPasswordText() {
    if (this.props.location.search && this.props.location.search.indexOf("password-reset") > -1) {
      return (<WhiteP><FormattedMessage {...messages.resetPasswordInfo} /></WhiteP>)
    }

    return null;
  }

  render() {
    if (this.props.auth.status === status.LOGGED_IN) {
      // return <Redirect to="/my-pages/events" />;
      return <Redirect to="/" />;
    }
    if (this.props.auth.status === status.NEW_PASSWORD_REQUIRED) {
      return <Redirect to={`/change-password`} />;
    }
    return (
      <Wrapper>
        {this.renderResetPasswordText()}
        <form
          onSubmit={this.props.handleSubmit}
          noValidate
        >
          <Field
            name="email"
            placeholder={this.props.intl.formatMessage(messages.form.email)}
            type="email"
            component={Input}
            validate={[required]}
          />
          <Field
            name="password"
            placeholder={this.props.intl.formatMessage(messages.form.password)}
            type="password"
            component={Input}
            validate={[required]}
          />
          {this.renderError()}
          <ButtonWrapper>
            <SmallPinkButton
              label={this.props.intl.formatMessage(messages.loginButton)}
              busy={this.props.auth.status === status.LOGGING_IN}
            />
            <SmallGrayLinkButton to="/register"><FormattedMessage {...messages.registerButton} /></SmallGrayLinkButton>
          </ButtonWrapper>
          <WhiteLink to="/forgot-password"><FormattedMessage {...messages.forgotPassword} /></WhiteLink>
        </form>
      </Wrapper>
    );
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Redux form function that will call this.props.onSubmit if form is valid
};

let LoginForm = reduxForm({
  form: "login",
})(injectIntl(Login));

LoginForm = connect(
  state => ({
    initialValues: {
      email: state.register.email,// || state.forgotPassword.email || '',
      password: state.register.password,// || state.forgotPassword.password || '',
    },
  })
)(LoginForm);

export default LoginForm;

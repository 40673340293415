import { defineMessages } from 'react-intl';

export default defineMessages({
  form: {
    email: {
      id: 'login.form.email.placeholder',
    },
    verificationCode: {
      id: 'login.form.verificationCode.placeholder',
    },
  },
  popupReminder: {
    title: {
      id: 'register.verificationReminder.title'
    },
    body: {
      id: 'register.verificationReminder.body'
    }
  },
  confirmRegistrationButtom: {
    id: 'login.confirm-registration-button',
  },
  resendConfirmationCodeButton: {
    id: 'login.resend-cofirmation-code-button',
  },
  error: {
    UserNotFoundException: {
      id: 'login.error.user-not-found',
    },
    ResourceNotFoundException: {
      id: 'login.error.cognito-service-not-found',
    },
    UserNotConfirmedException: {
      id: 'login.error.user-not-confirmed',
    },
    CodeMismatchException: {
      id: 'login.error.code-mismatch',
    },
    default: {
      id: 'login.error.unknown-error',
    },
    activationCodeError: {
      id: 'register.error.activation-code-error',
    }
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  form: {
    title: {
      id: 'send-outs.form.title',
    },
    previousSendOuts: {
      id: 'send-outs.form.previous-send-outs',
    },
    info: {
      id: 'send-outs.form.info',
    },
    infoItalic: {
      id: 'send-outs.form.info-italic',
    },
    receivers: {
      title: {
        id: 'send-outs.form.receivers-title',
      },
      info: {
        id: 'send-outs.form.receivers-info',
      }
    },
    header: {
      name: {
        id: 'send-outs.form.header-name',
      },
      age: {
        id: 'send-outs.form.header-age',
      },
      phone: {
        id: 'send-outs.form.header-phone',
      },
      email: {
        id: 'send-outs.form.header-email',
      },
    },
    recipientTypes: {
      id: 'send-outs.form.recipient-types',
    },
    recipientTypeOptions: {
      VOLUNTEERS: {
        id: 'send-outs.form.recipient-type-options.volunteers',
      },
      CONTACT_PERSONS: {
        id: 'send-outs.form.recipient-type-options.contact-persons',
      },
    },
    volunteerExpected: {
      id: 'send-outs.form.stations.volunteerExpected'
    },
    officialQuantity: {
      id: 'send-outs.form.stations.officialQuantity'
    },
    stations: {
      name: {
        id: 'send-outs.form.stations.name',
      },
      groups: {
        id: 'send-outs.form.stations.groups',
      },
      noStations: {
        id: 'send-outs.form.stations.no-stations',
      }
    },
    subject: {
      id: 'send-outs.form.subject',
    },
    message: {
      id: 'send-outs.form.message',
    },
    attachments: {
      id: 'send-outs.form.attachments',
    },
    attachmentsButton: {
      id: 'send-outs.form.attachments-button',
    },
    noStationsError: {
      id: 'send-outs.form.no-stations-error',
    },
    noRecipientTypes: {
      id: 'send-outs.form.no-recipient-types-error',
    },
    sendButton: {
      id: 'send-outs.form.send-button',
    },
    sendError: {
      id: 'send-outs.form.send-error',
    },
    sent: {
      id: 'send-outs.form.sent',
    }
  },
  list: {
    title: {
      id: 'send-outs.list.title',
    },
    created: {
      id: 'send-outs.list.created',
    },
    subject: {
      id: 'send-outs.list.subject',
    },
    noSendOuts: {
      id: 'send-outs.list.no-send-outs',
    }
  },
  single: {
    title: {
      id: 'send-outs.single.title',
    },
    error: {
      id: 'send-outs.single.error',
    },
  }
});

import { default as foodOptions } from './food-options';
import { default as shoeSizes } from './shoe-sizes';
import { default as shirtSizes } from './shirt-sizes';
import { default as skills } from './skills';
import { default as currencies } from './currencies';
import { default as payments } from './payments';
import { default as additionalQuestions } from './event-additional-questions';

export default {
  foodOptions,
  shoeSizes,
  shirtSizes,
  skills,
  currencies,
  payments,
  additionalQuestions,
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmDeleteTitle: {
    id: 'message.confirm-delete.title',
  },
  confirmDeleteButton: {
    id: 'message.confirm-delete-button',
  },
  cancelDeleteButton: {
    id: 'message.cancel-delete-button',
  },
});

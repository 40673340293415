// actions
export const AUTH_STORED_USER = 'AUTH_STORED_USER';
export const AUTH_STORED_USER_FAILED = 'AUTH_STORED_USER_FAILED';
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const REGISTRATION_CONFIRM_SUBMIT = 'REGISTRATION_CONFIRM_SUBMIT';
export const REGISTRATION_CONFIRM_SUCCESS = 'REGISTRATION_CONFIRM_SUCCESS';
export const REGISTRATION_CONFIRM_FAILED = 'REGISTRATION_CONFIRM_FAILED';
export const REGISTRATION_RESEND_CODE = 'REGISTRATION_RESEND_CODE';
export const REGISTRATION_RESEND_CODE_FAILED = 'REGISTRATION_RESEND_CODE_FAILED';
export const REGISTRATION_RESEND_CODE_SUCCESS = 'REGISTRATION_RESEND_CODE_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILED = 'UPDATE_USER_INFO_FAILED';
export const CHANGE_PASSWORD_SUBMIT = 'CHANGE_PASSWORD_SUBMIT';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const RESET = 'RESET';

export const status = {
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  LOGGING_IN: 'LOGGING_IN',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGGED_IN: 'LOGGED_IN',
  LOGGING_OUT: 'LOGGING_OUT',
  PENDING: 'PENDING',
  VERIFYING: 'VERIFYING',
  VERIFYING_FAILED: 'VERIFYING_FAILED',
  VERIFYING_SUCCESS: 'VERIFYING_SUCCESS',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  CHANGING_PASSWORD: 'CHANGING_PASSWORD',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
  INIT: 'INIT',
  SENDING: 'SENDING',
  SENT: 'SENT',
  SENT_FAILED: 'SENT_FAILED',
};

export function loginSubmit (data) {
  return {
    type: LOGIN_SUBMIT,
    payload: data,
  };
}

export function changePassword (data) {
  return {
    type: CHANGE_PASSWORD_SUBMIT,
    payload: data,
  };
}

export function confirmRegistration (data) {
  return {
    type: REGISTRATION_CONFIRM_SUBMIT,
    payload: data,
  };
}

export function resendConfirmationCode (data) {
  return {
    type: REGISTRATION_RESEND_CODE,
    payload: data,
  };
}

export function logout () {
  return {
    type: LOGOUT,
  };
}

export function authStoredUser () {
  return {
    type: AUTH_STORED_USER,
  };
}

export function updateUserInfo (data) {
  return {
    type: UPDATE_USER_INFO,
    payload: data,
  };
}

export function reset () {
  return {
    type: RESET,
  };
}

// reducer with initial state
const initialState = {
  status: status.PENDING,
  user: null,
  accessToken: null,
  refreshToken: null,
  expiresIn: null,
  error: null,
  passwordChallengeEmail: null,
  activationCodeStatus: status.INIT,
};

const stateToUse = initialState;

export default function auth(state = stateToUse, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case LOGIN_SUBMIT:
      return Object.assign({}, state, {error: null}, {status: status.LOGGING_IN});
    case LOGIN_SUCCESS:
      return Object.assign({}, state, action.payload, {status: status.LOGGED_IN});
    case LOGIN_FAILED:
      return Object.assign({}, state, action.payload, {error: action.payload}, {status: status.LOGIN_FAILED, user: null});
    case NEW_PASSWORD_REQUIRED:
      return Object.assign({}, state, {status: status.NEW_PASSWORD_REQUIRED, user: action.payload.user, passwordChallengeEmail: action.payload.email.toLowerCase()});
    case REGISTRATION_CONFIRM_SUBMIT:
      return Object.assign({}, state, {error: null}, {status: status.VERIFYING});
    case REGISTRATION_CONFIRM_FAILED:
      return Object.assign({}, state, {error: action.payload}, {status: status.VERIFYING_FAILED});
    case LOGOUT:
      return Object.assign({}, state, {status: status.LOGGIN_OUT, user: null});
    case LOGOUT_SUCCESS:
      return initialState;
    case AUTH_STORED_USER_FAILED:
      return Object.assign({}, state, action.payload, {status: status.NOT_LOGGED_IN, user: null});
    case REGISTRATION_CONFIRM_SUCCESS:
      return Object.assign({}, state, {error: null}, {status: status.VERIFYING_SUCCESS});
    case GET_USER_INFO_SUCCESS:
      return Object.assign({}, state, {user: action.payload});
    case GET_USER_INFO_FAILED:
      return state;
    case UPDATE_USER_INFO_SUCCESS:
      return Object.assign({}, state,{user: action.payload});
    case UPDATE_USER_INFO_FAILED:
      return state;
    case CHANGE_PASSWORD_SUBMIT:
      return Object.assign({}, state, {error: null}, {status: status.CHANGING_PASSWORD});
    case CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {error: action.payload}, {status: status.CHANGE_PASSWORD_FAILED, user: null});
    case REGISTRATION_RESEND_CODE:
      return Object.assign({}, state, {activationCodeStatus: status.SENDING});
    case REGISTRATION_RESEND_CODE_SUCCESS:
      return Object.assign({}, state, {activationCodeStatus: status.SENT});
    case REGISTRATION_RESEND_CODE_FAILED:
      return Object.assign({}, state, {activationCodeStatus: status.SENT_FAILED});
    case RESET:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}

export const ATTENDANCE_SUBMIT = 'ATTENDANCE_SUBMIT';
export const ATTENDANCE_SUBMIT_SUCCESS = 'ATTENDANCE_SUBMIT_SUCCESS';
export const ATTENDANCE_SUBMIT_FAILED = 'ATTENDANCE_SUBMIT_FAILED';
export const RESET = 'RESET';

export function submitAttendance (data) {
  return {
    type: ATTENDANCE_SUBMIT,
    payload: data,
  };
}

export function reset (data) {
  return {
    type: RESET,
  };
}

const statuses = {
  INIT: 'INIT',
  SUBMITTING: 'SUBMITTING',
  SUBMITTED: 'SUBMITTED',
  FAILED: 'FAILED'
};

const initialState = {
  groups: {}, // Will have groupId as key and contain status
};

export default function attendance(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case RESET:
      return Object.assign({}, initialState);
    case ATTENDANCE_SUBMIT:
      const newState = Object.assign({}, state);
      newState.groups[action.payload.groupId] = statuses.SUBMITTING;
      return newState;
    case ATTENDANCE_SUBMIT_SUCCESS:
      const newSuccessGroups = Object.assign({}, state.groups);
      newSuccessGroups[action.payload.groupId] = statuses.SUBMITTED;

      return Object.assign({}, {groups: newSuccessGroups});
    case ATTENDANCE_SUBMIT_FAILED:
      const newGroups = Object.assign({}, state.groups);
      newGroups[action.payload.groupId] = statuses.FAILED;

      return Object.assign({}, {groups: newGroups});
    default:
      return state;
  }
}

export {statuses};

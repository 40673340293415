import React, { Component } from "react";
import { Container, ContentWrapper } from "../../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import MyProfile from "./";

import { injectIntl } from "react-intl";
import styled from "styled-components";
import { colors } from "../../../constants/styling-constants";

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.lightGray};
  width: 100%;
`;

class MyProfileStart extends Component {
  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <Container>
            <Row>
              <Col sm={9}>
                <MyProfile />
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default injectIntl(MyProfileStart);

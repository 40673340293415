import styled from 'styled-components';

const ImageBackground = styled.div`
  background-size: cover;
  height: 420px;
  display flex;
  align-items: center;
`;

export default ImageBackground;

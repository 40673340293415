import { defineMessages } from 'react-intl';

export default defineMessages({
  headerTitle: {
    id: 'terms.header.title',
  },
  pageIntro1: {
    id: 'terms.page-intro-text.paragraph-1',
  },
  pageIntro2: {
    id: 'terms.page-intro-text.paragraph-2',
  },
  volunteerTitle: {
    id: 'terms-volunteer.page-title',
  },
  organizerTitle: {
    id: 'terms-organizer.page-title',
  },
});

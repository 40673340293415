import { connect } from "react-redux";
import { submitEvent, reset, fetchEvent } from "../reducer";
import EventCopyBase from "./EventCopyBase";
import { fetchEventOffers, createOffer } from '../../Offer/reducer';

const mapStateToProps = (state) => {
  const obj = {
    eventOffers: state.offers.eventOffers,
    event: state.event.event,
    error: state.event.error,
    status: state.event.status,
    user: state.auth.user,
  };

  return obj;
};

const mapDispatchToProps = (dispatch) => ({
  fetchEventOffers: data => dispatch(fetchEventOffers(data)),
  createOffer: data => dispatch(createOffer(data)),
  fetchEvent: data => dispatch(fetchEvent(data)),
  submitEvent: data => dispatch(submitEvent(data)),
  reset: () => dispatch(reset({})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCopyBase);

import React from 'react';
import { Modal } from 'react-bootstrap/lib';
import { MediumGrayButton, SmallPinkButton, SmallGreenButton } from '../Buttons';
import { H2, P } from '../index';
import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import { breakPoints } from "../../constants/styling-constants";


export const ModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 530px;
  }
  .modal-content {
    border-radius: 0;
    padding: 30px;
  }
  z-index: 999999
  
  @media (max-width: ${breakPoints.sm}px) {
    .modal-dialog {
      max-width: 360px;
  }
`;

export const Content = styled.section`
  text-align: center;
  padding: 0 15px;
  i {
    color: ${colors.pink};
    margin-bottom: 25px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
`;

const renderConfirmButton = (theme, label, busy, handleConfirm) => {
  switch(theme) {
    case 'pink':
      return <SmallPinkButton label={label} busy={busy} onClick={handleConfirm}/>;
    case 'green':
      return <SmallGreenButton label={label} busy={busy} onClick={handleConfirm}/>;
    case 'gray':
      return <MediumGrayButton label={label} busy={busy} onClick={handleConfirm}/>;
    default:
      return <MediumGrayButton label={label} busy={busy} onClick={handleConfirm}/>;
  }
};

// TODO: Change to slim version of icon in FontAwesome Pro
// <i className={`far ${ icon}`} /> - add this when we add other types of icons to confirmations modals
const ConfirmationModal = (
  {
    show,
    handleHide,
    label = <FormattedMessage {...messages.cancelDeleteButton} />,
    handleConfirm,
    icon = 'fa-times-circle',
    title = <FormattedMessage {...messages.confirmDeleteTitle} />,
    buttonText = <FormattedMessage {...messages.confirmDeleteButton} />,
    text = '',
    busy = false,
    theme = 'gray',
    bgColor = colors.gray,
    startGradientColor = colors.grayStartGradient,
    endGradientColor = colors.gray,
    hoverStartGradientColor = colors.gray,
    hoverEndGradientColor = colors.gray,
    children
  }) => (
  <ModalWrapper show={show} onHide={handleHide} bsSize="large">
    <Modal.Body>
      <Content>
        <H2>{title}</H2>
        <P>{text}</P>
        {children}
      </Content>
      <Footer>
        <MediumGrayButton label={label} type="button" onClick={handleHide} />
        {renderConfirmButton(theme, buttonText, busy, handleConfirm)}
      </Footer>
    </Modal.Body>
  </ModalWrapper>
);

export default injectIntl(ConfirmationModal);

import { takeLatest, takeEvery } from 'redux-saga/effects';

import { LOGIN_SUBMIT, LOGOUT, AUTH_STORED_USER, REGISTRATION_RESEND_CODE, REGISTRATION_CONFIRM_SUBMIT, LOGIN_SUCCESS, UPDATE_USER_INFO, GET_USER_INFO, CHANGE_PASSWORD_SUBMIT } from './auth/reducer';
import { loginSaga, loadAuthenticatedUserSaga, logoutSaga, confirmRegistrationSaga, resendConfirmationSaga, getUserInfoSaga, updateUserInfo, changePasswordSaga } from './auth/saga';

import { REGISTER_SUBMIT } from './containers/User/Register/reducer';
import { registerSaga } from './containers/User/Register/saga';

import { GROUP_SUBMIT, GROUP_FETCHING, GROUPS_SUMMARIES, FETCH_PUBLIC_EVENTS, GROUP_CONFIRM, FETCH_COMPLETE_EVENT } from './containers/MyPages/VolunteerContext/reducer';
import { groupSaga, fetchGroup, fetchGroupsSummaries, fetchPublicEvents, confirmGroupSaga, fetchCompleteEvent } from './containers/MyPages/VolunteerContext/saga';

import { EVENT_SUBMIT, EVENT_FETCHING, EVENTS_SUMMARIES, EVENT_CONFIRM, EVENT_REMOVE, SET_HISTORIC } from './containers/MyPages/Event/reducer';
import { saveEvent, fetchEvent, fetchEventsSummaries, confirmEventSaga, removeEvent, setHistoric } from './containers/MyPages/Event/saga';

import { resetPasswordSaga, forgotPasswordSaga, forgotPasswordResetSaga } from './containers/User/ForgotPassword/saga';
import { RESET_PASSWORD, FORGOT_PASSWORD_CODE_SEND, RESET_PASSWORD_STATE } from './containers/User/ForgotPassword/reducer';
import { FETCH_CALENDAR_EVENTS } from './containers/Start/reducer';
import { fetchCalendarEvents } from './containers/Start/saga';
import { GROUP_AVAIL_SAVE, GROUP_AVAIL_FETCH } from './containers/MyPages/VolunteerContext/GroupAvailability/reducer';
import { saveGroupAvailabilitySaga, getGroupAvailabilitySaga } from './containers/MyPages/VolunteerContext/GroupAvailability/saga';
import { GROUP_OFFERS_FETCH, GROUP_OFFER_FETCH, ALL_OFFERS_FETCH, EVENT_OFFERS_FETCH, AVAILABLE_GROUPS_FETCH, OFFER_CREATE, CONFIRM_STATION_OFFERS, GROUPS_OFFERS_FETCH, ACCEPT_STATION_OFFER, REJECT_STATION_OFFER, DELETE_OFFER, STATION_OFFERS_FETCH, SEND_OFFER_REMINDER, UPDATE_OFFER_ATTENDEES, CREATE_SELF_REGISTRATION_LINK } from './containers/MyPages/Offer/reducer';
import { fetchGroupOffersSaga, updateOfferAttendeesSaga, fetchGroupOfferSaga, fetchAllOffersSaga, fetchEventOffersSaga, fetchAvailableGroupsSaga, createOfferSaga, confirmStationOffersSaga, fetchGroupsEventsSaga, acceptGroupOfferSaga, rejectGroupOfferSaga, deleteOfferSaga, fetchStationOffersSaga, sendOfferReminderSaga, createLink } from './containers/MyPages/Offer/saga';

import { GET_USER_BY_EMAIL, CREATE_USER } from "./containers/User/reducer";
import { getUserByEmailSaga, createUserSaga } from "./containers/User/saga";

import { SEND_SEND_OUT, FETCH_SEND_OUTS, FETCH_SEND_OUT } from "./containers/MyPages/Event/SendOuts/reducer";
import { sendSendOutSaga, fetchSendOutsSaga, fetchSendOutSaga } from "./containers/MyPages/Event/SendOuts/saga";

import { ATTENDANCE_SUBMIT } from "./containers/MyPages/Event/Attendance/reducer";
import { submitAttendanceSaga } from "./containers/MyPages/Event/Attendance/saga";

import { TEAMLEADER_EVENTS_FETCH, TEAMLEADER_EVENT_FETCH } from './containers/MyPages/TeamLeader/reducer';
import { fetchTeamleaderEvents, fetchTeamleaderEvent } from './containers/MyPages/TeamLeader/saga';

import { VOLUNTEER_SUBMIT, FETCHING } from './containers/SelfRegistration/reducer';
import { addVolunteer, fetchPublicEvent } from './containers/SelfRegistration/saga';

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSaga() {
  yield takeLatest(UPDATE_OFFER_ATTENDEES, updateOfferAttendeesSaga)
  yield takeLatest(REGISTRATION_RESEND_CODE, resendConfirmationSaga);
  yield takeLatest(LOGIN_SUBMIT, loginSaga);
  yield takeLatest(LOGIN_SUCCESS, getUserInfoSaga);
  yield takeLatest(CHANGE_PASSWORD_SUBMIT, changePasswordSaga);
  yield takeLatest(GET_USER_INFO, getUserInfoSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(AUTH_STORED_USER, loadAuthenticatedUserSaga);
  yield takeLatest(REGISTER_SUBMIT, registerSaga);
  yield takeLatest(GROUP_SUBMIT, groupSaga);
  yield takeLatest(GROUP_FETCHING, fetchGroup);
  yield takeLatest(EVENT_SUBMIT, saveEvent);
  yield takeLatest(EVENT_FETCHING, fetchEvent);
  yield takeLatest(REGISTRATION_CONFIRM_SUBMIT, confirmRegistrationSaga);
  yield takeLatest(EVENTS_SUMMARIES, fetchEventsSummaries);
  yield takeLatest(EVENT_REMOVE, removeEvent);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(RESET_PASSWORD_STATE, forgotPasswordResetSaga);
  yield takeLatest(FORGOT_PASSWORD_CODE_SEND, forgotPasswordSaga);
  yield takeLatest(FETCH_CALENDAR_EVENTS, fetchCalendarEvents);
  yield takeLatest(UPDATE_USER_INFO, updateUserInfo);
  yield takeLatest(GROUPS_SUMMARIES, fetchGroupsSummaries);
  yield takeLatest(FETCH_PUBLIC_EVENTS, fetchPublicEvents);
  yield takeLatest(FETCH_COMPLETE_EVENT, fetchCompleteEvent);
  yield takeLatest(EVENT_CONFIRM, confirmEventSaga);
  yield takeLatest(GROUP_CONFIRM, confirmGroupSaga);
  yield takeLatest(GROUP_AVAIL_SAVE, saveGroupAvailabilitySaga);
  yield takeLatest(GROUP_AVAIL_FETCH, getGroupAvailabilitySaga);
  yield takeLatest(GROUP_OFFERS_FETCH, fetchGroupOffersSaga);
  yield takeLatest(GROUPS_OFFERS_FETCH, fetchGroupsEventsSaga);
  yield takeLatest(GROUP_OFFER_FETCH, fetchGroupOfferSaga);
  yield takeLatest(ALL_OFFERS_FETCH, fetchAllOffersSaga);
  yield takeLatest(EVENT_OFFERS_FETCH, fetchEventOffersSaga);
  yield takeLatest(STATION_OFFERS_FETCH, fetchStationOffersSaga);
  yield takeLatest(ACCEPT_STATION_OFFER, acceptGroupOfferSaga);
  yield takeLatest(REJECT_STATION_OFFER, rejectGroupOfferSaga);
  yield takeLatest(DELETE_OFFER, deleteOfferSaga);
  yield takeLatest(SEND_OFFER_REMINDER, sendOfferReminderSaga);
  yield takeLatest(GET_USER_BY_EMAIL, getUserByEmailSaga);
  yield takeLatest(CREATE_USER, createUserSaga);
  yield takeEvery(CONFIRM_STATION_OFFERS, confirmStationOffersSaga);
  yield takeEvery(AVAILABLE_GROUPS_FETCH, fetchAvailableGroupsSaga);
  yield takeEvery(OFFER_CREATE, createOfferSaga);
  yield takeLatest(SEND_SEND_OUT, sendSendOutSaga);
  yield takeLatest(FETCH_SEND_OUTS, fetchSendOutsSaga);
  yield takeLatest(FETCH_SEND_OUT, fetchSendOutSaga);
  yield takeEvery(ATTENDANCE_SUBMIT, submitAttendanceSaga);
  yield takeLatest(TEAMLEADER_EVENTS_FETCH, fetchTeamleaderEvents);
  yield takeLatest(TEAMLEADER_EVENT_FETCH, fetchTeamleaderEvent);
  yield takeLatest(SET_HISTORIC, setHistoric);
  yield takeLatest(CREATE_SELF_REGISTRATION_LINK, createLink);
  yield takeLatest(VOLUNTEER_SUBMIT, addVolunteer);
  yield takeLatest(FETCHING, fetchPublicEvent);
}

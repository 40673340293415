import React, { Fragment } from "react";
import styled from "styled-components";
import { colors, buttonStyles, breakPoints } from "../../constants/styling-constants";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { CircleIcon } from "../Icons/index";

// TODO: Add Hover effects

const LinkButton = styled(Link)`
  font-size: ${buttonStyles.fontSize};
  color: ${buttonStyles.color};
  padding: ${buttonStyles.padding};
  background-color: ${colors.pink};
  background: linear-gradient(${colors.pink}, ${colors.pinkEndGradient});
  border-radius: ${buttonStyles.borderRadius};
  display: inline-block;
  text-align: center;
  &:hover, &:visited {
    color: ${buttonStyles.color};
    text-decoration: none;
  }
  // TODO: Not sure if these size changes should apply to all links or only on the start page
  @media (max-width: ${breakPoints.sm}px) {
    padding: 15px 25px;
    font-size 16px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    padding: 12px 25px;
    font-size 14px;
  }
`;

const GreenLinkButton = LinkButton.extend`
  background-color: ${colors.green};
  background: linear-gradient(${colors.green}, ${colors.greenEndGradient});
  margin-bottom: 20px;
`;

const BigLinkButton = LinkButton.extend`
  width: 245px;
  margin-bottom: 20px;
  @media (max-width: ${breakPoints.sm}px) {
    width: 180px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    width: 130px;
  }
  // padding-left: 80px;
  // padding-right: 80px;
`;

const BigHashLinkButton = styled(HashLink)`
    font-size: ${buttonStyles.fontSize};
    color: ${buttonStyles.color};
    padding: ${buttonStyles.padding};
    background-color: ${colors.pink};
    background: linear-gradient(${colors.pink}, ${colors.pinkEndGradient});
    border-radius: ${buttonStyles.borderRadius};
    display: inline-block;
    text-align: center;
    width: 245px;
    margin-bottom: 20px;
    // TODO: Add transition for hover effect
    &:hover, &:visited {
      color: ${buttonStyles.color};
      text-decoration: none;
      background: linear-gradient(${colors.darkPink}, ${colors.pinkEndGradient});
    }
    // TODO: Not sure if these size changes should apply to all links or only on the start page
    @media (max-width: ${breakPoints.sm}px) {
      padding: 15px 25px;
      font-size 16px;
      width: 180px;
    }
    @media (max-width: ${breakPoints.xs}px) {
      padding: 12px 25px;
      font-size 14px;
      width: 130px;
    }
`;

const BigGreenLinkButton = GreenLinkButton.extend`
  width: 245px;
  @media (max-width: ${breakPoints.sm}px) {
    width: 180px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    width: 130px;
  }
  // padding-left: 80px;
  // padding-right: 80px;
`;

const BigHashGreenLinkButton = BigHashLinkButton.extend`  
  background-color: ${colors.green};
  background: linear-gradient(${colors.green}, ${colors.greenEndGradient});
  margin-bottom: 20px;
  width: 245px;
  // TODO: Add transition for hover effect
   &:hover {
    background: linear-gradient(${colors.darkGreen}, ${colors.greenEndGradient});
  }
  @media (max-width: ${breakPoints.sm}px) {
    width: 180px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    width: 130px;
  }
`;

const NavLinkButton = styled(NavLink)`
  font-size: 14px;
  padding: 12px 25px;
  background-color: transparent;
  border-radius: ${buttonStyles.borderRadius};
  display: inline-block;
  margin-top: 20px;
  border: 1px solid ${colors.pink};
  color: ${colors.pink};
  margin-right 15px;
  transition: all 0.1s ease;
  // TODO: Fix transition effect, not working at the moment? 
  &:hover, &.active {
    background-color: ${colors.pink};
    background: linear-gradient(${colors.pink}, ${colors.pinkEndGradient});
    color: #FFF;
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
`;

const GreenNavLinkButton = NavLinkButton.extend`
  border-color: ${colors.green};
  color: ${colors.green};
  // TODO: Add transition for hover effect
  &:hover, &.active {
    background-color: ${colors.green};
    background: linear-gradient(${colors.green}, ${colors.greenEndGradient});
    color: #FFF;
  }
`;

const CircleLinkButtonStyle = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  text-align: center;
  border-radius: 100%;
  border: 20px solid ${colors.lightPink3};
  color: ${colors.lightPink3};
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  //padding: 15px 20px 0;
  &:hover {
    color: ${colors.darkPink};
    text-decoration: none;
  }
  span:first-child {
    margin-bottom: 20px;
  }
  &.green {
    border-color: ${colors.green};
    color: ${colors.green};
    span:nth-child(2) {
      background-color: ${colors.green};
    }
    &:hover {
      color: ${colors.darkGreen};
    }
  }
`;

const CircleLinkButton = ({ to, label, className }) => (
  <CircleLinkButtonStyle
    className={className}
    to={to}
  >
    <Fragment>{label}</Fragment>
    <CircleIcon className={className}/>
  </CircleLinkButtonStyle>
);

const SmallGrayLinkButton = styled(Link)`
  font-size: 14px;
  color: ${buttonStyles.color};
  padding: 12px 25px;
  background-color: ${colors.gray};
  background: linear-gradient(${colors.grayStartGradient}, ${colors.gray});
  border-radius: ${buttonStyles.borderRadius};
  display: inline-block;
  margin-top: 20px;
  text-align: center;
  outline: none;
  // TODO: Add transition for hover effect
  &:hover { 
    color: ${buttonStyles.color}; 
    background: linear-gradient(${colors.gray}, ${colors.gray});
    text-decoration: none;
  }
`;


const WhiteLink = styled(Link)`
  color: #FFF;
  &:hover, &:visited {
    color: #FFF;
    text-decoration: none;
  }
`;


export {
  GreenLinkButton,
  LinkButton,
  BigLinkButton,
  BigGreenLinkButton,
  BigHashLinkButton,
  BigHashGreenLinkButton,
  NavLinkButton,
  GreenNavLinkButton,
  CircleLinkButton,
  SmallGrayLinkButton,
  WhiteLink
};

import { connect } from 'react-redux';
import { selectLanguage } from '../reducer';
import DropDownMenuItem from './DropDownMenuItem';

const mapStateToProps = state => ({
  selectedItem: state.menu.selectedLanguage,
});

const mapDispatchToProps = dispatch => ({
  onSelect: data => dispatch(selectLanguage(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownMenuItem);

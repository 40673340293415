import React, { useState, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import {
  required,
  email as emailValidator,
  integer,
  onlyNumbers,
  positiveNumbers,
} from "../../validators/index";
import messages from "./messages";
import messagesVol from "../MyPages/VolunteerContext/Volunteers/messages";
import { Row, Col } from "react-bootstrap/lib";
import {
  Input,
  FormSectionTitle,
  FormGroup,
  ListSelect,
  Select,
  GeneralError,
  P,
  H4,
  Checkbox,
} from "../../components";
import { BigGreenButton } from "../../components/Buttons/index";
import { colors } from "../../constants/styling-constants";
import { ContentWrapper, Container, ConfirmationModal } from "../../components";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.lightGreen};
  width: 100%;
`;

const SelfRegistrationForm = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [busy, setBusy] = useState(false);
  const [substituteCheckbox, setSubstituteCheckbox] = useState(false);

  const handleConfirm = () => {
    setShowModal(false);
    return props.onSubmit(data);
  };

  const toggleModal = (e) => {
    e && e.preventDefault();
    setShowModal(!showModal);
  };

  const handleSubmit = (values) => {
    const id = uuidv4();
    const participation = []
    const participate = {eventId: props.event.eventId}


    if(values.shirtSize || values.specialDiets || values.substitute){
      if(!values.shirtSize){
        participate.specialDiets = values.specialDiets
      }
      else if(!values.specialDiets){
        participate.shirtSize = values.shirtSize
      }
      else{
        participate.specialDiets = values.specialDiets
        participate.shirtSize = values.shirtSize
      }

      if(values.substitute){
        participate.substitute = values.substitute
      }

      participation.push(participate)
  }
  
    const data = {
      volunteer: { ...values, id, participation },
      link: props.match.params.hash,
    };
    setShowModal(true);
    setData(data);
  };

  useEffect(() => {
    if (props.status === "SUBMITTING") setBusy(true);
    else setBusy(false);
    if (props.status === "SUCCESS") {
      setShowSuccess(true);
      setSubstituteCheckbox(false);
      props.reset();
    } else setShowSuccess(false);
  }, [props.status]);

  useEffect(() => {
    const eventId = props.match.params.eventId
      ? props.match.params.eventId
      : null;
    if (props.fetchStatus === "NOT_FETCHING" && eventId) {
      props.fetchEvent(eventId);
    }
  }, [props.fetchStatus, props.eventId]);

  const styleRow =
    typeof props.goBackToList === "function"
      ? { display: "flex", justifyContent: "center" }
      : {};

  const time = props.event
    ? moment(props.event.date).format("YYYY-MM-DD ")
    : "";

  const withLoadedProps = (LoadedComponent, loadedProps) => (props) =>
    <LoadedComponent {...loadedProps} {...props} />;

  const includedSpecialDietsTranslated =
    props.event && props.event.foodOptions
      ? props.event.foodOptions.map((opt) => ({
          id: opt,
          label: props.intl.formatMessage({
            id: `food.${opt.toLowerCase()}`,
          }),
        }))
      : null;
  const includedShirtSizesTranslated =
    props.event && props.event.shirtSizes
      ? props.event.shirtSizes.map((opt) => ({
          id: opt,
          label: props.intl.formatMessage({
            id: `shirt-size.${opt.toLowerCase()}`,
          }),
        }))
      : null;

  return (
    <Wrapper>
      <ContentWrapper>
        <Container>
          <form
            noValidate
            onSubmit={props.handleSubmit((values) => handleSubmit(values))}
          >
            <Row style={styleRow}>
              <Col sm={8}>
                <FormGroup>
                  <FormSectionTitle>
                    <FormattedMessage {...messages.title} />
                  </FormSectionTitle>
                  <H4> {props.match.params.groupName}</H4>
                  <H4><FormattedMessage {...messages.info.eventName}/> {props.event ? props.event.name : ""} </H4>
                  <H4>{time}</H4>
                  <Field
                    name="name"
                    placeholder={props.intl.formatMessage(messages.form.name)}
                    type="text"
                    component={Input}
                    validate={[required]}
                  />
                  <Field
                    name="email"
                    placeholder={props.intl.formatMessage(messages.form.email)}
                    type="email"
                    component={Input}
                    validate={[required, emailValidator]}
                  />
                  <Field
                    name="phone"
                    placeholder={props.intl.formatMessage(messages.form.phone)}
                    type="tel"
                    component={Input}
                    validate={[required, onlyNumbers]}
                  />
                  <Field
                    name="age"
                    placeholder={props.intl.formatMessage(messages.form.age)}
                    type="number"
                    component={Input}
                    validate={[required, positiveNumbers, integer]}
                  />
                  {props.event && props.event.substitute ?
                  <div>
                    <H4><FormattedMessage {...messages.form.substituteTitle}/></H4>
                    <Checkbox
                      id={"substitute"}
                      onChange={() => setSubstituteCheckbox(!substituteCheckbox)}
                      label={" " + props.intl.formatMessage(messages.form.substituteOption)}
                      checked={substituteCheckbox}
                    />
                  </div>
                  : null}
                  {substituteCheckbox ?
                  <div>
                    <H4>  </H4>
                    <Field
                    name="substitute"
                    placeholder={props.intl.formatMessage(
                      messages.form.substituteField
                    )}
                    type="text"
                    component={Input}
                    validate={[required]}
                    />
                  </div>
                  : null
                  }
                </FormGroup>
                {props.event ? (
                  props.event.foodOptions || props.event.shirtSizes ? (
                    <FormGroup>
                      <FormSectionTitle>
                        <FormattedMessage
                          {...messagesVol.volunteers.otherInfoTitle}
                        />
                      </FormSectionTitle>
                      {props.event.foodOptions ? (
                        <>
                          <H4><FormattedMessage {...messages.info.foodOptionsTitle} /></H4>
                          <Field
                            name="specialDiets"
                            component={withLoadedProps(ListSelect, {
                              display: "inline-block",
                            })}
                            valueField="id"
                            textField="label"
                            data={includedSpecialDietsTranslated}
                          />
                        </>
                      ) : null}
                      {props.event.shirtSizes ? (
                        <>
                          <H4><FormattedMessage {...messages.info.shirtSizesTitle} /></H4>
                          <Field
                            name="shirtSize"
                            placeholder={props.intl.formatMessage(
                              messagesVol.volunteers.form.shirtSize
                            )}
                            component={Select}
                            valueField="id"
                            textField="label"
                            data={includedShirtSizesTranslated}
                            validate={[required]}
                          />
                        </>
                      ) : null}
                    </FormGroup>
                  ) : null
                ) : null}
                <BigGreenButton
                  busy={busy}
                  disabled={busy}
                  label={props.intl.formatMessage(messages.form.submitButton)}
                />
                {props.formError ? (
                  <GeneralError>
                    <FormattedMessage
                      {...messages.form.emailAlreadyExistError}
                    />
                  </GeneralError>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </form>
          {showSuccess ? <P>Tack för din anmälan!</P> : null}
        </Container>
        <ConfirmationModal
          label={props.intl.formatMessage(
            messages.confirmationModal.cancelButton
          )}
          show={showModal}
          handleConfirm={handleConfirm}
          handleHide={toggleModal}
          buttonText={props.intl.formatMessage(
            messages.confirmationModal.confirmButton
          )}
          title={props.intl.formatMessage(messages.confirmationModal.title)}
          text={props.intl.formatMessage(messages.confirmationModal.text)}
          busy={busy}
          disabled={busy}
          theme="green"
        />
      </ContentWrapper>
    </Wrapper>
  );
};

export default reduxForm({
  form: "SelfRegistration",
})(injectIntl(SelfRegistrationForm));

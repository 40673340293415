import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchStationOffers } from "../../../Offer/reducer";
import StationAttendance from "./StationAttendance";

const mapStateToProps = (state, ownProps) => ({
  event: ownProps.event,
  stationOffers: state.offers.stationOffers,
  offerError: state.offers.stationOfferError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStationOffers: data => dispatch(fetchStationOffers(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(StationAttendance));

import React, { Fragment } from "react";
import styled from "styled-components";
import { colors } from "../../constants/styling-constants";

const StyledError = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
  .icon {
    border: 2px solid ${colors.pink};
    border-radius: 100%;
    height: 35px;
    flex-basis: 35px;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
    color: ${colors.pink};
    margin-right: 15px;
  }
  .text {
  
  }
  .icon, .text {
    display: inline-block;
  }
`;

const GeneralError = ({ children }) => (
  <StyledError>
    <span className="icon">!</span>
    <Fragment>{children}</Fragment>
  </StyledError>
);

export default GeneralError;


// actions
export const EVENT_SUBMIT = 'EVENT_SUBMIT';
export const EVENT_FETCHING = 'EVENT_FETCHING';
export const EVENT_FETCHED = 'EVENT_FETCHED';
export const EVENT_SUCCESS = 'EVENT_SUCCESS';
export const EVENT_FAILED = 'EVENT_FAILED';
export const EVENT_RESET = 'EVENT_RESET';
export const EVENT_SAVING = 'EVENT_SAVING';
export const EVENT_SAVING_FAILED = 'EVENT_SAVING_FAILED';
export const EVENT_SAVED = 'EVENT_SAVED';
export const EVENTS_SUMMARIES = 'EVENTS_SUMMARIES';
export const EVENTS_SUMMARIES_FAILED = 'EVENTS_SUMMARIES_FAILED';
export const EVENTS_SUMMARIES_SUCCESS = 'EVENTS_SUMMARIES_SUCCESS';
export const EVENT_CONFIRM = 'EVENT_CONFIRM';
export const EVENT_CONFIRM_SUCCESS = 'EVENT_CONFIRM_SUCCESS';
export const EVENT_CONFIRM_FAILED = 'EVENT_CONFIRM_FAILED';
export const EVENT_REMOVE = 'EVENT_REMOVE';
export const EVENT_REMOVE_SUCCESS = 'EVENT_REMOVE_SUCCESS';
export const EVENT_REMOVE_FAILED = 'EVENT_REMOVE_FAILED';
export const SET_HISTORIC = 'SET_HISTORIC';
export const SET_HISTORIC_FAILED = 'SET_HISTORIC_FAILED';
export const SET_HISTORIC_SUCCESS = 'SET_HISTORIC_SUCCESS';

export const status = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  SUBMITTING: 'SUBMITTING',
  SUCCESS: 'SUCCESS', // ??? Succeeeded what?
  FAILED: 'FAILED', // ??? failed what?
};

export const eventStatus = status;

export function submitEvent (data) {
  return {
    type: EVENT_SUBMIT,
    payload: data,
  };
}

export function confirmEvent (data) {
  return {
    type: EVENT_CONFIRM,
    payload: data,
  };
}

export function fetchEvent (data) {
  return {
    type: EVENT_FETCHING,
    payload: data,
  };
}

export function getEventsSummaries (data) {
  return {
    type: EVENTS_SUMMARIES,
    payload: data,
  };
}

export function removeEvent (data) {
  return {
    type: EVENT_REMOVE,
    payload: data,
  };
}

export function reset (data) {
  return {
    type: EVENT_RESET,
    payload: data,
  };
}

export function setHistoric (data) {
  return {
    type: SET_HISTORIC,
    payload: data,
  };
}

// reducer with initial state
const initialState = {
  status: status.NOT_SUBMITTED,
  error: null,
  event: null,
  eventsSummaries: null,
};

export default function EVENT(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case EVENT_SUBMIT:
      return Object.assign({}, state, {status: status.SUBMITTING});
    case EVENT_CONFIRM:
      return Object.assign({}, state, {status: status.SUBMITTING});
    case EVENT_FETCHING:
      return Object.assign({}, state, {status: status.FETCHING});
    case EVENT_FETCHED:
      return Object.assign({}, state, {status: status.FETCHED, event: action.payload});
    case EVENT_SUCCESS:
      return Object.assign({}, state, {status: status.SUCCESS, event: action.payload});
    case EVENT_FAILED:
      return Object.assign({}, state, action.payload, {status: status.FAILED, error: action.payload});
    case EVENT_RESET:
      return initialState;
    case EVENTS_SUMMARIES_SUCCESS:
      return Object.assign({}, state, {eventsSummaries: action.payload});
    case EVENTS_SUMMARIES_FAILED:
      return Object.assign({}, state, {eventsSummaries: []});
    case EVENT_CONFIRM_SUCCESS:
      return Object.assign({}, state, {status: status.SUCCESS, event: action.payload});
    case EVENT_REMOVE_SUCCESS:
      return Object.assign({}, state, {eventsSummaries: state.eventsSummaries.filter(e => e.eventId !== action.payload.eventId)});
    case EVENT_REMOVE_FAILED:
      return Object.assign({}, state, {});
    case SET_HISTORIC:
      return Object.assign({}, state, {status: status.SUBMITTING});
      case SET_HISTORIC_SUCCESS:
      return Object.assign({}, state, {eventsSummaries: state.eventsSummaries.map(e1 => action.payload.some(e2 => e2.Attributes.eventId === e1.eventId) ? Object.assign({}, e1, {status: "HISTORIC"}) : e1), status: status.SUCCESS});
    case SET_HISTORIC_FAILED:
      return Object.assign({}, state, {status: status.FAILED, error: action.payload});
    default:
      return state;
  }
}

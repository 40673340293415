import { connect } from "react-redux";
import { withRouter } from "react-router";
import { submitEvent, reset } from "../reducer";
import StationEditBase from "./StationEditBase";
import { fetchEventOffers } from "../../Offer/reducer";
import { resetUser } from "../../../User/reducer";

const mapStateToProps = (state, ownProps) => ({
  event: ownProps.event,
  status: state.event.status,
  initialValues: ownProps.event,
  user: state.auth.user,
  newUserStatus: state.user.newUserStatus,
  eventOffers: state.offers.eventOffers,
});

const mapDispatchToProps = (dispatch) => ({
  submitEvent: data => dispatch(submitEvent(data)),
  resetEvent: () => dispatch(reset()),
  fetchEventOffers: data => dispatch(fetchEventOffers(data)),
  resetUser: () => dispatch(resetUser()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(StationEditBase));

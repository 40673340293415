import React, { Component, Fragment, useEffect, useState } from "react";
import { Button, BigGrayButton } from "../../../../components/Buttons";
import { eventStatus } from "../reducer";
import { status as userEmailStatus } from "../../../User/reducer";
import { Row, Col } from "react-bootstrap/lib";
import styled from "styled-components";
import { municipalities } from "../../../../constants/municipalities";
import {
  required,
  unique,
  positiveNumbers,
  integer,
  maxLength250,
} from "../../../../validators/index";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import {
  H2,
  TextLabel,
  FormGroup,
  Input,
  TextArea,
  MultiSelect,
  DatePicker,
  Select,
} from "../../../../components/index";
import { FormattedMessage, injectIntl } from "react-intl";
import AddUserForm from "../../../../components/ContactForm/AddUserForm/index";
import messages from "./messages";
import { getUserByEmail } from "../../../User/service";
import { lists } from "../../../../constants/lists.js"; // TODO: Add updated volex-data repository
import MapsComponent, {
  formatCoordinates,
  getCoordinatesFromLocation,
  getLocationFromCoordinates,
} from "../../../../components/Map";
import { breakPoints } from "../../../../constants/styling-constants";


const { skills, payments, currencies } = lists;

const FormWrapper = styled.form`
  margin-top: 80px;
`;

const MapsWrapper = styled.div`
  height: 500px;
  width: 600px;
  @media (max-width: ${breakPoints.sm}px) {
    width: 350px;
    height: 275px;
    }
`;

const dateRangeValidation = (stationIndex) => (value, event) => {
  const start = moment(event.stations[stationIndex].start).format();
  const end = moment(value).format();

  return end < start ? "Slutdatum måste vara senare än startdatum" : undefined;
};

const StationEditForm = (props) => {
  const uniqueValidator = () => {
    const stations = props.event ? props.event.stations : null;
    const currStation = props.event
      ? props.event.stations[props.stationKey]
      : null;
    const filteredStations =
      stations && currStation
        ? stations.filter((s) => s.id !== currStation.id)
        : [];
    return unique(
      filteredStations,
      props.intl.formatMessage(messages.stationNameAlreadyExistError)
    );
  };

  const [markerLocation, setMarkerLocation] = useState(
    props.event.stations[props.stationKey].markerLocation
  );
  const [markerCoordinates, setMarkerCoordinates] = useState(
    props.event.stations[props.stationKey].markerCoordinates
  );
  const [showMarker, setShowMarker] = useState(!!markerCoordinates);
  const [submitting, setSubmitting] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [center, setCenter] = useState(markerCoordinates);
  const [teamLeaderEmail, setTeamLeaderEmail] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [uniqueNameValidator, setUniqueNameValidator] = useState(() =>
    uniqueValidator()
  );
  const dataRangeIndexValidation = dateRangeValidation(props.stationKey);

  const handleStationSubmit = (fieldValues) => {
    if (
      markerCoordinates ||
      (props.event.stations[props.stationKey].markerCoordinates &&
        !markerCoordinates)
    ) {
      fieldValues.stations[props.stationKey].markerLocation = markerLocation;
      fieldValues.stations[props.stationKey].markerCoordinates =
        markerCoordinates;
    }
    if (fieldValues.stations[props.stationKey].teamLeaderEmail) {
      getUserByEmail({
        email: fieldValues.stations[props.stationKey].teamLeaderEmail,
      })
        .then((resp) => {
          setSubmitting(true);
          props.submitEvent(fieldValues);
        })
        .catch((e) => toggleAddUserModal());
    } else {
      setSubmitting(true);
      props.submitEvent(fieldValues);
    }
  };

  /**
   * Call touch on the start or end to validate when one changes
   */
  const handleChangeStart = (event, newValue, previousValue, name) => {
    const nameStart = name.substring(0, name.lastIndexOf("][") + 1);
    props.touch(`${nameStart}[end]`);
  };

  useEffect(() => {
    if (props.newUserStatus === userEmailStatus.SUBMIT_SUCCESS)
      toggleAddUserModal();
  }, [props.newUserStatus]);

  useEffect(() => {
    if (props.status === eventStatus.SUBMITTING) {
      setSubmitStatus(true);
    }
    if (props.status === eventStatus.SUCCESS && submitStatus) {
      setSubmitting(false);
      setSubmitStatus(false);
      props.toggleEditForm();
    }
  }, [props.status]);

  const toggleAddUserModal = () => setShowAddUserModal(!showAddUserModal);

  useEffect(() => {
    setMarkerCoordinates(
      props.event.stations[props.stationKey].markerCoordinates
    );
    setMarkerLocation(props.event.stations[props.stationKey].markerLocation);
    setCenter(markerCoordinates);
    setShowMarker(!!markerCoordinates);

    if (!markerCoordinates) {
      setCoordinates(props.event.city);
    }
  }, [props.stationKey]);

  const renderMunicipalities = (id) => {
    return (
      <Field
        placeholder={props.intl.formatMessage(
          messages.station.form.municipality
        )}
        data={municipalities}
        valueField="id"
        filter
        textField="name"
        validate={[required]}
        name={`[stations][${id}][municipality]`}
        component={Select}
        readonly={props.isTeamleaderView}
        onChange={(fieldValue) =>
          setCoordinates(municipalities.find((m) => m.id === fieldValue).name)
        }
      />
    );
  };

  const renderSkills = (id) => {
    const skillOptions = skills.map((skill) => ({
      id: skill,
      name: props.intl.formatMessage({ id: `lists.skills.${skill}` }),
    }));
    return (
      <Field
        placeholder={props.intl.formatMessage(messages.volunteer.form.skills)}
        data={skillOptions}
        valueField="id"
        textField="name"
        name={`[stations][${id}][skills]`}
        component={MultiSelect}
        readonly={props.isTeamleaderView}
      />
    );
  };

  const setCoordinates = (location) => {
    getCoordinatesFromLocation(location).then((coordinates) =>
      setCenter(coordinates)
    );
  };

  if (!center) {
    setCoordinates(props.event.city);
  }

  const handleMarkerChange = (coordinates) => {
    getLocationFromCoordinates(coordinates).then((location) => {
      setMarkerCoordinates(coordinates);
      setMarkerLocation(location);
      setShowMarker(true);
    });
  };

  const handleMarkerRemove = () => {
    setMarkerCoordinates(null);
    setMarkerLocation(null);
    setShowMarker(false);
  };

  const handleSearch = (place) => {
    setCenter(place.geometry.location);
  };

  const handlePaymentChange = (PaymentTypeFieldValue) => {
    switch (PaymentTypeFieldValue) {
      case "GROUPAYMENT":
        props.change(`[stations][${props.stationKey}][paymentAdult]`, "");
        props.change(`[stations][${props.stationKey}][paymentAdult]`, "");
        props.change(`[stations][${props.stationKey}][paymentChild]`, "");
        props.change(
          `[stations][${props.stationKey}][compensationByAgreement]`,
          ""
        );
        break;
      case "COMPENSATIONBYAGREEMENT":
      default:
        props.change(`[stations][${props.stationKey}][groupPayment]`, "");
        props.change(`[stations][${props.stationKey}][paymentAdult]`, "");
        props.change(`[stations][${props.stationKey}][paymentChild]`, "");
        props.change(`[stations][${props.stationKey}][currency]`, "");
        break;
      case "NOCOMPENSATION":
        props.change(`[stations][${props.stationKey}][groupPayment]`, "");
        props.change(`[stations][${props.stationKey}][paymentAdult]`, "");
        props.change(`[stations][${props.stationKey}][paymentChild]`, "");
        props.change(`[stations][${props.stationKey}][currency]`, "");
        break;
      case "PERPERSON":
        props.change(`[stations][${props.stationKey}][groupPayment]`, "");
        props.change(
          `[stations][${props.stationKey}][compensationByAgreement]`,
          ""
        );
        break;
      case "HOURLYRATE":
        props.change(`[stations][${props.stationKey}][groupPayment]`, "");
        props.change(
          `[stations][${props.stationKey}][compensationByAgreement]`,
          ""
        );
        break;
    }
    props.handlePaymentChange(PaymentTypeFieldValue);
  };

  const renderAddUserModal = () => {
    const email = teamLeaderEmail;

    return (
      <AddUserForm
        show={showAddUserModal}
        hide={toggleAddUserModal}
        intl={props.intl}
        newEmail={email}
        type={props.type}
      />
    );
  };

  const renderEditForm = (id) => {
    const paymentOptions = payments.map((payment) => ({
      id: payment,
      name: props.intl.formatMessage({ id: `lists.payments.${payment}` }),
    }));
    const currencyOptions = currencies.map((currency) => ({
      id: currency,
      name: props.intl.formatMessage({
        id: `lists.currencies.${currency}`,
      }),
    }));
    return (
      <FormWrapper
        onSubmit={props.handleSubmit(handleStationSubmit)}
        noValidate
      >
        <Row>
          <Col sm={6}>
            <H2>
              {props.isTeamleaderView ?
              <FormattedMessage {...messages.stationEdit.teamLeaderFormTitle} />:
              <FormattedMessage {...messages.stationEdit.formTitle} /> 
              }
            </H2>
            {props.isTeamleaderView ? null : (
              <TextLabel>
                <FormattedMessage {...messages.station.form.title} />
              </TextLabel>
            )}
            <FormGroup>
              <Field
                name={`[stations][${id}][name]`}
                placeholder={props.intl.formatMessage(
                  messages.stationEdit.form.id
                )}
                type="text"
                component={Input}
                validate={[required, uniqueNameValidator, maxLength250]}
                readonly={props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][description]`}
                placeholder={props.intl.formatMessage(
                  messages.stationEdit.form.description
                )}
                type="text"
                component={TextArea}
                validate={[required]}
                readonly={props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][start]`}
                placeholder={props.intl.formatMessage(
                  messages.stationEdit.form.startTime
                )}
                component={DatePicker}
                validate={[required]}
                time
                onChange={handleChangeStart}
                readonly={props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][end]`}
                placeholder={props.intl.formatMessage(
                  messages.stationEdit.form.endTime
                )}
                component={DatePicker}
                validate={[required, dataRangeIndexValidation]}
                time
                readonly={props.isTeamleaderView}
              />
              {renderMunicipalities(id)}
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.station.form.mapTitle} />
              </TextLabel>
              {center && (
                <MapsWrapper>
                  <MapsComponent
                    center={center}
                    onMarkerChange={handleMarkerChange}
                    onMarkerRemove={handleMarkerRemove}
                    zoom={
                      props.event.stations[props.stationKey].markerCoordinates
                        ? 15
                        : 11
                    }
                    showSearchBox={true}
                    onSearch={handleSearch}
                    showMarker={showMarker}
                    markerLocation={markerCoordinates}
                    readOnly={props.isTeamleaderView}
                  />
                </MapsWrapper>
              )}
              <TextLabel>
                <FormattedMessage {...messages.station.form.markerTitle} />
              </TextLabel>
              <TextLabel>
                {markerLocation ? (
                  markerLocation
                ) : (
                  <FormattedMessage {...messages.station.form.noMarker} />
                )}
              </TextLabel>
              <TextLabel>
                {markerCoordinates ? formatCoordinates(markerCoordinates) : ""}
              </TextLabel>
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.teamLeader.form.title} />
              </TextLabel>
              <Field
                name={`[stations][${id}][teamLeaderEmail]`}
                placeholder={props.intl.formatMessage(
                  messages.teamLeader.form.email
                )}
                type="email"
                component={Input}
                readonly={props.isTeamleaderView}
                onChange={(e) => setTeamLeaderEmail(e.target.Value)}
              />
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.volunteer.form.title} />
              </TextLabel>
              <Field
                name={`[stations][${id}][officialQuantity]`}
                placeholder={props.intl.formatMessage(
                  messages.volunteer.form.officialQuantity
                )}
                type="number"
                min="0"
                component={Input}
                validate={[required, positiveNumbers, integer]}
                readonly={props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][officialMiniumumAge]`}
                placeholder={props.intl.formatMessage(
                  messages.volunteer.form.officialMinimumAge
                )}
                type="number"
                min="0"
                component={Input}
                validate={[required, positiveNumbers, integer]}
                readonly={props.isTeamleaderView}
              />
              {renderSkills(id)}
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.payment.form.title} />
              </TextLabel>
              <Field
                name={`[stations][${id}][paymentType]`}
                placeholder={props.intl.formatMessage(
                  messages.payment.form.paymentType
                )}
                component={Select}
                validate={[required]}
                valueField="id"
                textField="name"
                onChange={handlePaymentChange}
                data={paymentOptions}
                readonly={props.isTeamleaderView}
              />
              {!props.compensationByAgreement ? (
                <Fragment>
                  <Field
                    name={`[stations][${id}][currency]`}
                    placeholder={props.intl.formatMessage(
                      messages.payment.form.currency
                    )}
                    component={Select}
                    validate={[required]}
                    valueField="id"
                    textField="name"
                    data={currencyOptions}
                    readonly={props.isTeamleaderView}
                  />
                  {props.groupPayment ? (
                    <Field
                      name={`[stations][${id}][groupPayment]`}
                      placeholder={props.intl.formatMessage(
                        messages.payment.form.groupPayment
                      )}
                      type="number"
                      min="0"
                      component={Input}
                      validate={[required, positiveNumbers]}
                      readonly={props.isTeamleaderView}
                    />
                  ) : (
                    <Fragment>
                      <Field
                        name={`[stations][${id}][paymentAdult]`}
                        placeholder={props.intl.formatMessage(
                          messages.payment.form.paymentAdult
                        )}
                        type="number"
                        min="0"
                        component={Input}
                        validate={[required, positiveNumbers]}
                        readonly={props.isTeamleaderView}
                      />
                      <Field
                        name={`[stations][${id}][paymentChild]`}
                        placeholder={props.intl.formatMessage(
                          messages.payment.form.paymentChild
                        )}
                        type="number"
                        min="0"
                        component={Input}
                        validate={[required, positiveNumbers]}
                        readonly={props.isTeamleaderView}
                      />
                    </Fragment>
                  )}
                </Fragment>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={9}>{renderButtons()}</Col>
        </Row>
        {showAddUserModal && renderAddUserModal()}
      </FormWrapper>
    );
  };

  const renderButtons = () => {
    if (props.isTeamleaderView) {
      return null;
    }

    return (
      <Fragment>
        <BigGrayButton
          type="button"
          label={props.intl.formatMessage(
            messages.stationEdit.cancelEditButton
          )}
          onClick={props.toggleEditForm}
        />
        <Button
          busy={submitting}
          label={props.intl.formatMessage(
            messages.stationEdit.editStationButton
          )}
        />
      </Fragment>
    );
  };

  return (
    <div id="station-edit-form">
      {typeof uniqueNameValidator === "function"
        ? renderEditForm(props.stationKey)
        : null}
    </div>
  );
};

export default reduxForm({
  form: "stationEdit",
})(injectIntl(StationEditForm));

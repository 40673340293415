import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { fetchStatus } from "../reducer";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../messages";
import { Row, Col } from "react-bootstrap/lib";
import {
  H2,
  P,
  GeneralError,
  TextLabel,
  ListSelect,
  Input,
  TextArea,
  FileUpload,
} from "../../../../../components";

const recipientTypes = ["VOLUNTEERS", "CONTACT_PERSONS"];

class SendOut extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mappedRecipientTypes: recipientTypes.map(rt => ({
        id: rt,
        value: this.props.intl.formatMessage({ id: `form.recipientTypeOptions.${rt}` })
      })),
    }
  }

  componentDidMount() {
    this.props.fetchSendOut({
      sendOutId: this.props.match.params.sendOutId,
      eventId: this.props.event.id
    });
  }

  renderSelectedStations() {
    const stations = [];

    this.props.sendOut.stations.forEach(s => {
      const station = this.props.event.stations.find(es => es.id === s);
      if (stations) {
        stations.push(station.name);
      }
    });

    return stations.join(", ");
  }

  render() {
    if (this.props.status === fetchStatus.FETCHING) {
      return (<i className="fas fa-spinner fa-pulse" />);
    }

    if (!this.props.sendOut) {
      return null;
    }

    if (this.props.status === fetchStatus.FETCH_FAILED) {
      return (
        <GeneralError><FormattedMessage {...messages.single.error} /></GeneralError>
      )
    }
    return (
      <Fragment>
        <H2><FormattedMessage {...messages.single.title} /></H2>
        <Row>
          <Col md={5}>
            <TextLabel><FormattedMessage {...messages.form.receivers.title} /></TextLabel>
          </Col>
          <Col md={7}>
            <P>{this.renderSelectedStations()}</P>
          </Col>
        </Row>
        <ListSelect
          name="recipientTypes"
          placeholder={this.props.intl.formatMessage(messages.form.recipientTypes)}
          data={this.state.mappedRecipientTypes}
          valueField="id"
          textField="value"
          readonly
          input={{ value: this.props.sendOut.recipientTypes }}
          meta={{}}
        />
        <Input
          readonly
          placeholder={this.props.intl.formatMessage(messages.form.subject)}
          input={{ value: this.props.sendOut.subject }}
          meta={{}}
        />
        <TextArea
          readonly
          placeholder={this.props.intl.formatMessage(messages.form.message)}
          input={{ value: this.props.sendOut.message }}
          meta={{}}
        />
        <FileUpload
          readonly
          placeholder={this.props.intl.formatMessage(messages.form.attachments)}
          files={this.props.sendOut.attachments}
          meta={{}}
        />
      </Fragment>
    );
  }

}

export default withRouter(injectIntl(SendOut));

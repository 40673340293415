export const GROUP_AVAIL_SAVE = 'GROUP_AVAIL_SAVE';
export const GROUP_AVAIL_SAVE_SUCCESS = 'GROUP_AVAIL_SAVE_SUCCESS';
export const GROUP_AVAIL_SAVE_FAILED = 'GROUP_AVAIL_SAVE_FAILED';
export const GROUP_AVAIL_FETCH = 'GROUP_AVAIL_FETCH';
export const GROUP_AVAIL_FETCH_SUCCESS = 'GROUP_AVAIL_FETCH_SUCCESS';
export const GROUP_AVAIL_FETCH_FAILED = 'GROUP_AVAIL_FETCH_FAILED';

export const status = {
  SAVING: 'SAVING',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  SAVE_FAILED: 'SAVE_FAILED',
};

export const saveAvailabilitiesStatus = status;

export function saveAvailabilities (data) {
  return {
    type: GROUP_AVAIL_SAVE,
    payload: data,
  };
}

export function fetchAvailabilities (data) {
  return {
    type: GROUP_AVAIL_FETCH,
    payload: data,
  };
}

const initialState = {
  status: status.NOT_SUBMITTED,
  availabilities: null,
};

export default function groupAvailability (state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GROUP_AVAIL_SAVE:
      return Object.assign({}, state, {status: status.SAVING});
    case GROUP_AVAIL_FETCH_SUCCESS:
      return Object.assign({}, state, {availabilities: action.payload});
    case GROUP_AVAIL_FETCH_FAILED:
      return Object.assign({}, state, {availabilities: [], error: action.payload});
    case GROUP_AVAIL_SAVE_SUCCESS:
      return Object.assign({}, state, {status: status.SAVE_SUCCESS, availabilities: action.payload});
    case GROUP_AVAIL_SAVE_FAILED:
      return Object.assign({}, state, {status: status.SAVE_FAILED, availabilities: [], error: action.payload});
    default:
      return state;
  }
}


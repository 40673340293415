import { connect } from 'react-redux';
import SelfRegistrationForm from './SelfRegistrationForm';
import { fetchPublicEvent, submitVolunteer } from './reducer';

const mapStateToProps = ( state ) => {
    const obj = {
        status: state.selfRegistration.status,
        fetchStatus: state.selfRegistration.fetchStatus,
        formError: state.selfRegistration.error,
        event: state.selfRegistration.event,
    }
    return obj;
};

const mapDispatchToProps = ( dispatch ) => ({
    onSubmit: data => dispatch(submitVolunteer(data)),
    fetchEvent: eventId => dispatch(fetchPublicEvent(eventId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfRegistrationForm);

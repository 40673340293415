// TODO fix this file!!!
// this file is bad, we should move form declaration to parent component and make this to a form section https://redux-form.com/6.5.0/docs/api/formsection.md/


import React from 'react';
import sports from '../../../../sports.json';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap/lib';
import {
  P,
  FormSectionTitle,
  Input,
  Select,
  ListSelect,
  FormGroup,
  GeneralError,
  MultiSelect,
  ContactForm,
} from '../../../../components/index';
import { BigGreenButton } from '../../../../components/Buttons/index';
import { required, minLength3, positiveNumbers, integer } from '../../../../validators/index';
import { groupStatus } from '../reducer';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import { municipalities } from '../../../../constants/municipalities';
import {lists} from '../../../../constants/lists.js'; // TODO: Add updated volex-data repository
const {skills} = lists;

const withLoadedProps = (LoadedComponent, loadedProps) => props => <LoadedComponent {...loadedProps} {...props} />;

const ages = [
  {id: '10', value: '10'},
  {id: '11', value: '11'},
  {id: '12', value: '12'},
  {id: '13', value: '13'},
  {id: '14', value: '14'},
  {id: '15', value: '15'},
  {id: '16', value: '16'},
  {id: '17', value: '17'},
  {id: '18+', value: '18+'},
];

// Sort on name but keep 'Ingen' (id 0) on top
const sortSportOnName = (a,b) => {
  if (a.id !== '0' && b.id !== '0' && a.name < b.name) {
    return -1;
  }
  if (a.id !== '0' && b.id !== '0' && a.name > b.name) {
    return 1;
  }
  return 0;
};

const sortedSports = sports.sort(sortSportOnName);


class GroupForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      readonly: !this.isNew(),
      contactList: this.isNew() ? this.getInitialContactList() : this.props.group && Array.isArray(this.props.group.contactPersons) ? this.props.group.contactPersons : [],
      showDeleteContactModal: false,
      deletingContactPerson: false,
      contactToDelete: null,
      newContactEmail: null,
      contactError: null,
      emailInvalid: null,
      submitClicked: false,
      nextClicked: false,
    };

    this.toggleReadonly = this.toggleReadonly.bind(this);
    this.renderSkills = this.renderSkills.bind(this);
    this.renderMunicipality = this.renderMunicipality.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.saveGroup = this.saveGroup.bind(this);
    this.updateContactListFromForm = this.updateContactListFromForm.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    if (this.isNew() && nextProps.user && !this.props.user) {
      this.setState({
        contactList: [{email: nextProps.user.email, name: `${nextProps.user.firstName } ${ nextProps.user.lastName}`, phone: nextProps.user.phone}]
      })
    }
    if (nextProps.status === groupStatus.SUCCESS && this.props.status !== groupStatus.SUCCESS) {
      this.setState({
        submitClicked: false,
      });
    }
  }

  getInitialContactList () {
    if (this.props.user) {
      const user = this.props.user;
      return [{email: user.email, name: `${user.firstName } ${ user.lastName}`, phone: user.phone}];
    }
    return [];
  }

  updateContactListFromForm (contactList) {
    this.setState({contactList});
  }

  validateContact (contactList) {
    const listContainsSubmittedEmail = contactList.find(contact => contact.email === this.state.newContactEmail && !contact.toBeDeleted);

    if (!this.state.newContactEmail) {
      this.setState({
        contactError: <GeneralError><FormattedMessage {...messages.editGroup.form.contactEmptyMessage} /></GeneralError>,
      });
    } else if (listContainsSubmittedEmail) {
      this.setState({
        contactError: <GeneralError><FormattedMessage {...messages.editGroup.form.contactExistMessage} /></GeneralError>,
      });
    } else if (!this.state.emailInvalid) {
      this.props.getUserByEmail({email: this.state.newContactEmail});
    }
  }

  handleContactSubmit() {
    this.validateContact(this.state.contactList);
  }

  renderSkills () {
    const skillOptions = skills.map(skill => ({id: skill, name: this.props.intl.formatMessage({id: `lists.skills.${skill}`})}));
    return (
      <Field
        placeholder={this.props.intl.formatMessage(messages.form.alignment.skills)}
        data={skillOptions}
        valueField="id"
        textField="name"
        readonly={this.state.readonly}
        name="skills"
        component={MultiSelect}
      />
    );
  }

  renderMunicipality () {
    return (
      <Field
        placeholder={this.props.intl.formatMessage(messages.form.groupInfo.municipality)}
        data={municipalities}
        valueField="id"
        textField="name"
        filter
        readonly={this.state.readonly}
        validate={[required]}
        name="municipality"
        component={Select}
      />
    );
  }

  isNew () {
    return !this.props.match.params.id;
  }

  toggleReadonly (e) {
    e && e.preventDefault();

    this.setState({
      readonly: !this.state.readonly,
    });
  }

  renderButtons () {
    const buttons = [];
    if (!this.isNew() && this.state.readonly) {
      buttons.push(<BigGreenButton type="button" key="1" onClick={this.toggleReadonly} label={this.props.intl.formatMessage(messages.editGroupButton)} />);
    }
    else if (!this.isNew()) {
      buttons.push(<BigGreenButton type="submit" key="1" label={this.props.intl.formatMessage(messages.saveGroupButton)} busy={this.state.submitClicked} />);
    } 
    if (this.props.onClickNext) {
      buttons.push(<BigGreenButton type="button" key="2" onClick={this.props.handleSubmit(this.handleClickNext)} label={this.props.intl.formatMessage(messages.nextButton)} busy={this.state.nextClicked} />);
    }

    return buttons;
  }

  saveGroup (fieldValues) {
    const newGroup = Object.assign({}, fieldValues, {contactPersons: this.state.contactList.filter(c => !c.toBeDeleted)});
    newGroup.numberOfMembers = parseInt(newGroup.numberOfMembers, 10);

    if (this.props.group) {
      newGroup.id = this.props.group.id;
    }

    this.props.submitGroup(newGroup);
  }

  handleClickNext (fieldValues) {
    this.setState({
      nextClicked: true,
    });

    this.saveGroup(fieldValues);
    this.props.onClickNext(1);
  }

  handleSubmit(fieldValues) {
    if (this.state.submitClicked) {
      return;
    }

    this.setState({
      submitClicked: true,
    });

    this.saveGroup(fieldValues);
    if (!this.props.onClickNext) {
      this.toggleReadonly();
    }
  }

  render () {
    if ((!this.isNew() && (this.props.status === groupStatus.NOT_SUBMITTED || this.props.status === groupStatus.FETCHING)) || !this.props.user) {
      return (<P>Show spinner...</P>);
    }

    return (<form noValidate onSubmit={this.props.handleSubmit(this.handleSubmit)}>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <FormSectionTitle><FormattedMessage {...messages.form.groupInfo.title} /></FormSectionTitle>
            <Field
              name="name"
              placeholder={this.props.intl.formatMessage(messages.form.groupInfo.name)}
              type="text"
              component={Input}
              readonly={this.state.readonly}
              validate={[required, minLength3]}
            />
            <Field
              name="organizationNumber"
              placeholder={this.props.intl.formatMessage(messages.form.groupInfo.orgNumber)}
              type="text"
              component={Input}
              readonly={this.state.readonly}
              validate={[required]}
            />
            <Field
              name="organizationName"
              placeholder={this.props.intl.formatMessage(messages.form.groupInfo.orgName)}
              type="text"
              component={Input}
              readonly={this.state.readonly}
              validate={[required]}
            />
            {this.renderMunicipality()}
          </FormGroup>
          <FormGroup>
            <FormSectionTitle><FormattedMessage {...messages.form.members.title} /></FormSectionTitle>
            <Field
              name="numberOfMembers"
              placeholder={this.props.intl.formatMessage(messages.form.members.numberOfMembers)}
              type="number"
              min="0"
              component={Input}
              readonly={this.state.readonly}
              validate={[required, positiveNumbers, integer]}
            />
            <Field
              name="ages"
              component={withLoadedProps(ListSelect, {display: 'inline-block'})}
              valueField="id"
              textField="value"
              readonly={this.state.readonly}
              placeholder={this.props.intl.formatMessage(messages.form.ages.ages)}
              data={ages}
              validate={[required]}
            />
            <Field
              name="sport"
              placeholder={this.props.intl.formatMessage(messages.form.alignment.sport)}
              component={Select}
              valueField="id"
              textField="name"
              readonly={this.state.readonly}
              data={sortedSports}
            />
            {this.renderSkills()}
          </FormGroup>
          <ContactForm
            intl={this.props.intl}
            readonly={this.state.readonly}
            contactList={this.state.contactList}
            updateContactList={this.updateContactListFromForm}
            type='group'
          />
          {/* Uncomment block below when payment info should be added */}
          {/* <FormGroup>
            <FormSectionTitle><FormattedMessage {...messages.form.payment.title} /></FormSectionTitle>
            <Field
              name="bank"
              placeholder={this.props.intl.formatMessage(messages.form.payment.bank)}
              type="text"
              component={Input}
              readonly={this.state.readonly}
              validate={[required]}
            />
            <Field
              name="accountNumber"
              placeholder={this.props.intl.formatMessage(messages.form.payment.accountNumber)}
              type="text"
              component={Input}
              readonly={this.state.readonly}
              validate={[required]}
            />
          </FormGroup> */}
        </Col>
      </Row>
      <Row>
        <Col sm={10}>
          {this.renderButtons()}
        </Col>
      </Row>
    </form>);
  }
}

const formName = 'groupForm';
const GroupFormForm = reduxForm({
  form: formName,
})(GroupForm);

export default injectIntl(GroupFormForm);

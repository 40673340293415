import { defineMessages } from 'react-intl';

export default defineMessages({
  menuItems: {
    organizer: {
      id: 'menu-item.organizer',
    },
    volunteer: {
      id: 'menu-item.volunteer',
    },
    myPages: {
      id: 'menu-item.my-pages',
    },
    myProfile: {
      id: 'my-profile.title'
    },
    register: {
      id: 'menu-item.register',
    },
    login: {
      id: 'menu-item.login',
    },
    logOut: {
      id: 'menu-item.logout',
    },
    language: {
      swe: {
        id: 'menu-item.language.swe.placeholder',
      },
      eng: {
        id: 'menu-item.language.eng.placeholder',
      },
      nor: {
        id: 'menu-item.language.nor.placeholder',
      },
    },
    contact: {
      id: 'menu-item.contact',
    },
  },
});

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { submitEvent, reset, confirmEvent } from "../reducer";
import EventEdit from "./EventEdit";
import { getUserByEmail, resetUser } from "../../../User/reducer";

const mapStateToProps = (state, ownProps) => ({
  event: ownProps.event,
  status: state.event.status,
  // TODO: Temp solution to handle weird backend response. Handle in backend instead.
  initialValues: Array.isArray(ownProps.event) && ownProps.event[0].Attributes ? ownProps.event[0].Attributes : ownProps.event,
  user: state.auth.user,
  userEmail: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  submitEvent: data => dispatch(submitEvent(data)),
  confirmEvent: data => dispatch(confirmEvent(data)),
  resetEvent: () => dispatch(reset()),
  getUserByEmail: (data) => dispatch(getUserByEmail(data)),
  resetUser: () => dispatch(resetUser()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EventEdit));

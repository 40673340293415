import { Grid } from 'react-bootstrap/lib';
import styled from 'styled-components';

const Container = styled(Grid)`
  @media (min-width: 1170px) {
    width: 980px;
  }
`;

export default Container;

import { connect } from "react-redux";
import { changePassword, reset } from "../../../auth/reducer";
import ChangePassword from "./ChangePassword";

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: data => dispatch(changePassword(data)),
  resetAuth: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);

import { call, put } from 'redux-saga/effects';
import { VOLUNTEER_SUCCESS, VOLUNTEER_FAILED, FETCH_SUCCESS, FETCH_FAILED } from './reducer';
import { submitVolunteer, getPublicEvent } from './service';

export function* addVolunteer (action) {
    try {
        const response = yield call(() => submitVolunteer(action.payload));
        yield put ({type: VOLUNTEER_SUCCESS, payload: response});
    } catch (e) {
        yield put({type: VOLUNTEER_FAILED, payload: e.response.data.message});
    }
}

export function* fetchPublicEvent (action) {
    try {
        const response = yield call(() => getPublicEvent(action.payload));
        yield put ({type: FETCH_SUCCESS, payload: response});
    } catch (e) {
        yield put({type: FETCH_FAILED, payload: e.response.data.message});
    }
}
import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap/lib";
import { BigGreenButton } from "../../../../components/Buttons";
import { injectIntl, FormattedMessage } from "react-intl";
import { groupStatus } from "../reducer";
import messages from "../GroupConfirm/messages";
import styled from "styled-components";
import VolunteerForm from "./VolunteerForm";
import VolunteerList from "./VolunteerList";
import {
  ConfirmationModal,
} from "../../../../components";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

class Volunteers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      volunteerToEdit: null,
      confirming: false,
      showModal: false,
      groupStatus: this.props.group ? this.props.group.status : {},
    };

    this.saveGroup = this.saveGroup.bind(this);
    this.isNew = this.isNew.bind(this);
    this.onVolunteerRowClick = this.onVolunteerRowClick.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === groupStatus.SUCCESS && this.state.submitting) {
      this.setState({
        submitting: false,
        volunteerToEdit: null,
      });
    }

    if (prevProps.group.status !== "CONFIRMED" && this.props.group.status === "CONFIRMED") {
      this.setState({
        confirming: false,
        showModal: null,
      });
      this.props.history.push(`/my-pages/volunteers/${this.props.group.groupId}/volunteers`);
    }
  }

  isNew() {
    return !this.props.match.params.id;
  }

  handleConfirm() {
    this.setState({
      confirming: true,
    });

    this.props.confirmGroup(this.props.group);
  }

  toggleModal(e) {
    e && e.preventDefault();
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  saveGroup(volunteersList) {
    const groupToSubmit = this.props.group;
    groupToSubmit.volunteers = volunteersList;
    this.props.submitGroup(groupToSubmit);
    this.setState({
      submitting: true,
    });
  }

  onVolunteerRowClick(key, row) {
    this.setState({
      volunteerToEdit: row,
      volunteerEditKey: key,
      editingVolunteer: true,
    });
  }

  render() {
    return (<Fragment>
      <VolunteerForm {...this.props} volunteerToEdit={this.state.volunteerToEdit}
                     saveGroup={this.saveGroup}
      />
      <Row>
        <Col sm={12}>
          <Wrapper>
            <VolunteerList {...this.props} onVolunteerRowClick={this.onVolunteerRowClick}
                           saveGroup={this.saveGroup}
                           submittingVolunteerForm={this.state.submitting}
            />
          </Wrapper>
        </Col>
      </Row>
      <Row>
        <Col sm={10}>
          {this.props.group && this.props.group.status === "DRAFT"
            ? <BigGreenButton
              onClick={this.toggleModal}
              label={this.props.intl.formatMessage(messages.confirmButton)}
            />
            : null
          }
        </Col>
      </Row>
      <ConfirmationModal
        label={this.props.intl.formatMessage(messages.cancelConfirmButton)}
        show={this.state.showModal}
        handleConfirm={this.handleConfirm}
        handleHide={this.toggleModal}
        buttonText={this.props.intl.formatMessage(messages.confirmModalButton)}
        title={this.props.intl.formatMessage(messages.confirmationModalTitle)}
        text={
          <FormattedMessage {...messages.confirmationModalText} values={{ name: this.props.group ? this.props.group.name : "" }} />}
        busy={this.state.confirming}
        theme="green"
      />
    </Fragment>);
  }
}

export default injectIntl(Volunteers);

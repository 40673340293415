import { API } from 'aws-amplify';

export const submitVolunteer = data => {
    const request = {
        body: data,
    }
    return API.post('volex-protected', `/groups/addVolunteer`, request);
}

export const getPublicEvent = eventId => {
    return API.get('volex-protected',`/public/events/${eventId}`);
}
import { defineMessages } from 'react-intl';

export default defineMessages({
	warning: {
		id: 'test-block.warning'
	},
	button: {
		id: 'test-block.button'
	},
	placeholder: {
		id: 'test-block.placeholder'
	},
});

import React, { Component, Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col } from "react-bootstrap/lib";
import styled from "styled-components";
import messages from "./../messages";
import AddGroup from "../AddGroup";
import { ViewVolunteers } from ".";
import { SmallGrayButton } from "../../../../../components/Buttons";
import { breakPoints } from "../../../../../constants/styling-constants";


const SummaryWrapper = styled.div`
  font-size: 18px;
  justify-content: space-between;
  font-weight: 500;
  color: #404040;
  background: white;
  margin-bottom: 20px;
  padding-bottom: 15px;
  padding-top: 15px;
`;

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: inline-flex;
`;

const SummaryHeader = styled(Row)`
  font-weight: 500;
  font-size: 24px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

const SkillBox = styled.div`
  border: 1px solid #dddddd;
  margin-left: 2px;
  margin-bottom: 2px;
  display: inline-block;
  background-color: #eeeeee;
  &.empty {
    border: 0px;
    background-color: transparent;
  }
  p {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

const SkillWrapper = styled.div`
  display: flow-root;
  margin-left: -5px;
`;

const SummaryRow = styled(Row)`
  margin-left: 5px;
  margin-bottom: 5px;
`;

const ButtonWrapper = styled(Row)`
  margin-left: 15px;
  width: 90%;
  justify-content: space-around;
  @media (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
    align-items: center;
  }
`;

class AddVolunteersForm extends Component {
  componentDidMount() {
    if (!Array.isArray(this.props.availableGroups)) {
      const { municipality, start, end, id } = this.props.station;
      this.props.fetchAvailableGroups({
        eventId: this.props.eventId,
        location: municipality,
        startDate: start,
        endDate: end,
        stationId: id
      });
    }
  }

  StationSummaryRow(){
    const { station, 
      officialsProposed,
      officialsAllocated, 
      officialsDraft } = this.props;

    return (
      <div>
        <SummaryWrapper>
          <SummaryHeader>
            <Col xs={6}>
              {station.name}
            </Col>
          </SummaryHeader>
          <SummaryRow>
            <Col xs={6}>
              <FormattedMessage {...messages.station.officialQuantity} />
              {": "}
            </Col>
            <Col xs={6}>
              {station.officialQuantity}
            </Col>
          </SummaryRow>
          <SummaryRow>
            <Col xs={6}>
              <FormattedMessage {...messages.station.officialsNotAllocated} />
              {": "}
            </Col>
            <Col xs={6}>{station.officialQuantity - officialsProposed - officialsAllocated - officialsDraft}</Col>
          </SummaryRow>
          {this.renderMinimumAge()}
          {this.renderSkills()}
        </SummaryWrapper>
      </div>
    );
  }


  renderMinimumAge() {
    const { station } = this.props;
    return (
      <SummaryRow>
        <Col xs={6}>
          <FormattedMessage {...messages.station.officialMinimumAge} />:
          {" "}
        </Col>
        <Col xs={6}>{station.officialMiniumumAge}</Col>
      </SummaryRow>
    );
  }

  renderSkills() {
    const { station, intl } = this.props;
    return (
      <SummaryRow>
        <Col
          xs={6}
        >
          <FormattedMessage {...messages.station.skills} />:
          {" "}
        </Col>
        <Col xs={6}>
          <SkillWrapper>
            {station.skills && station.skills.length > 0 ? (
              station.skills.map((skill, index) => (
                <SkillBox key={index}>
                  <p>{intl.formatMessage({ id: `lists.skills.${skill}` })}</p>
                </SkillBox>
              ))
            ) : (
              <SkillBox className="empty">
                <p><FormattedMessage {...messages.station.noSkills} /></p>
              </SkillBox>
            )}
          </SkillWrapper>
        </Col>
      </SummaryRow>
    );
  }

  render() {
    const { availableGroups, ...restProps } = this.props;
    const { station } = this.props;
    return (
      <Fragment>
        {this.StationSummaryRow()}
        <ViewVolunteers 
          {...this.props}
        />
        <Header>
          <FormattedMessage {...messages.addGroup.button} />
        </Header>
        <Row style={{marginBottom: "20px"}}>
          <Col sm={12} md={12}>
            <AddGroup
              {...restProps}
              availableGroups={
                !Array.isArray(availableGroups)
                  ? []
                  : availableGroups
              }
              form={"add-group-" + station.id}
            />
          </Col>
        </Row>
        <ButtonWrapper>
          <SmallGrayButton
              type="button"
              onClick={() => this.props.prevModal()}
              label={"Föregående"}
            />
          <SmallGrayButton
              type="button"
              onClick={() => this.props.nextModal()}
              label={"Nästa"}
            />
          </ButtonWrapper>
      </Fragment>
    );
  }
}

let AddVolunteersFirst = injectIntl(AddVolunteersForm);

export default AddVolunteersFirst;

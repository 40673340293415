import { Auth, API } from 'aws-amplify';

export const getUserByEmail = ({email}) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/user/${email}`, request);
    });

export const createUser = (data) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: data,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.post('volex-protected', `/user`, request);
    });
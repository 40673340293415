import React, { Component, Fragment } from "react";
import {
  ContentWrapper,
  Container,
  WhiteH1,
  H2,
  P,
  ImageBackground
} from "../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import img from "../../images/Volex_bild_undersida7.jpg";
import messages from "./messages";
import { FormattedMessage } from "react-intl";

const Background = ImageBackground.extend`
  background-image: url(${img});
`;

class About extends Component {
  render() {
    return (
      <Fragment>
        <Background>
          <Container>
            <WhiteH1><FormattedMessage {...messages.headerTitle} /></WhiteH1>
          </Container>
        </Background>
        <ContentWrapper>
          <Container>
            <Row>
              <Col sm={9}>
                <H2><FormattedMessage {...messages.pageTitle} /></H2>
                <P><FormattedMessage {...messages.section1} /></P>
                <P><FormattedMessage {...messages.section2} /></P>
                <P><FormattedMessage {...messages.section3} /></P>
                <P><FormattedMessage {...messages.section4} /></P>
                <P><FormattedMessage {...messages.section5} /></P>
                <P><FormattedMessage {...messages.section6} /></P>
                <P><FormattedMessage {...messages.section7} /></P>
                <P><FormattedMessage {...messages.section8} /></P>
                <P><FormattedMessage {...messages.section9} /></P>
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default About;

import React from 'react';
import { Modal } from 'react-bootstrap/lib';
import { MediumGrayButton, SmallPinkButton, SmallGreenButton } from '../Buttons';
import { H2, P } from '../index';
import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';

export const ModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 530px;
  }
  .modal-content {
    border-radius: 0;
    padding: 30px;
  }
`;

export const Content = styled.section`
  text-align: center;
  padding: 0 15px;
  i {
    color: ${colors.pink};
    font-size: 110px;
    margin-bottom: 25px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  padding: 0 15px;
`;

const renderConfirmButton = (theme, label, busy, handleConfirm) => {
  switch(theme) {
    case 'pink':
      return <SmallPinkButton label={label} busy={busy} onClick={handleConfirm}/>;
    case 'green':
      return <SmallGreenButton label={label} busy={busy} onClick={handleConfirm}/>;
    case 'gray':
      return <MediumGrayButton label={label} busy={busy} onClick={handleConfirm}/>;
    default:
      return <MediumGrayButton label={label} busy={busy} onClick={handleConfirm}/>;
  }
};

const ReminderModal = (
  {
    show,
    handleHide,
    title = '',
    buttonText = <FormattedMessage {...messages.modalConfirmButtonText} />,
    text = '',
    busy = false,
    theme = 'gray',
    handleConfirm = handleHide
  }) => (
  <ModalWrapper show={show} onHide={handleHide} bsSize="large">
    <Modal.Body>
      <Content>
        <H2>{title}</H2>
        <P>{text}</P>
      </Content>
      <Footer>
        {renderConfirmButton(theme, buttonText, busy, handleConfirm)}
      </Footer>
    </Modal.Body>
  </ModalWrapper>
);

export default injectIntl(ReminderModal);

import { connect } from "react-redux";
import ContactForm from "./ContactForm";
import { getUserByEmail, resetUser } from "../../containers/User/reducer";

const mapStateToProps = state => ({
  userEmail: state.user,
  user: state.auth.user,
  newUserStatus: state.user.newUserStatus,
  newUser: state.user.newUser,
});

const mapDispatchToProps = (dispatch) => ({
  getUserByEmail: (data) => dispatch(getUserByEmail(data)),
  resetUser: () => dispatch(resetUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm);

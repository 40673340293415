import React, { Fragment } from "react";
import { DateTimePicker } from "react-widgets";
import styled from "styled-components";
import { SmallError } from "./Error";
import { colors } from "../../constants/styling-constants";
import { TextLabel, P } from "../index";
import { Row, Col } from "react-bootstrap/lib";
import moment from "moment";

const StyledDatePicker = styled(DateTimePicker)`
  font-size: 17px;
  margin-bottom: 15px;
  .rw-widget-picker {
    background-color: #FFF;
    width: 100%;
    border: none;
    border-radius: 0;
  }
  &.rw-state-focus .rw-widget-picker {
    box-shadow: none;
  }
  input {
    outline: none;
    padding: 13px 15px;
    &::placeholder {
      color: ${colors.lightGray4};
    }
  }
  &.has-error {
    margin-bottom: 5px;
    input {
      &::placeholder {
        color: ${colors.pink};
      }
    }
    input, .rw-select {
      background-color: ${colors.lightPink2}
    }
  }
`;

const DatePickerHOC = (ErrorComponent = SmallError) => ({ className = "", input, placeholder, meta: { touched, error, warning }, date = true, time = false, readonly = false }) => {
  if (readonly) {
    return (
      <Row>
        <Col md={5}>
          <TextLabel>{placeholder}</TextLabel>
        </Col>
        <Col md={7}>
          <P>{moment(input.value).format((time && date && "YYYY-MM-DD HH:mm") || (date && "YYYY-MM-DD") || (time && "HH:mm") || "")}</P>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <StyledDatePicker
        className={`${className} ${touched && error ? "has-error" : ""}`}
        onChange={input.onChange}
        onBlur={() => input.onBlur()}
        format={(time && date && "YYYY-MM-DD HH:mm") || (date && "YYYY-MM-DD") || (time && "HH:mm") || ""}
        time={time}
        date={date}
        placeholder={placeholder}
        value={!input.value ? null : new Date(input.value)}
        timeFormat="HH:mm"
      />
      {touched &&
      ((error && <ErrorComponent error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

const SmallDatePicker = styled(DatePickerHOC(SmallError))`
  font-size: 12px;
  margin-bottom: 11px;
  .rw-widget-picker {
    background-color: #FFF;
    width: 100%;
    border: none;
    border-radius: 0;
  }
  &.rw-state-focus .rw-widget-picker {
    box-shadow: none;
  }
  input {
    padding: 9px 11px;
  }
`;

const DatePicker = DatePickerHOC();

export const TimePicker = (props) => <DatePicker {...props} date={false} time />

export default DatePicker;

export {
  SmallDatePicker
};

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './components/Main';
import Login from './containers/User/Login';
import Register from './containers/User/Register';
import ConfirmRegistration from './containers/User/ConfirmRegistration';
import ForgotPassword from './containers/User/ForgotPassword';
import ChangePassword from './containers/User/ChangePassword';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/verify-email" component={ConfirmRegistration} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/" component={Main} />
      </Switch>
    );
  }
}

export default App;

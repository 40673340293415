import React, { Component } from "react";
import { H2 } from "../../../components/index";
import { Field, reduxForm } from "redux-form";
import { required, minLength3 } from "../../../validators";
import { Row, Col } from "react-bootstrap/lib";
import { FormGroup, Input, Select } from "../../../components";
import { SmallGrayButton, Button } from "../../../components/Buttons";
import messages from "./messages";
import { injectIntl } from "react-intl";
import { countryCodes } from "../../../constants/country-codes";

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readonly: true
    };

    this.toggleReadonly = this.toggleReadonly.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
  }

  toggleReadonly(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      readonly: !this.state.readonly
    });
  }

  handleProfileSubmit(user) {
    this.props.onSubmit(user);
    this.toggleReadonly();
  }

  renderButtons() {
    if (this.state.readonly) {
      return (
        <SmallGrayButton
          onClick={this.toggleReadonly}
          label={this.props.intl.formatMessage(messages.editButton)}
          type="button"
        />
      );
    }
    return (
      <Button label={this.props.intl.formatMessage(messages.saveButton)} />
    );
  }

  renderPhone() {
    if (this.state.readonly) {
      return (
        <Field
          name="phone"
          placeholder={this.props.intl.formatMessage(messages.form.phone)}
          type="text"
          component={Input}
          validate={[required, minLength3]}
          readonly={this.state.readonly}
        />
      );
    } else {
      return (
        <Row>
          <Col xs={5}>
            <Field
              name="countryCode"
              placeholder={this.props.intl.formatMessage(
                messages.form.countryCode
              )}
              component={Select}
              validate={[required]}
              data={countryCodes}
            />
          </Col>
          <Col xs={7}>
            <Field
              name="phoneWithoutCountryCode"
              placeholder={this.props.intl.formatMessage(messages.form.phone)}
              type="text"
              component={Input}
              validate={[required, minLength3]}
            />
          </Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.handleProfileSubmit)}>
        <Row>
          <Col md={8}>
            <FormGroup>
              <H2>{this.props.intl.formatMessage(messages.title)}</H2>
              <Field
                name="firstName"
                placeholder={this.props.intl.formatMessage(
                  messages.form.firstName
                )}
                type="text"
                component={Input}
                validate={[required, minLength3]}
                readonly={this.state.readonly}
              />
              <Field
                name="lastName"
                placeholder={this.props.intl.formatMessage(
                  messages.form.lastName
                )}
                type="text"
                component={Input}
                validate={[required, minLength3]}
                readonly={this.state.readonly}
              />
              <Field
                name="email"
                placeholder={this.props.intl.formatMessage(messages.form.email)}
                type="email"
                component={Input}
                validate={[required, minLength3]}
                readonly={true}
              />
              {this.renderPhone()}
            </FormGroup>
            {this.renderButtons()}
          </Col>
        </Row>
      </form>
    );
  }
}

export default reduxForm({
  form: "myProfile",
  enableReinitialize: true,
  destroyOnUnmount: false
})(injectIntl(MyProfile));

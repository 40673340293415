import React from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import EventStart from './EventStart';
import EventNewBase from './NewBase';
import EventCopyBase from './CopyBase';
import EventBase from './Base';
import styled from 'styled-components';
import { colors } from '../../../constants/styling-constants';

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.lightPink};
`;

const EventRouter = ({ match }) => {
  localStorage.setItem('homePath', 'events');
  return (
    <Wrapper>
      <Switch>
        <Route exact path={`${match.url}`} component={EventStart} />
        <Route path={`${match.url}/new`} component={EventNewBase} />
        <Route path={`${match.url}/copy`} component={EventCopyBase} />
        <Route path={`${match.url}/:id`} component={EventBase} />
      </Switch>
    </Wrapper>
  );
};

export default EventRouter;

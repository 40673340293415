import { defineMessages } from 'react-intl';

export default defineMessages({
  passwordRules: {
    title: {
      id: 'register.passwordRules.title',
    },
    lowerCase: {
      id: 'register.passwordRules.lowerCase',
    },
    upperCase: {
      id: 'register.passwordRules.upperCase',
    },
    number: {
      id: 'register.passwordRules.number',
    },
    length: {
      id: 'register.passwordRules.length',
    },
  },
});

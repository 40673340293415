import { defineMessages } from 'react-intl';

export default defineMessages({
  contactForm: {
    title: {
      id: 'contact-form.title',
    },
    contact: {
      id: 'contact-form.contact',
    },
    addContactButton: {
      id: 'contact-form.add-contact-button',
    },
    contactList: {
      name: {
        id: 'contact-form.contact-list.name',
      },
      email: {
        id: 'contact-form.contact-list.email',
      },
      phone: {
        id: 'contact-form.contact-list.phone',
      }
    },
    deleteText: {
      id: 'contact-form.delete-text',
    },
    contactEmptyMessage: {
      id: 'contact-form.contact-empty-message',
    },
    contactExistMessage: {
      id: 'contact-form.contact-exists-message',
    }
  },

  addUserForm: {
    title: {
      id: 'add-user-form.title',
    },
    info: {
      id: 'add-user-form.info-text',
    },
    form: {
      email: {
        id: 'add-user-form.form.email',
      },
      firstName: {
        id: 'add-user-form.form.first-name',
      },
      lastName: {
        id: 'add-user-form.form.last-name',
      },
      countryCode: {
        id: 'add-user-form.form.country-code',
      },
      phoneNumber: {
        id: 'add-user-form.form.phone-number',
      },
    },
    cancelButton: {
      id: 'add-user-form.cancel-button',
    },
    confirmButton: {
      id: 'add-user-form.confirm-button',
    },
    error: {
      id: 'add-user-form.error',
    }
  }
});

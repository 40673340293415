import { call, put } from 'redux-saga/effects';
import { ATTENDANCE_SUBMIT_FAILED, ATTENDANCE_SUBMIT_SUCCESS } from "./reducer";
import { STATION_OFFER_UPDATE } from "../../Offer/reducer";
import { submitAttendance } from "./service";

export function* submitAttendanceSaga(action) {
  try {
    const response = yield call(() => submitAttendance(action.payload));
    yield put({type: ATTENDANCE_SUBMIT_SUCCESS, payload: response});
    yield put({type: STATION_OFFER_UPDATE, payload: response});
  } catch (e) {
    yield put({type: ATTENDANCE_SUBMIT_FAILED, payload: {e, groupId: action.payload.groupId}});
  }
}
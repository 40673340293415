const specialCompetence = [
  'ACROBATICS',
  'CHEERLEADING',
  'DRIVING_LICENCE_B',
  'FORKLIFT_LICENCE',
  'BUS_DRIVER',
  'TRACTOR_LICENCE',
  'BOATING_LICENSE',
  'CHOIR_SINGING',
  'PERCUSSION',
  'BRASS_BAND',
  'FLIPS',
  'STUNT',
];

export default specialCompetence;

import { Auth, API, Storage } from 'aws-amplify';

function S3Exception (message, statusCode) {
  this.message = message;
  this.statusCode = statusCode;
  this.name = 'S3Exception';
}

export const sendSendOut = data =>
  Auth.currentSession()
    .then(async (session) => {
      // Cant get the user id in the backend (can only find user.sub) so we pass it here so that we know the path to the attachments
      let info = await Auth.currentUserInfo();
      data.userId = info.id;

      if (Array.isArray(data.attachments)) {
        const fileKeys = [];

        for (const file of data.attachments) {
          const result = await Storage.put(`${data.eventId}/${file.name}`, file, {
            contentType: file.type,
            level: 'private',
          })
            .catch(err => {
              throw new S3Exception(err.message || 'Cant upload file to S3', err.statusCode);
            });
          fileKeys.push(Object.assign(result, {name: file.name, userId: info.id}));
        }

        data.attachments = fileKeys;
      }

      const request = {
        body: data,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };

      return API.post('volex-protected', `/events/${data.eventId}/sendout`, request);
    });

export const fetchEventSendOuts = (eventId) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/events/${eventId}/sendout`, request);
    });

export const fetchEventSendOut = ({eventId, sendOutId}) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/events/${eventId}/sendout/${sendOutId}`, request);
    });
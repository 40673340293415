import { store } from '../index';
import axios from 'axios';
// import { refreshToken } from "../auth/service";

/**
 * Set the Authorization Bearer on each request
 * (if we have an accessToken)
 */
const injectAuthorizationHeader = (config) => {
  const oauth = store.getState().auth;

  if (oauth.accessToken !== null) {
    config.headers['Authorization'] = `Bearer ${oauth.accessToken}`;
  }

  return config;
};


/**
 * Intercept all errors.
 *
 * If it is a 401 run the refreshToken request
 * and redo the request afterwards.
 */
export function refreshTokenSetup (error) {
  /* TODO...
  let oauth = store.getState().auth;

  if (oauth !== null && error.response.status === 401) {
    return refreshToken()
      .then(() => axios(error.request.config));
  }
  */

  return Promise.reject(error);
}

export const initAxios = () => {
  axios.interceptors.request.use(injectAuthorizationHeader);
  axios.interceptors.response.use(response => response, refreshTokenSetup);
};

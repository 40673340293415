import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap/lib";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import { Field, reduxForm } from "redux-form";
import { Button } from "../../../../components/Buttons/index";
import { eventStatus } from "../reducer";
import styled from "styled-components";

import {
  H1,
  P,
  FormGroup,
  Input,
  DatePicker,
  ContentWrapper,
  ConfirmationModal,
  TimePicker
} from "../../../../components";

const StyledList = styled.ul`
  list-style: circle;
  padding-left: 2em;
  line-height: 1.5;
`;

class ConfirmEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      confirming: false,
      eventStatus: this.props.event ? this.props.event.status : {},
    };

    this.handleConfirm = this.handleConfirm.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    if (this.props.location && this.props.location.pathname === "/my-pages/events/new") {
      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === eventStatus.SUCCESS && this.props.status === eventStatus.SUBMITTING && this.state.confirming) {
      this.toggleModal();
      this.setState({
        confirming: false,
        eventStatus: nextProps.event.status,
      });
    }
  }

  handleConfirm() {
    this.setState({
      confirming: true,
    });

    this.props.confirmEvent(this.props.event);
  }

  toggleModal(e) {
    e && e.preventDefault();
    this.setState({
      showModal: !this.state.showModal,
    });
  }


  render() {
    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1><FormattedMessage {...messages.confirmTitle} /></H1>
            <P><FormattedMessage {...messages.confirmText1} /></P>
          </Col>
        </Row>
        <form>
          <Row>
            <Col sm={7}>
              <FormGroup>
                <Field
                  name="name"
                  placeholder={this.props.intl.formatMessage(messages.event.name)}
                  type="text"
                  component={Input}
                  readonly
                />
                <Field
                  name="date"
                  placeholder={this.props.intl.formatMessage(messages.event.date)}
                  type="text"
                  component={DatePicker}
                  readonly
                />
                <Field
                  name="startTime"
                  placeholder={this.props.intl.formatMessage(messages.event.startTime)}
                  type="text"
                  component={TimePicker}
                  readonly
                />
                <Field
                  name="city"
                  placeholder={this.props.intl.formatMessage(messages.event.city)}
                  component={Input}
                  type="text"
                  readonly
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <P><FormattedMessage {...messages.confirmText2} /></P>
              <StyledList>
                <li><FormattedMessage {...messages.confirmListElement1} /></li>
                <li><FormattedMessage {...messages.confirmListElement2} /></li>
              </StyledList>
            </Col>
          </Row>
          <ConfirmationModal
            label={this.props.intl.formatMessage(messages.cancelConfirmButton)}
            show={this.state.showModal}
            handleConfirm={this.handleConfirm}
            handleHide={this.toggleModal}
            buttonText={this.props.intl.formatMessage(messages.confirmModalButton)}
            title={this.props.intl.formatMessage(messages.confirmationModalTitle)}
            text={
              <FormattedMessage {...messages.confirmationModalText} values={{ name: this.props.event ? this.props.event.name : "" }} />}
            busy={this.state.confirming}
            theme="pink"
          />
          <Row>
            <Col sm={10}>
              {this.state.eventStatus === "DRAFT" ?
                <ContentWrapper><Button
                  type="onClick"
                  onClick={this.toggleModal}
                  label={this.props.intl.formatMessage(messages.confirmButton)}
                /></ContentWrapper>
                :
                <ContentWrapper><P><FormattedMessage {...messages.eventConfirmedText} /></P></ContentWrapper>
              }
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

ConfirmEvent = reduxForm({
  form: "event",
})(ConfirmEvent);

export default injectIntl(ConfirmEvent);

import { connect } from 'react-redux';
import { logout } from '../../auth/reducer';
import { toggleMobileMenu } from './reducer';
import Menu from './Menu';
import { withRouter } from 'react-router';

const mapStateToProps = state => ({
  auth: state.auth,
  showMobileMenu: state.menu.showMobileMenu,
});

const mapDispatchToProps = dispatch => ({
  logout: data => dispatch(logout()),
  toggleMobileMenu: () => dispatch(toggleMobileMenu()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu));

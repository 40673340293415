import { connect } from "react-redux";
import { submitEvent, reset } from "../reducer";
import EventNewBase from "./EventNewBase";

const mapStateToProps = (state) => {
  const obj = {
    event: state.event.event,
    error: state.event.error,
    status: state.event.status,
    user: state.auth.user,
    newUserStatus: state.user.newUserStatus,
  };

  return obj;
};

const mapDispatchToProps = (dispatch) => ({
  submitEvent: data => dispatch(submitEvent(data)),
  reset: () => dispatch(reset({})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventNewBase);

import React, { Component } from "react";
import { ContentWrapper, Container, H1, P } from "../../../components/index";
import { CircleLinkButton } from "../../../components/Links";
import { Row, Col } from "react-bootstrap/lib";
import styled from "styled-components";
import { breakPoints } from "../../../constants/styling-constants";
import EventList from "./List";
import TopNavigation from "../TopNavigation";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";

const IconWrapper = styled(Col)`
  //display: flex; - Add this when we add search group support
  justify-content: space-around;
  margin-top: 90px;
  margin-bottom: 90px;
  @media (max-width: ${breakPoints.xs}px) {
    flex-direction: column;
    align-items: center;
    a {
      margin-bottom: 25px;
    }
  }
`;

const ListWrapper = styled(Col)`
  @media (max-width: ${breakPoints.xs}px) {
    padding-right: 0;
  }
`;
  

class EventStart extends Component {
  render() {
    //<CircleLinkButton to="/my-pages/search-group"
    // label={this.props.intl.formatMessage(messages.searchVolunteerGroup)} /> - Add this when we add search group
    // support
    return (
      <ContentWrapper>
        <TopNavigation />
        <Container>
          <Row>
            <Col sm={9}>
              <H1><FormattedMessage {...messages.title} /></H1>
              <P><FormattedMessage {...messages.text} /></P>
            </Col>
          </Row>
          <Row>
            <IconWrapper sm={5}>
              <CircleLinkButton
                to="/my-pages/events/new"
                label={this.props.intl.formatMessage(messages.newEventLink)}
              />
            </IconWrapper>
            <IconWrapper sm={5}>
              <CircleLinkButton
                to="/my-pages/events/copy"
                label={this.props.intl.formatMessage(messages.copyEventLink)}
              />
            </IconWrapper>
          </Row>
          <Row>
            <ListWrapper sm={9}>
              <EventList />
            </ListWrapper>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }
}

export default injectIntl(EventStart);

import React, { Component } from "react";
import styled from "styled-components";
import Error from "./Error";
import { SmallPinkButton, SmallGreenButton, MediumGrayButton, DeleteButton } from "../Buttons/index";
import { colors } from "../../constants/styling-constants";
import { TextLabel } from "../index";
import { Row, Col } from "react-bootstrap/lib";
import { BorderTable } from "../Tables/index";
import messages from "./messages";
import { injectIntl } from "react-intl";
import { validateFileArraySize } from "../../validators";

const StyledFileUpload = styled.div`
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  &.has-error {
    margin-bottom: 5px;
    background-color: ${colors.lightPink2}
    &::placeholder {
      color: ${colors.pink};
    }
  }
  &.border {
    border: 1px solid ${colors.lightGray4};
    border-radius: 3px;
  }
`;

const DeleteRowButton = ({ file, callback }) => {
  const onClick = () => {
    callback(file);
  };

  return (
    <DeleteButton onClick={onClick} />
  );
};

const renderConfirmButton = (theme, label) => {
  switch (theme) {
    case "pink":
      return <SmallPinkButton label={label} />;
    case "green":
      return <SmallGreenButton label={label} />;
    default:
      return <MediumGrayButton label={label} />;
  }
};

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileTooBig: false,
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.validateFileSize = validateFileArraySize(this.props.totalFileSize);
  }

  handleFileSelect(e) {
    const newFiles = this.props.files.splice(0);
    const file = e.target.files[0];
    const fileSizeMB = file.size / 1024 / 1024;

    if (fileSizeMB <= this.props.maxFileSize) {
      newFiles.push(e.target.files[0]);
      this.props.changeValue(this.props.input.name, newFiles);
      this.setState({ fileTooBig: false });
    } else {
      this.setState({ fileTooBig: true });
    }
  }

  handleFileRemove(file) {
    const updatedFiles = this.props.files.filter(f => f.name !== file.name && f.size !== file.size);
    this.props.changeValue(this.props.input.name, updatedFiles);
  }

  renderTotalFileSize() {
    if (this.validateFileSize(this.props.files)) {
      return <Error error={this.props.intl.formatMessage(messages.fileUpload.totalFileSizeTooBig, { max: this.props.totalFileSize })} />;
    }

    return null;
  }

  render() {
    const {
      theme,
      input,
      placeholder,
      buttonText,
      readonly = false,
      files,
    } = this.props;

    if (readonly) {
      return (
        <Row>
          <Col md={12}>
            <TextLabel>{placeholder}</TextLabel>
            <BorderTable>
              <tbody>
                {files.map((f, index) => (
                    <tr key={index}>
                      <td>{f.name}</td>
                    </tr>
                  )
                )}
              </tbody>
            </BorderTable>
          </Col>
        </Row>
      );
    }

    return (
      <StyledFileUpload>
        <div className="upload-btn-wrapper">
          {renderConfirmButton(theme, buttonText)}
          <input
            type="file"
            name="myfile"
            onChange={this.handleFileSelect}
          />
        </div>
        {this.state.fileTooBig &&
        <Error error={this.props.intl.formatMessage(messages.fileUpload.fileTooBig, { max: this.props.maxFileSize })} />}
        <BorderTable>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.name}</td>
                <td>{Math.round((file.size / 1024 / 1024) * 100) / 100} Mb</td>
                <td><DeleteRowButton
                  file={file}
                  callback={this.handleFileRemove}
                /></td>
              </tr>
            ))}
          </tbody>
        </BorderTable>
        {this.renderTotalFileSize()}
      </StyledFileUpload>
    );
  }
}

FileUpload.defaultProps = {
  theme: "gray",
  files: [],
  maxFileSize: 20, // In MB,
  totalFileSize: 20,// In MB,
};

export default injectIntl(FileUpload);

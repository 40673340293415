import { call, put } from 'redux-saga/effects';
import { GROUP_OFFER_FETCH, UPDATE_OFFER_ATTENDEES_FAILED, GROUP_OFFERS_FETCH_SUCCESS, GROUP_OFFERS_FETCH_FAILED, GROUP_OFFER_FETCH_SUCCESS, GROUP_OFFER_FETCH_FAILED, ALL_OFFERS_FETCH_FAILED, ALL_OFFERS_FETCH_SUCCESS, EVENT_OFFERS_FETCH_SUCCESS, EVENT_OFFERS_FETCH_FAILED, AVAILABLE_GROUPS_FETCH_SUCCESS, AVAILABLE_GROUPS_FETCH_FAILED, OFFER_CREATE_FAILED, OFFER_CREATE_SUCCESS, GROUPS_OFFERS_FETCH_SUCCESS, GROUPS_OFFERS_FETCH_FAILED, EVENT_OFFERS_FETCH, CONFIRM_STATION_OFFERS_SUCCESS, CONFIRM_STATION_OFFERS_FAILED, ACCEPT_STATION_OFFER_SUCCESS, ACCEPT_STATION_OFFER_FAILED, REJECT_STATION_OFFER_SUCCESS, REJECT_STATION_OFFER_FAILED, DELETE_OFFER_SUCCESS, DELETE_OFFER_FAILED, STATION_OFFERS_FETCH_SUCCESS, STATION_OFFERS_FETCH_FAILED, SEND_OFFER_REMINDER_SUCCESS, SEND_OFFER_REMINDER_FAILED, CREATE_SELF_REGISTRATION_LINK_SUCCESS, CREATE_SELF_REGISTRATION_LINK_FAIL } from './reducer';
import { fetchGroupOffers, fetchGroupOffer, fetchAllOffers, fetchEventOffers, fetchAvailableGroups, createOffer, fetchGroupsOffers, confirmStationOffers, acceptGroupOffer, rejectGroupOffer, deleteOffer, fetchStationOffers, sendOfferReminder, updateOfferAttendees, createLink as getLink } from './service';

export function* fetchGroupOffersSaga (action) {
  try {
    const response = yield call(() => fetchGroupOffers(action.payload));
    yield put({type: GROUP_OFFERS_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUP_OFFERS_FETCH_FAILED, payload: e});
  }
}

export function* fetchGroupOfferSaga (action) {
  try {
    const response = yield call(() => fetchGroupOffer(action.payload));
    yield put({type: GROUP_OFFER_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUP_OFFER_FETCH_FAILED, payload: e});
  }
}

export function* updateOfferAttendeesSaga (action) {
  try {
    const response = yield call(() => updateOfferAttendees(action.payload));
    yield put({type: GROUP_OFFER_FETCH, payload: response});
  } catch (e) {
    yield put({type: UPDATE_OFFER_ATTENDEES_FAILED, payload: e});
  }
}

export function* fetchEventOffersSaga (action) {
  try {
    const response = yield call(() => fetchEventOffers(action.payload));
    yield put({type: EVENT_OFFERS_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: EVENT_OFFERS_FETCH_FAILED, payload: e});
  }
}

export function* fetchStationOffersSaga (action) {
  try {
    const response = yield call(() => fetchStationOffers(action.payload));
    yield put({type: STATION_OFFERS_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: STATION_OFFERS_FETCH_FAILED, payload: e});
  }
}

export function* fetchAllOffersSaga (action) {
  try {
    const response = yield call(() => fetchAllOffers(action.payload));
    yield put({type: ALL_OFFERS_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: ALL_OFFERS_FETCH_FAILED, payload: e});
  }
}

export function* fetchGroupsEventsSaga (action) {
  try {
    const response = yield call(() => fetchGroupsOffers(action.payload));
    yield put({type: GROUPS_OFFERS_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUPS_OFFERS_FETCH_FAILED, payload: e, data: action.payload});
  }
}

export function* fetchAvailableGroupsSaga (action) {
  try {
    const response = yield call(() => fetchAvailableGroups(action.payload));
    yield put({type: AVAILABLE_GROUPS_FETCH_SUCCESS, payload: response, eventId: action.payload.eventId, stationId: action.payload.stationId});
  } catch (e) {
    yield put({type: AVAILABLE_GROUPS_FETCH_FAILED, payload: e});
  }
}

export function* createOfferSaga (action) {
  try {
    const response = yield call(() => createOffer(action.payload));
    yield put({type: OFFER_CREATE_SUCCESS, payload: response, data: action.payload});
    yield put({type: EVENT_OFFERS_FETCH, payload: action.payload});
  } catch (e) {
    yield put({type: OFFER_CREATE_FAILED, payload: e, data: action.payload});
  }
}

export function* confirmStationOffersSaga (action) {
  try {
    const response = yield call(() => confirmStationOffers(action.payload));
    yield put({type: CONFIRM_STATION_OFFERS_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: CONFIRM_STATION_OFFERS_FAILED, payload: e});
  }
}

export function* acceptGroupOfferSaga (action) {
  try {
    const response = yield call(() => acceptGroupOffer(action.payload));
    yield put({type: ACCEPT_STATION_OFFER_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: ACCEPT_STATION_OFFER_FAILED, payload: e});
  }
}

export function* rejectGroupOfferSaga (action) {
  try {
    const response = yield call(() => rejectGroupOffer(action.payload));
    yield put({type: REJECT_STATION_OFFER_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: REJECT_STATION_OFFER_FAILED, payload: e});
  }
}

export function* deleteOfferSaga (action) {
  try {
    const response = yield call(() => deleteOffer(action.payload));
    yield put({type: DELETE_OFFER_SUCCESS, payload: response});
    yield put({type: EVENT_OFFERS_FETCH, payload: action.payload});
  } catch (e) {
    yield put({type: DELETE_OFFER_FAILED, payload: e});
  }
}

export function* sendOfferReminderSaga (action) {
  try {
    const response = yield call(() => sendOfferReminder(action.payload));
    yield put({type: SEND_OFFER_REMINDER_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: SEND_OFFER_REMINDER_FAILED, payload: e});
  }
}

export function* createLink (action) {
  try {
    const response = yield call(() => getLink(action.payload));
    yield put({type: CREATE_SELF_REGISTRATION_LINK_SUCCESS, payload: response});
  } catch (e) {
    yield put({type:  CREATE_SELF_REGISTRATION_LINK_FAIL, payload: e});
  }
}

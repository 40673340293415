import { connect } from "react-redux";
import { fetchPublicEvents, fetchCompleteEvent } from "../reducer";
import GroupAvailability from "./GroupAvailability";
import { saveAvailabilities, fetchAvailabilities } from "./reducer";

const mapStateToProps = (state) => ({
  group: state.group,
  completeEvents: state.group.completeEvents,
  publicEvents: state.group.publicEvents,
  event: state.event,
  publicEventsError: state.group.publicEventsError,
  status: state.group.status,
  availabilityStatus: state.groupAvailabilities.status,
  initialValues: { availabilities: state.groupAvailabilities.availabilities },
});

const mapDispatchToProps = (dispatch) => ({
  submitGroupAvailabilities: data => dispatch(saveAvailabilities(data)),
  fetchGroupAvailabilities: data => dispatch(fetchAvailabilities(data)),
  fetchPublicEvents: data => dispatch(fetchPublicEvents(data)),
  fetchEvent: data => dispatch(fetchCompleteEvent(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAvailability);

import { defineMessages } from 'react-intl';

export default defineMessages({
  event: {
    name: {
      id: 'event.name.title',
    },
    date: {
      id: 'event.date.title',
    },
    startTime: {
      id: 'event.date.startTime',
    },
    city: {
      id: 'event.city.title',
    },
    organizer: {
      title: {
        id: 'event.organizer.title',
      },
      organizerNumber: {
        id: 'event.organizer.organizer-number.title',
      },
      organizerName: {
        id: 'event.organizer.organizer-name.title',
      },
      organizerEmail: {
        id: 'event.organizer.organizer-email.title',
      },
      invoiceAddress: {
        id: 'event.organizer.invoice-address.title',
      },
      organizerWebsite: {
        id: 'event.organizer.organizer-website.title',
      },
    },
    shoeSizeChoices: {
      title: {
        id: 'event.shoe-sizes.title',
      },
      shoeSizes: {
        id: 'event.shoe-sizes.my-choices.title',
      },
    },
    included: {
      title: {
        id: 'event.included.title',
      },
    },
    substitute: {
      title:{
        id: 'event.substitute.title',
      },
      option:{
        id: 'event.substitute.option',
      },
    },
    foodOptions: {
      title: {
        id: 'event.food.title',
      },
      food: {
        id: 'event.food.my-choices.title',
      },
      glutenFree: {
        id: 'edit-event.form.include.food-options.gluten-free.placeholder',
      },
      lactoseFree: {
        id: 'edit-event.form.include.food-options.lactose-free.placeholder',
      },
      milkProteinFree: {
        id: 'edit-event.form.include.food-options.milk-protein-free.placeholder',
      },
      vegan: {
        id: 'edit-event.form.include.food-options.vegan.placeholder',
      },
      vegetarian: {
        id: 'edit-event.form.include.food-options.vegetarian.placeholder',
      },
    },
    clothesChoices: {
      title: {
        id: 'event.clothes.title',
      },
      clothes: {
        id: 'event.clothes.my-choices.title',
      },
    },
    contact: {
      email: {
        id: 'event.contact.form.email.placeholder',
      },
      title: {
        id: 'event.contact-persons.title',
      },
      addContactButton: {
        id: 'event.contact.add-contact-button',
      },
      contactEmptyMessage: {
        id: 'event.contact.contact-field-empty-message',
      },
      contactExistMessage: {
        id: 'event.contact.contact-already-exist-message',
      },
      list: {
        name: {
          id: 'event.contact.list.name',
        },
        email: {
          id: 'event.contact.list.email',
        },
        phone: {
          id: 'event.contact.list.phone',
        },
      },
      requestError: {
        id: 'event.contact.request-error',
      },
      userNotFound: {
        id: 'event.contact.user-not-found',
      },
    },
  },
  confirmButton: {
    id: 'event.confirm-button',
  },
  editButton: {
    id: 'event.edit-button',
  },
  saveButton: {
    id: 'event.save-button',
  },
  confirmModalTitle: {
    id: 'event.confirm-modal.title',
  },
  confirmModalText: {
    id: 'event.confirm-modal.text',
  },
  confirmModalButton: {
    id: 'event.confirm-modal.confirm-button',
  },
  cancelConfirmModalButton: {
    id: 'event.confirm-modal.cancel-button',
  },
  deleteContactModalText: {
    id: 'event.delete-modal.text',
  },
});

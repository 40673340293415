
export const required = (value) => {
  const errorString = 'Du måste fylla i fältet';
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : errorString;
  } 
  return value ? undefined : errorString;
};

export const password = (password) => {
  const errorString = 'Lösenordet uppfyller inte kraven';

  const numberRegEx = /[0-9]/g;
  const upperCase = /^(?=.*[A-Z])/;
  const lowerCase = /^(?=.*[a-z])/;

  return password.match(numberRegEx) && password.match(upperCase) && password.match(lowerCase) && password.length >= 8 ? undefined : errorString;
}

const minNumber = min => value =>
  (value && value < min ? `Värdet måste vara minst ${min}` : undefined);

export const integer = (value) => {
  if(!isInt(value)) {
    return 'Värdet måste vara ett heltal';
  } else {
    return undefined;
  }
};

function isInt(value) {
  /*eslint-disable */
  return !isNaN(value) &&
    parseInt(Number(value), 10) == value &&
    !isNaN(parseInt(value, 10));
  /*eslint-enable */
}

export const positiveNumbers = minNumber(0);
export const zeroOrHigher = minNumber(-1);

const maxLength = max => value =>
  (value && value.length > max ? `Värdet får vara max ${max} tecken långt` : undefined);

export const maxLength250 = maxLength(250);

const minLength = min => value =>
  (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const minLength3 = minLength(3);

export const unique = (definitions, message) => value => (definitions && definitions.find(s => s.name === value) ? `${message}` : undefined);

export const email = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) ? undefined : 'Du måste fylla i en e-postadress';
};

export const onlyNumbers = (value) => {
  const reg = /^[\+]?\d+$/;
  return reg.test(value) ? undefined : 'Telefonumret får endast innehålla siffor'
};

export const atLeastOneArrayElement = label => values => {
  return values && values.length > 0 ? undefined : label;
};

export const organizerNumber = (value) => {
  const reg = /^((\d{6}[-]\d{4})|(\d{10})|(\d{12})|(\d{8}[-]\d{4}))$/;
  return value.match(reg) ? undefined : 'Organisationsnummer uppfyller inte kraven'
};

// Max in MB and file.size in bytes
export const validateFileArraySize = max => (files) => {
  if (files && Array.isArray(files)) {
    const totalFileSize = files.reduce((currentValue, f) => {
      return f ? f.size + currentValue : 0 + currentValue;
    }, 0);

    const totalMb = totalFileSize / 1024 / 1024;

    return totalMb > max ? 'error' : undefined;
  }

  return undefined;
};

export const afterTodaysDate = value => {
  return new Date(value) >= new Date() ? undefined : 'Datumet måste vara i framtiden'
}

export const afterSetDate = value => date => {
  return new Date(value) >= new Date(date) ? undefined : 'Datumet får inte vara innan' + new Date(date)
}

import React, { Component } from "react";
import { ContentWrapper, Container, H1, P } from "../../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import TopNavigation from "../TopNavigation";
import TeamLeaderList from "./List";
import messages from "./messages";
import { FormattedMessage } from "react-intl";

class TeamLeaderStart extends Component {
  render() {
    return (
      <ContentWrapper>
        <TopNavigation />
        <Container>
          <Row>
            <Col sm={9}>
              <H1><FormattedMessage {...messages.title} /></H1>
              <P><FormattedMessage {...messages.text} /></P>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <TeamLeaderList />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }
}

export default TeamLeaderStart;

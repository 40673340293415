import { connect } from "react-redux";
import { confirmRegistration, resendConfirmationCode } from "../../../auth/reducer";
import ConfirmRegistration from "./ConfirmRegistration";

const mapStateToProps = state => ({
  auth: state.auth,

  // figure a better way to expose form values to props
  fieldValues: state.form["confirm-registration"] ? state.form["confirm-registration"].values : {},
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: data => dispatch(confirmRegistration(data)),
  resendConfirmationCode: data => dispatch(resendConfirmationCode(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRegistration);

import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { SmallError } from "./Error";
import { colors } from "../../constants/styling-constants";
import { Row, Col } from "react-bootstrap/lib";
import { TextLabel, P } from "../index";


const TextAreaTemplate = ({ className, input, placeholder, readonly, meta: { touched, error, warning } }) => {

  if (readonly) {
    return (
      <Row>
        <Col md={5}>
          <TextLabel>{placeholder}</TextLabel>
        </Col>
        <Col md={7}>
          <P>{input.value}</P>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <textarea
        className={className}
        placeholder={placeholder}
        {...input}
      />
      {touched &&
      ((error && <SmallError error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

const TextArea = styled(TextAreaTemplate)`
  font-size: 17px;
  padding: 13px 15px;
  background-color: #FFF;
  width: 100%;
  margin-bottom: 15px;
  border: none;
  outline: none;
  height: 100px;
  resize: vertical;
  &::placeholder {
    color: ${colors.lightGray4};
  }
  ${({ meta: { touched, error } }) => touched && error && css`
    margin-bottom: 5px;
    background-color: ${colors.lightPink2}
    &::placeholder {
      color: ${colors.pink};
    }
  `}
`;

const GrayTextArea = TextArea.extend`
  background-color: ${colors.lightGray5};
`;

export {
  TextArea,
  GrayTextArea
};

import { connect } from "react-redux";
import { withRouter } from "react-router";
import MyProfile from "./MyProfile";
import { updateUserInfo } from "../../../auth/reducer";
import { countryCodes } from "../../../constants/country-codes";

/**
 * Phone and country code are stored in a single field so
 * we need to split the phone into two seperate fields
 * that the user can update.
 *
 * @param user
 * @returns {*}
 */
const splitPhoneToCountryCodeAndPhone = (user) => {
  countryCodes.map(cc => {
    const index = user.phone.indexOf(cc);

    if (index !== -1) {
      user.countryCode = cc;
      user.phoneWithoutCountryCode = user.phone.substring(cc.length);
    }

    return cc;
  });
  return user;
};

const mapStateToProps = state => {
  const initialValues = Object.assign({}, { countryCode: "123" }, state.auth.user);

  if (initialValues && initialValues.hasOwnProperty("phone")) {
    initialValues.user = splitPhoneToCountryCodeAndPhone(initialValues);
  }

  return {
    auth: state.auth,
    initialValues: initialValues,
  }
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: data => dispatch(updateUserInfo(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfile));

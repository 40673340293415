import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { breakPoints } from "../../constants/styling-constants";


const Title = styled.div`
  font-size: 20px
  font-weight: 600;
`

const LargeModal = styled(Modal)`
  .modal-dialog {
    width: 60%;
  }
  z-index: 99999;

  @media (max-width: ${breakPoints.sm}px) {
    .modal-dialog {
      width: 100%;
      max-width: 360px;
  }
`;

const PopUpModal = ({
  show,
  handleHide,
  title,
  titleSize,
  backdrop,
  handleConfirm,
  handleNext,
  handleCancel,
  children,
  bgColor = "#ffffff"
}) => (
  <LargeModal show={show} onHide={handleHide} backdrop={backdrop}>
    <Modal.Header style={{ backgroundColor: bgColor }} closeButton>
      <Title style={{fontSize: titleSize}}>{title}</Title>
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: bgColor }}>{children}</Modal.Body>
  </LargeModal>
);

export default PopUpModal;

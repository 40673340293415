import React, { Component, Fragment } from "react";
import {FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import { BigGreenButton, BigGrayButton } from "../../../../components/Buttons";
import VolunteerForm from "../Volunteers/VolunteerForm";
import { GeneralError, PopUpModal } from "../../../../components";

class GroupAttendeesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      createNewVoluntee: false,
      volunteerToEdit: {},
      editVolunteer: false,
      specialInfoPopUp: false,
    };
    this.selectRow = this.selectRow.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.goBackToList = this.goBackToList.bind(this);
    this.createNewVoluntee = this.createNewVoluntee.bind(this);
    this.submitNewAttendees = this.submitNewAttendees.bind(this);
    this.specialInfoPopUp = this.specialInfoPopUp.bind(this);
  }

  submitNewAttendees() {
    this.setState({ error: false });
    const { offer, update, updateOffers } = this.props;
    const { attendees = [] } = offer;
    const newAttendees = this.state["selected"];

    if (!newAttendees.length > 0) {
      this.setState({ error: true });
      return;
    }

    const attendeesMissingSpecialInfo = [];
    /*if(this.props.offer.foodOptions && this.props.offer.shirtSizes){
      newAttendees.map((attendee) => attendee.participation ? 
      attendee.participation.some((event) => event.eventId === this.props.offer.eventId && event.specialDiets && event.shirtSize ? 
      true : false)? attendees.push(attendee.id) : attendeesMissingSpecialInfo.push(attendee.id):attendeesMissingSpecialInfo.push(attendee.id))
    }*/
    /*else if(this.props.offer.foodOptions){
      newAttendees.map((attendee) => attendee.participation ? 
      attendee.participation.some((event) => event.eventId === this.props.offer.eventId && event.specialDiets ? 
      true : false)? attendees.push(attendee.id) : attendeesMissingSpecialInfo.push(attendee.id):attendeesMissingSpecialInfo.push(attendee.id))
    }*/
    if(this.props.offer.shirtSizes){
      newAttendees.map((attendee) => attendee.participation ? 
      attendee.participation.some((event) => event.eventId === this.props.offer.eventId && event.shirtSize ? 
      true : false)? attendees.push(attendee.id) : attendeesMissingSpecialInfo.push(attendee.id):attendeesMissingSpecialInfo.push(attendee.id))
    }
    else{
      newAttendees.map((att) => attendees.push(att.id))
    }
    
    if(attendees.length > 0){
      update({
        eventId: offer.eventId,
        groupId: offer.groupId,
        stationId: offer.stationId,
        attendees: attendees,
      });
      updateOffers({
        eventId: offer.eventId,
        groupId: offer.groupId,
      });
    }
    this.setState({ selected: [] });

    if(attendeesMissingSpecialInfo.length > 0){
      this.setState({ specialInfoPopUp: true });
    }
    else{
      this.props.handleHide();
    }
    
  }

  selectRow(row) {
    var selected = this.state["selected"];
    if (
      !selected.find(function (el) {
        return el.id === row.id;
      })
    ) {
      selected.push(row);
    } else {
      _.remove(selected, function (el) {
        return el.id === row.id;
      });
    }
    this.setState(selected);
  }

  selectAll(isSelect, rows) {
    var selected = [];
    if (isSelect) {
      rows.forEach((row) => selected.push(row));
    }
    this.setState({ selected: selected });
  }

  getColumns(intl) {
    const columns = [
      { dataField: "id", text: "id", hidden: true },
      {
        dataField: "name",
        text: intl.formatMessage(messages.attendees.name),
        sort: true,
      },
      {
        dataField: "email",
        text: intl.formatMessage(messages.attendees.email),
        sort: true,
      },
      {
        dataField: "age",
        text: intl.formatMessage(messages.attendees.age),
        sort: true,
      },
      ...(this.props.offer && this.props.offer.foodOptions
        ? [
            {
              dataField: "specialDiets",
              text: intl.formatMessage(messages.attendees.specialDiets.title),
              sort: true,
            },
          ]
        : []),
      ...(this.props.offer && this.props.offer.shirtSizes
        ? [
            {
              dataField: "shirtSize",
              text: intl.formatMessage(messages.attendees.shirtSize),
              sort: true,
            },
          ]
        : []),
      ...(this.props.offer && this.props.offer.substitute === "active"
        ? [
            {
              dataField: "substitute",
              text: intl.formatMessage(messages.attendees.substitute),
              sort: true,
            },
          ]
        : []),
    ];
    return columns;
  }

  goBackToList() {
    this.setState({ createNewVoluntee: false });
    this.setState({ volunteerToEdit: null });
    this.setState({ editVolunteer: false });
  }

  handleClose() {
    if(this.state.createNewVoluntee || this.state.editVolunteer){
      this.goBackToList();
    }
    else{
      this.props.handleHide();
    }
  }

  getSpecialDiets(group, intl) {
    if (group && group.specialDiets && group.specialDiets.length > 0) {
      return group.specialDiets.map((specialDiet, index) => {
        return index === 0
          ? intl.formatMessage({ id: `food.${specialDiet.toLowerCase()}` })
          : ", " +
              intl.formatMessage({ id: `food.${specialDiet.toLowerCase()}` });
      });
    }
  }

  createNewVoluntee() {
    this.setState({ createNewVoluntee: true });
  }

  specialInfoPopUp(){
    this.setState({ specialInfoPopUp: false });
    this.props.handleHide();
  }


  render() {
    const onRowClick = {
      onClick: (e, row, rowIndex) => {
        this.setState({ volunteerToEdit: row });
        this.setState({ editVolunteer: true });
        this.setState({ createNewVoluntee: true });
      },
    };

    const { intl, group, offer, offers } = this.props;
    const nonNullOfferAttendees =
      offer && offer.attendees && offer.attendees.length > 0
        ? offer.attendees
        : [];

    const availableAttendee =
      group && group.volunteers && group.volunteers.length > 0
        ? group.volunteers.filter(
            (volunteer) =>
              !nonNullOfferAttendees.find(
                (attendee) => attendee === volunteer.id
              )
          )
        : [];
    
    const noOtherStations =
      offers && group && group.volunteers && group.volunteers.length > 0
        ? availableAttendee.filter(
          (vol) => !offers.some(
            (offer) => offer.attendees ? offer.attendees.includes(vol.id) : false
          ))
        : [];
      
    const formattedAttendee = noOtherStations.map((attendee) => {
      const { substitute, specialDiets, shirtSize, ...rest } = attendee;
      const diet = attendee.participation
        ? attendee.participation.map((event) =>
            event.eventId === this.props.offer.eventId && event.specialDiets
              ? this.getSpecialDiets(event, intl)
              : null
          )
        : null;
        
      const shirt = attendee.participation
        ? attendee.participation.map((event) =>
            event.eventId === this.props.offer.eventId && event.shirtSize
              ? event.shirtSize
              : null
          )
        : null;

      const sub = attendee.participation
      ? attendee.participation.map((event) =>
          event.eventId === this.props.offer.eventId && event.substitute
            ? event.substitute
            : null
        )
      : null;
      
      return { substitute: sub, specialDiets: diet, shirtSize: shirt, ...rest };
    });
    return (
      
      <PopUpModal
        title={
          this.state.createNewVoluntee
            ? intl.formatMessage(messages.modal.newVolunteersCreate)
            : intl.formatMessage(messages.modal.newVolunteers)
        }
        id="addVolunteerPopup"
        bgColor="#e2f0f1"
        show={this.props.showPopup}
        handleHide={() => this.handleClose()}
      >
        <PopUpModal 
          title={<FormattedMessage {...messages.modal.missingSpecialInfo}/>}
          id="missingSpecialInfoPopUp"
          bgColor="#e2f0f1"
          show={this.state.specialInfoPopUp}
          handleHide={this.specialInfoPopUp}
        >
          <BigGrayButton 
            onClick={this.specialInfoPopUp}
            label={<FormattedMessage {...messages.attendees.closeButton}/>}/>
        </PopUpModal>
        <Fragment>
          {this.state.createNewVoluntee && (
            <VolunteerForm
              group={group}
              offer={offer}
              goBackToList={this.goBackToList}
              volunteerToEdit={this.state.editVolunteer ? this.state.volunteerToEdit : null}
              saveGroup={(volunteersList) => {
                const groupToSubmit = this.props.group;
                groupToSubmit.volunteers = volunteersList;
                this.props.saveGroup(groupToSubmit);
                this.goBackToList()
              }}
            />
          )}
          {!this.state.createNewVoluntee && (
            <Fragment>
              <BootstrapTable
                selectRow={{
                  mode: "checkbox",
                  onSelect: this.selectRow,
                  onSelectAll: this.selectAll,
                }}
                keyField="id"
                columns={this.getColumns(intl)}
                data={formattedAttendee}
                classes="table-test"
                wrapperClasses="responsive-table"
                rowEvents={onRowClick}
                hover
                rowStyle={ {cursor: 'pointer' }}
              />
              {this.state.error && (
                <GeneralError>
                  {intl.formatMessage(messages.attendees.notEnoughAttendee)}
                </GeneralError>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BigGreenButton
                  label={intl.formatMessage(messages.attendees.submitButton)}
                  onClick={this.submitNewAttendees}
                />
                <BigGreenButton
                  label={intl.formatMessage(messages.attendees.createButton)}
                  onClick={this.createNewVoluntee}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      </PopUpModal>
    );
  }
}

export default injectIntl(GroupAttendeesForm);

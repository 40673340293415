import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';
import { Row, Col } from 'react-bootstrap/lib';
import { Container, FlexSpaceBetween, GrayLogo } from '../';
import { NavLink } from 'react-router-dom';
import { breakPoints } from '../../constants/styling-constants';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';

const Wrapper = styled.footer`
  background-color: ${colors.gray};
  padding: 40px 0 30px;
  flex-shrink: 0;
`;

const MenuList = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  display: inline-block;
  margin-right: 45px;
  @media (max-width: ${breakPoints.md}px) {
    margin-right: 25px;
  }
  @media (max-width: ${breakPoints.sm}px) {
    display: block;
    margin-top: 10px;
  }
`;

const StyledLink = styled(NavLink)`
  font-size: 16px;
  color: ${colors.lightGray};
  text-transform: uppercase;
  &.active {
    color: ${colors.lightGray}
  }
  &:hover {
    text-decoration: none;
    color: ${colors.lightGray};
  }
`;

const CopyRight = styled.p`
  color: ${colors.mediumLightGray};
  font-size: 13px;
  margin-top: 40px;
`;

const SocialIcon = styled.i`
  font-size: 1.5em;
  color: white;
`;

const IconLink = styled.a`
  &:not(:last-child) {
    margin-right: 35px;
  }
  &:last-child {
    margin-right: 12px;
  }
`;

const FloatedDiv = styled.div`
  @media (min-width: ${breakPoints.sm}px) {
    float: right;
  }
  margin-top: 30px;
`;

const FooterMenu = () => (
  <nav>
    <MenuList>
      <MenuItem>
        <StyledLink to="/faq"><FormattedMessage {...messages.link.faq}/></StyledLink>
      </MenuItem>
      <MenuItem>
        <StyledLink to="/terms"><FormattedMessage {...messages.link.terms}/></StyledLink>
      </MenuItem>
      <MenuItem>
        <StyledLink to="/about"><FormattedMessage {...messages.link.about} /></StyledLink>
      </MenuItem>
      <MenuItem>
        <StyledLink to="/privacy-policy"><FormattedMessage {...messages.link.personalData} /></StyledLink>
      </MenuItem>
      <MenuItem>
        <StyledLink to="/contact"><FormattedMessage {...messages.link.contact} /></StyledLink>
      </MenuItem>
    </MenuList>
  </nav>
);

const Footer = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col sm={12}>
          <FlexSpaceBetween>
            <FooterMenu />
            <GrayLogo />
          </FlexSpaceBetween>
        </Col>
        <Col sm={12}>

        </Col>
        <Col sm={12}>
          <Row>
            <Col xs={12} sm={6}>
              <CopyRight><FormattedMessage {...messages.copyRight} /></CopyRight>
            </Col>
            <Col xs={12} sm={6}>
              <FloatedDiv>
                <IconLink href="https://www.instagram.com/Volexsweden/" target="_blank"><SocialIcon className="fab fa-instagram"></SocialIcon></IconLink>
                <IconLink href="https://www.facebook.com/Volex-736969303334432/" target="_blank"><SocialIcon className="fab fa-facebook-f"></SocialIcon></IconLink>
                <IconLink href="https://www.linkedin.com/company/volexsweden/" target="_blank"><SocialIcon className="fab fa-linkedin-in"></SocialIcon></IconLink>
              </FloatedDiv>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default injectIntl(Footer);

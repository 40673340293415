import React from 'react';
import { P } from '../index';
import { Button } from '../Buttons';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from './messages';

const Wrapper = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	background: white;
	z-index: 99999999999999999;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const RowWrapper =  styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const TestWall = (props) => {
	const handleSubmit = (value) => {
		if (value == "test123") {
			localStorage.setItem("testAcknowledged", true);
			props.update(true);
		}
	}

	return (
		<Wrapper>
			<RowWrapper>
				<P><FormattedMessage {...messages.warning} /></P>
				<input id="pass" type='text' placeholder={props.intl.formatMessage(messages.placeholder)}/>
				<Button
					onClick={() => handleSubmit(document.getElementById("pass").value)}
					label={props.intl.formatMessage(messages.button)
				}/>
			</RowWrapper>
		</Wrapper>
	)
}

export default injectIntl(TestWall);
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'register-volunteers.title',
  },
  text: {
    id: 'register-volunteers.text',
  },
  volunteers: {
    personalInfoTitle: {
      id: 'register-group.volunteers.form.personal-info-title',
    },
    otherInfoTitle: {
      id: 'register-group.volunteers.form.other-info-title',
    },
    form: {
      goBack: {
        id: 'group.volunteer.form.goBack'
      },
      name: {
        id: 'group.volunteer.form.name.placeholder',
      },
      email: {
        id: 'group.volunteer.form.email.placeholder',
      },
      phone: {
        id: 'group.volunteer.form.phone.placeholder',
      },
      age: {
        id: 'group.volunteer.form.age.placeholder',
      },
      substitute:{
        id: 'group.volunteer.form.substitute.placeholder',
      },
      substituteTitle: {
        id: 'group.volunteer.form.substitute.title',
      },
      substituteOption: {
        id: 'group.volunteer.form.substitute.option',
      },
      foodOptionsTitle: {
        id: 'group.volunteer.form.food-options-title',
      },
      shirtSizesTitle: {
        id: 'group.volunteer.form.shirt-sizes-title',
      },
      specialDiets: {
        lactoseFree: {
          id: 'group.volunteer.form.special-diets.lactose-free.placeholder',
        },
        glutenFree: {
          id: 'group.volunteer.form.special-diets.gluten-free.placeholder',
        },
        vegetarian: {
          id: 'group.volunteer.form.special-diets.vegetarian.placeholder',
        },
        vegan: {
          id: 'group.volunteer.form.special-diets.vegan.placeholder',
        },
      },
      shirtSize: {
        id: 'group.volunteer.form.shirt-size.placeholder',
      },
      addButton: {
        id: 'group.volunteer.form.add-button',
      },
      emailAlreadyExistError: {
        id: 'group.volunteer.form.email-already-exist.error',
      },
    },
    list: {
      name: {
        id: 'group.volunteer.list.name.placeholder',
      },
      email: {
        id: 'group.volunteer.list.email.placeholder',
      },
      phone: {
        id: 'group.volunteer.list.phone.placeholder',
      },
      age: {
        id: 'group.volunteer.list.age.placeholder',
      },
      specialDiets: {
        id: 'group.volunteer.list.special-diets.title',
      },
      shirtSize: {
        id: 'group.volunteer.list.shirt-size.placeholder',
      },
    },
    cancelDeleteButton: {
      id: 'group.volunteer.list.cancel-delete-volunteer-button',
    },
    deleteVolunteerTitle: {
      id: 'group.volunteer.list.delete-volunteer-title',
    },
    deleteVolunteerMessage: {
      id: 'group.volunteer.list.delete-volunteer-text',
    },
    noVolunteersMessage: {
      id: 'register-group.volunteers.no-volunteers-yet-message',
    },
    nextButton: {
      id: 'edit-group.volunteers.next-button',
    },
  },
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  multiSelect: {
    messages: {
      emptyList: {
        id: 'forms.select.empty-list',
      },
      emptyFilter: {
        id: 'forms.select.empty-filter',
      },
    },
  },
  select: {
    messages: {
      emptyList: {
        id: 'forms.select.empty-list',
      },
      emptyFilter: {
        id: 'forms.select.empty-filter',
      },
    },
  },
  fileUpload: {
    fileTooBig: {
      id: 'forms.file-upload.file-to-big',
    },
    totalFileSizeTooBig: {
      id: 'forms.file-upload.total-file-size-too-big',
    },
  },
});

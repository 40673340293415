import { sendForgotPasswordCode, resetPassword } from "./service";
import { call, put } from "redux-saga/effects";
import {
  FORGOT_PASSWORD_CODE_FAILED,
  FORGOT_PASSWORD_CODE_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  FORGOT_PASSWORD_CODE_RESET_SUCCESS
} from "./reducer";

export function* forgotPasswordSaga(action) {
  try {
    const response = yield call(() => sendForgotPasswordCode(action.payload));
    yield put({ type: FORGOT_PASSWORD_CODE_SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_CODE_FAILED, payload: e });
  }
}

export function* forgotPasswordResetSaga(action) {
  try {
    yield put({ type: FORGOT_PASSWORD_CODE_RESET_SUCCESS, payload: null });
  } catch (e) {
    yield put({ type: FORGOT_PASSWORD_CODE_RESET_SUCCESS, payload: null });
  }
}

export function* resetPasswordSaga(action) {
  try {
    const response = yield call(() => resetPassword(action.payload));
    yield put({ type: RESET_PASSWORD_SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAILED, payload: e });
  }
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  form: {
    email: {
      id: 'login.form.email.placeholder',
    },
    password: {
      id: 'login.form.password.placeholder',
    },
  },
  loginButton: {
    id: 'login.login-button',
  },
  registerButton: {
    id: 'login.register-button',
  },
  forgotPassword: {
    id: 'login.forgot-password.link',
  },
  error: {
    UserNotFoundException: {
      id: 'login.error.user-not-found',
    },
    ResourceNotFoundException: {
      id: 'login.error.cognito-service-not-found',
    },
    UserNotConfirmedException: {
      id: 'login.error.user-not-confirmed',
    },
    default: {
      id: 'login.error.unknown-error',
    },
    NotAuthorizedException: {
      id: 'login.error.wrong-password',
    },
  },
  resetPasswordInfo: {
    id: 'login.password-reset-info',
  }
});

import { defineMessages } from 'react-intl';

export default defineMessages({
  progressBar: {
    registerGroup: {
      id: 'new-group.progressbar.register-group.placeholder',
    },
    registerVolunteers: {
      id: 'new-group.progressbar.register-volunteers.placeholder',
    },
    registerAvailability: {
      id: 'new-group.progressbar.register-availability.placeholder',
    },
  },
  groupForm: {
    title: {
      id: 'new-group.group-form.title',
    },
    text: {
      id: 'new-group.group-form.text',
    },
  },
  availability: {
    title: {
      id: 'new-group.availability.title',
    },
    text: {
      id: 'new-group.availability.text',
    },
  },
  volunteers: {
    title: {
      id: 'new-group.volunteers.title',
    },
    text: {
      id: 'new-group.volunteers.text',
    },
  },
  confirmation: {
    title: {
      id: 'new-group.confirmation.title',
    },
    text: {
      id: 'new-group.confirmation.text',
    },
  },
  nextButton: {
    id: 'next-step-button',
  },
});

import { connect } from "react-redux";
import { fetchEventOffers } from "../../Offer/reducer";
import AttendanceList from "./AttendanceList";
import { withRouter } from "react-router";

const mapStateToProps = (state, ownProps) => ({
  event: ownProps.event,
  eventOffers: state.offers.eventOffers,
  offerStatus: state.offers.status,
  offerError: state.offers.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventOffers: data => dispatch(fetchEventOffers(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceList));

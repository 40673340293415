import React, { Component } from "react";
import {
  H2,
  BorderTable,
  ConfirmationModal
} from "../../../../components/index";
import moment from "moment";
import { Row, Col } from "react-bootstrap/lib";
import { DeleteButton } from "../../../../components/Buttons/index";
import styled from "styled-components";
import { colors } from "../../../../constants/styling-constants";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { eventStatus } from "../reducer";
import { municipalities } from "../../../../constants/municipalities";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

const Status = styled.div`
  padding: 8px;
  background-color: ${colors.gray};
  width: 100%;
  color: #FFF;
  text-align: center;
`;

const StatusUnmanned = Status.extend`
  background-color: ${colors.pink};
`;

const Button = ({ row, callback }) => {
  const onClick = () => {
    callback(row);
  };

  return (
    <DeleteButton onClick={onClick} />
  );
};

class StationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      stationToDelete: null,
      deleting: false,
      stationsList: this.props.stationsList,
    };

    this.renderRow = this.renderRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === eventStatus.SUCCESS && this.props.status === eventStatus.SUBMITTING && this.state.deleting) {
      this.setState({
        showModal: false,
        stationToDelete: null,
        deleting: false,
      });
    }
  }

  onDelete(row) {
    this.setState({
      showModal: true,
      stationToDelete: row,
    });
  }

  onConfirmDelete() {
    this.setState({
      deleting: true,
    });

    const updatedList = this.props.stationsList.slice();
    const indexToRemove = updatedList.indexOf(this.state.stationToDelete);
    updatedList.splice(indexToRemove, 1);
    this.props.onDeleteStation(updatedList);
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  renderStatus(status) {
    switch (status) {
      case "MANNED":
        return <Status><FormattedMessage {...messages.status.manned} /></Status>;
      default:
        return <StatusUnmanned><FormattedMessage {...messages.status.unmanned} /></StatusUnmanned>;
    }
  }

  renderRow(row, key) {
    return (
      <tr key={key}>
        <td>{row.name}</td>
        <td>{moment(row.start).format("HH:mm")}</td>
        <td>{moment(row.end).format("HH:mm")}</td>
        <td>{(municipalities.find(municipality => municipality.id === row.municipality) || {}).name}</td>
        <td>{row.officialQuantity}</td>
        <td>{row.officialMiniumumAge}</td>
        <td>{this.props.intl.formatMessage({ id: `station.payment-type.${row.paymentType}` })}</td>
        <td>{row.currency}</td>
        <td>{row.groupPayment}</td>
        <td>{row.paymentAdult}</td>
        <td>{row.paymentChild}</td>
        <td>{row.skills ? row.skills.map(skill => this.props.intl.formatMessage({ id: `lists.skills.${skill}` })).join(", ") : null}</td>
        {(!this.props.hideStatus && <td>{this.renderStatus("UNMANNED")}</td>)}
        {this.props.onDeleteStation ? <td className="no-background"><Button
          row={row}
          callback={this.onDelete}
        /></td>: null}
      </tr>
    );
  }

  render() {
    const title = this.props.title ? this.props.title : messages.myStation.title
    return (
      <Wrapper>
        <Row>
          <Col sm={12}>
            <H2><FormattedMessage {...title} /></H2>
            {
              this.props.stationsList.length > 0
                ? <BorderTable>
                  <thead>
                    <tr>
                      <th><FormattedMessage {...messages.myStation.list.id} /></th>
                      <th><FormattedMessage {...messages.myStation.list.startTime} /></th>
                      <th><FormattedMessage {...messages.myStation.list.endTime} /></th>
                      <th><FormattedMessage {...messages.myStation.list.municipality} /></th>
                      <th><FormattedMessage {...messages.myStation.list.amount} /></th>
                      <th><FormattedMessage {...messages.myStation.list.minimumAge} /></th>
                      <th><FormattedMessage {...messages.myStation.list.type} /></th>
                      <th><FormattedMessage {...messages.myStation.list.currency} /></th>
                      <th><FormattedMessage {...messages.myStation.list.paymentGroup} /></th>
                      <th><FormattedMessage {...messages.myStation.list.paymentAdult} /></th>
                      <th><FormattedMessage {...messages.myStation.list.paymentChild} /></th>
                      <th><FormattedMessage {...messages.myStation.list.skills} /></th>
                      {(!this.props.hideStatus && <th><FormattedMessage {...messages.myStation.list.status} /></th>)}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.stationsList.map(this.renderRow)}
                  </tbody>
                </BorderTable>
                : <p><FormattedMessage {...messages.noStationsMessage} /></p>
            }
          </Col>
        </Row>
        <ConfirmationModal
          label={this.props.intl.formatMessage(messages.cancelDeleteButton)}
          show={this.state.showModal}
          handleConfirm={this.onConfirmDelete}
          handleHide={this.toggleModal}
          text={this.props.intl.formatMessage(messages.deleteMessage)}
          busy={this.state.deleting}
          theme="pink"
        />
      </Wrapper>
    );
  }
}

export default injectIntl(StationsList);

export const GROUP_OFFERS_FETCH = "GROUP_OFFERS_FETCH";
export const GROUP_OFFERS_FETCH_SUCCESS = "GROUP_OFFERS_FETCH_SUCCESS";
export const GROUP_OFFERS_FETCH_FAILED = "GROUP_OFFERS_FETCH_FAILED";
export const EVENT_OFFERS_FETCH = "EVENT_OFFERS_FETCH";
export const EVENT_OFFERS_FETCH_SUCCESS = "EVENT_OFFERS_FETCH_SUCCESS";
export const EVENT_OFFERS_FETCH_FAILED = "EVENT_OFFERS_FETCH_FAILED";
export const STATION_OFFERS_FETCH = "STATION_OFFERS_FETCH";
export const STATION_OFFERS_FETCH_SUCCESS = "STATION_OFFERS_FETCH_SUCCESS";
export const STATION_OFFERS_FETCH_FAILED = "STATION_OFFERS_FETCH_FAILED";
export const STATION_OFFER_UPDATE = "STATION_OFFER_UPDATE";
export const ALL_OFFERS_FETCH = "ALL_OFFERS_FETCH";
export const ALL_OFFERS_FETCH_SUCCESS = "ALL_OFFERS_FETCH_SUCCESS";
export const ALL_OFFERS_FETCH_FAILED = "ALL_OFFERS_FETCH_FAILED";
export const AVAILABLE_GROUPS_FETCH = "AVAILABLE_GROUPS_FETCH";
export const AVAILABLE_GROUPS_FETCH_SUCCESS = "AVAILABLE_GROUPS_FETCH_SUCCESS";
export const AVAILABLE_GROUPS_FETCH_FAILED = "AVAILABLE_GROUPS_FETCH_FAILED";
export const AVAILABLE_GROUPS_RESET = "AVAILABLE_GROUPS_RESET";
export const DELETE_OFFER = "DELETE_OFFER";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILED = "DELETE_OFFER_FAILED";
export const OFFER_CREATE = "OFFER_CREATE";
export const OFFER_CREATE_SUCCESS = "OFFER_CREATE_SUCCESS";
export const OFFER_CREATE_FAILED = "OFFER_CREATE_FAILED";
export const GROUPS_OFFERS_FETCH = "GROUPS_OFFERS_FETCH";
export const GROUPS_OFFERS_FETCH_SUCCESS = "GROUPS_OFFERS_FETCH_SUCCESS";
export const GROUPS_OFFERS_FETCH_FAILED = "GROUPS_OFFERS_FETCH_FAILED";
export const CONFIRM_STATION_OFFERS = "CONFIRM_STATION_OFFERS";
export const CONFIRM_STATION_OFFERS_SUCCESS = "CONFIRM_STATION_OFFERS_SUCCESS";
export const CONFIRM_STATION_OFFERS_FAILED = "CONFIRM_STATION_OFFERS_FAILED";
export const ACCEPT_STATION_OFFER = "ACCEPT_STATION_OFFER";
export const ACCEPT_STATION_OFFER_SUCCESS = "ACCEPT_STATION_OFFER_SUCCESS";
export const ACCEPT_STATION_OFFER_FAILED = "ACCEPT_STATION_OFFER_FAILED";
export const REJECT_STATION_OFFER = "REJECT_STATION_OFFER";
export const REJECT_STATION_OFFER_SUCCESS = "REJECT_STATION_OFFER_SUCCESS";
export const REJECT_STATION_OFFER_FAILED = "REJECT_STATION_OFFER_FAILED";
export const GROUP_OFFER_FETCH = "GROUP_OFFER_FETCH";
export const GROUP_OFFER_FETCH_SUCCESS = "GROUP_OFFER_FETCH_SUCCESS";
export const GROUP_OFFER_FETCH_FAILED = "GROUP_OFFER_FETCH_FAILED";
export const RESET_GROUP_OFFER_ERROR = "RESET_GROUP_OFFER_ERROR";
export const SEND_OFFER_REMINDER = "SEND_OFFER_REMINDER";
export const SEND_OFFER_REMINDER_SUCCESS = "SEND_OFFER_REMINDER_SUCCESS";
export const SEND_OFFER_REMINDER_FAILED = "SEND_OFFER_REMINDER_FAILED";
export const SEND_OFFER_INVITATION = "SEND_OFFER_INVITATION";
export const SEND_OFFER_INVITATION_SUCCESS = "SEND_OFFER_INVITATION_SUCCESS";
export const SEND_OFFER_INVITATION_FAILED = "SEND_OFFER_INVITATION_FAILED";
export const UPDATE_OFFER_ATTENDEES = "UPDATE_OFFER_ATTENDEES";
export const UPDATE_OFFER_ATTENDEES_FAILED = "UPDATE_OFFER_ATTENDEES_FAILED";
export const CREATE_SELF_REGISTRATION_LINK = "CREATE_SELF_REGISTRATION_LINK";
export const CREATE_SELF_REGISTRATION_LINK_FAIL = "CREATE_SELF_REGISTRATION_LINK_FAIL";
export const CREATE_SELF_REGISTRATION_LINK_SUCCESS = "CREATE_SELF_REGISTRATION_LINK_SUCCESS";

export function fetchGroupOffers(data) {
  return {
    type: GROUP_OFFERS_FETCH,
    payload: data,
  };
}

export function fetchGroupOffer(data) {
  return {
    type: GROUP_OFFER_FETCH,
    payload: data,
  }
}

export function fetchAllOffers(data) {
  return {
    type: ALL_OFFERS_FETCH,
    payload: data,
  };
}

export function updateOfferAttendees(data) {
  return {
    type: UPDATE_OFFER_ATTENDEES,
    payload: data,
  }
}

export function fetchGroupsOffers(data) {
  return {
    type: GROUPS_OFFERS_FETCH,
    payload: data,
  };
}

export function fetchEventOffers(data) {
  return {
    type: EVENT_OFFERS_FETCH,
    payload: data,
  };
}

export function fetchStationOffers(data) {
  return {
    type: STATION_OFFERS_FETCH,
    payload: data,
  }
}

export function fetchAvailableGroups(data) {
  return {
    type: AVAILABLE_GROUPS_FETCH,
    payload: data,
  };
}

export function resetAvailableGroups() {
  return {
    type: AVAILABLE_GROUPS_RESET
  }
}

export function createOffer(data) {
  return {
    type: OFFER_CREATE,
    payload: data,
  };
}

export function deleteOffer(data) {
  return {
    type: DELETE_OFFER,
    payload: data,
  };
}

export function confirmStationOffers(data) {
    return {
    type: CONFIRM_STATION_OFFERS,
    payload: data,
  };
}

export function acceptStationOffer(data) {
  return {
    type: ACCEPT_STATION_OFFER,
    payload: data
  };
}

export function rejectStationOffer(data) {
  return {
    type: REJECT_STATION_OFFER,
    payload: data
  };
}

export function resetGroupOfferError() {
  return {
    type: RESET_GROUP_OFFER_ERROR,
  };
}

export function sendOfferReminder(data) {
  return {
    type: SEND_OFFER_REMINDER,
    payload: data
  };
}

export function sendOfferInvitation(data) {
  return {
    type: SEND_OFFER_INVITATION,
    payload: data
  };
}

export function createLink(data) {
  return {
    type: CREATE_SELF_REGISTRATION_LINK,
    payload: data
  };
}

const statuses = {
  INIT: "INIT",
  FETCHING: "FETCHING",
  FETCHED: "FETCHED",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_FAILED: "SUBMIT_FAILED",
};

const initialState = {
  status: statuses.INIT,
  groupOffer: null,
  groupOfferError: null,
  groupOfferAcceptError: null,
  groupOfferRejectError: null,
  groupOffers: null,
  eventOffers: {},
  stationOffers: null,
  stationOfferError: null,
  availableGroups: {},
  deleteOfferError: null,
  offerCreateStatus: {},
  sendOfferReminderStatus: statuses.INIT,
  sendOfferInvitationStatus: statuses.INIT,
  sendOfferReminderGroups: []
};

export default function offers(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GROUP_OFFERS_FETCH:
      return Object.assign({}, state, { status: statuses.FETCHING });
    case GROUP_OFFERS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        status: statuses.FETCHED,
        groupOffers: action.payload
      });
    case GROUP_OFFERS_FETCH_FAILED:
      return Object.assign({}, state, { error: action.payload });
    case ALL_OFFERS_FETCH:
      return Object.assign({}, state, { status: statuses.FETCHING });
    case ALL_OFFERS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        status: statuses.FETCHED,
        groupOffers: action.payload.groupOffers,
        eventOffers: action.payload.eventOffers
      });
    case ALL_OFFERS_FETCH_FAILED:
      return Object.assign({}, state, { error: action.payload });
    case EVENT_OFFERS_FETCH:
      return state;
    case EVENT_OFFERS_FETCH_SUCCESS:
      return Object.assign({}, state, { status: statuses.FETCHED }, {
        eventOffers: action.payload.reduce((events, offer) => {
          if (Array.isArray(events[offer.eventId])) {
            events[offer.eventId].push(offer);
          } else {
            events[offer.eventId] = [offer];
          }
          return events;
        }, {}),
      });
    case EVENT_OFFERS_FETCH_FAILED:
      return Object.assign({}, state, { error: action.payload });
    case AVAILABLE_GROUPS_FETCH_SUCCESS:
      return Object.assign({}, state, { availableGroups: Object.assign({}, state.availableGroups, { [`${action.eventId}_${action.stationId}`]: action.payload }) });
    case AVAILABLE_GROUPS_FETCH_FAILED:
      return state;
    case AVAILABLE_GROUPS_RESET:
      return Object.assign({}, state, { availableGroups: {} });
    case GROUPS_OFFERS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        status: statuses.FETCHED,
        groupOffers: action.payload
      });
    case CONFIRM_STATION_OFFERS_SUCCESS:
      const data = action.payload;
      // We need to do a immutable copy of eventOffers so that redux recognizes it and upd      ate
      var changed = false
      const eventOffers = JSON.parse(JSON.stringify(state.eventOffers));
      for (const d in data) {
	  const dd = data[d]
	  const offer = eventOffers[dd.eventId].find((o) => o.eventId === dd.eventId && o.groupId === dd.groupId && o.stationId === dd.stationId)
	  if (offer) {
	      offer.status = dd.status
	      changed = true
	  }
      }
      if (changed) {
          return Object.assign({}, state, { eventOffers: eventOffers, sendInvitationStatus: statuses.SUBMIT_SUCCESS, });
      }
      return state;
    case CONFIRM_STATION_OFFERS_FAILED:
      return Object.assign({}, state, { error: action.payload });
    case ACCEPT_STATION_OFFER_SUCCESS:
      return Object.assign({}, state, {
        groupOffer: action.payload,
        groupOfferAcceptError: null
      });
    case ACCEPT_STATION_OFFER_FAILED:
      return Object.assign({}, state, { groupOfferAcceptError: action.payload });
    case REJECT_STATION_OFFER_SUCCESS:
      return Object.assign({}, state, {
        groupOffer: action.payload,
        groupOfferRejectError: null
      });
    case REJECT_STATION_OFFER_FAILED:
      return Object.assign({}, state, { groupOfferRejectError: action.payload });
    case GROUP_OFFER_FETCH_SUCCESS:
      return Object.assign({}, state, {
        groupOffer: action.payload,
        error: null
      });
    case GROUP_OFFER_FETCH_FAILED:
      return Object.assign({}, state, { groupOfferError: action.payload });
    case DELETE_OFFER_SUCCESS:
      return Object.assign({}, state, { deleteOfferError: null });
    case DELETE_OFFER_FAILED:
      return Object.assign({}, state, { deleteOfferError: action.payload });
    case RESET_GROUP_OFFER_ERROR:
      return Object.assign({}, state, { groupOfferError: null });
    case OFFER_CREATE:
      const offerCreateStatusSuccess = state.offerCreateStatus;
      offerCreateStatusSuccess[action.payload.stationId] = statuses.SUBMITTING;

      return Object.assign({}, state, { offerCreateStatus: offerCreateStatusSuccess });
    case OFFER_CREATE_SUCCESS:
      const offerCreateStatus = Object.assign({}, state.offerCreateStatus);
      offerCreateStatus[action.data.stationId] = statuses.SUBMIT_SUCCESS;

      return Object.assign({}, state, { offerCreateStatus });
    case OFFER_CREATE_FAILED:
      const offerCreateStatusError = Object.assign({}, state.offerCreateStatus);
      offerCreateStatusError[action.data.stationId] = statuses.SUBMIT_FAILED;

      return Object.assign({}, state, { offerCreateStatus: offerCreateStatusError });
    case STATION_OFFERS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        stationOffers: action.payload,
        stationOfferError: null
      });
    case STATION_OFFERS_FETCH_FAILED:
      return Object.assign({}, state, {
        stationOffers: null,
        stationOfferError: action.payload
      });
    case STATION_OFFER_UPDATE:
      // Attendance saga receives an updated offer. Update here as well
      const stationOffers = JSON.parse(JSON.stringify(state.stationOffers));
      const stationOffer = stationOffers.find(o => o.stationIdGroupId === action.payload.stationIdGroupId);

      if (stationOffer) {
        stationOffer.adultAttendance = action.payload.adultAttendance;
        stationOffer.childAttendance = action.payload.childAttendance;
        return Object.assign({}, state, { stationOffers: stationOffers });
      } else {
        return state;
      }
    case SEND_OFFER_REMINDER:
      return Object.assign({}, state, { sendOfferReminderStatus: statuses.SUBMITTING });
    case SEND_OFFER_REMINDER_SUCCESS:
      return Object.assign({}, state, {
        sendOfferReminderStatus: statuses.SUBMIT_SUCCESS,
        sendOfferReminderGroups: action.payload
      });
    case SEND_OFFER_REMINDER_FAILED:
      return Object.assign({}, state, {
        sendOfferReminderStatus: statuses.SUBMIT_FAILED,
        sendOfferReminderGroups: []
      });
    case SEND_OFFER_INVITATION:
      return Object.assign({}, state, { sendOfferInvitationStatus: statuses.SUBMITTING });
    case SEND_OFFER_INVITATION_SUCCESS:
      return Object.assign({}, state, {
        sendOfferInvitationStatus: statuses.SUBMIT_SUCCESS,
        sendOfferInvitationGroups: action.payload
      });
    case SEND_OFFER_INVITATION_FAILED:
      return Object.assign({}, state, {
        sendOfferInvitationStatus: statuses.SUBMIT_FAILED,
        sendOfferInvitationGroups: []
      });
    case UPDATE_OFFER_ATTENDEES:
      return state;
    case UPDATE_OFFER_ATTENDEES_FAILED:
      return Object.assign({}, state, {
        groupOffer: null,
        error: action.payload
      });
    case CREATE_SELF_REGISTRATION_LINK_SUCCESS:
      return Object.assign({}, state, {
        link: action.payload
      });
    case CREATE_SELF_REGISTRATION_LINK_FAIL:
      return Object.assign({}, state, {
        link: null,
        error: action.payload
      });
    case  CREATE_SELF_REGISTRATION_LINK:
      return Object.assign({}, state, {
        createLinkStatus: statuses.SUBMITTING
      });
    default:
      return state;
  }
}

export { statuses };

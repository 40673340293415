import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchSendOuts } from "../reducer";
import SendOutsList from "./SendOutsList";

const mapStateToProps = (state) => ({
  fetchStatus: state.sendOuts.fetchStatus,
  status: state.sendOuts.status,
  sendOuts: state.sendOuts.sendOuts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSendOuts: data => dispatch(fetchSendOuts(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SendOutsList));

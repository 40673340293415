import React from 'react';
import styled from 'styled-components';
import Logo from '../../../components/Logo';
import { Link } from 'react-router-dom';
import { breakPoints } from '../../../constants/styling-constants';
import img from '../../../images/Volex_bild_LoggaIn.jpg';

const ContentWrapper = styled.div`
  padding: 75px 0;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-image: url(${img});
  background-size: cover;
    @media (max-width: ${breakPoints.sm}px) {
    height: 700px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    font-size: 40px;
    height: 484px;
  }
  justify-content: center;
  text-align: center;
`;

const InnerWrapper = styled.div`
  width: 530px;
  @media (max-width: ${breakPoints.xs}px) {
    width: 100%
    padding: 0 20px;
  }
`;

const Center = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const Wrapper = ({children}) => (
  <ContentWrapper>
    <InnerWrapper>
      <Center>
        <Link to="/">
          <Logo />
        </Link>
      </Center>
      {children}
    </InnerWrapper>
  </ContentWrapper>
);

export default Wrapper;

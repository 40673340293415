import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const H4 = styled.h4`
  color: ${colors.text};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export {
  H4,
};

import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const TextLabel = styled.p`
  color: ${colors.text};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 20px;
`;

export default TextLabel;

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import messages from "../messages";
import { FormattedMessage } from "react-intl";
import {
  Checkbox,
  BorderTable,
  Error,
  P,
} from "../../../../../components";
import styled from "styled-components";
import { colors } from "../../../../../constants/styling-constants";

const TDDisabledText = styled.td`
  &.no-groups {
    color: ${colors.mediumLightGray};
  }
`;

const TDGrayBackground = styled.td`
  background-color: ${colors.lightGray} !important;
`;

const StationsListCheckboxes = ({ stations, values = [], changeValue, offers, groups = [], meta: { touched, error, warning } }) => {
  const [expanded, setExpanded] = useState([]);
  const haveNoGroups = (station, allOffers) => {
    const ofs = allOffers.filter(o => o.stationId === station.id && o.status === "ACCEPTED");
    return ofs.length === 0;
  };
  
  const stationsWithNoGroups = stations ? stations.filter(s => haveNoGroups(s, offers)) : [];

  const onChangeStation = (e) => {
    const name = parseInt(e.target.name, 10);
    let vals = [];

    if (e.target.checked) {
      vals = values.splice(0);
      vals.push(parseInt(name, 10));
    } else {
      const index = values.indexOf(name);

      if (index > -1) {
        vals = values.filter(v => v !== name);
      }
    }

    changeValue("stations", vals);
  };

  const onRowClick = (rowId) => {
    const prevExpanded = expanded;
    const isRowExpanded = prevExpanded.includes(rowId);
    const newExpanded = isRowExpanded ? prevExpanded.filter(id => id !== rowId) : prevExpanded.concat(rowId);
    setExpanded(newExpanded);
  }

  const renderVolunteerRowHeader = (
      <tr key={0}>
        <TDGrayBackground className="hidden-xs"></TDGrayBackground>
        <TDGrayBackground className="hidden-xs"></TDGrayBackground>
        <TDGrayBackground> <FormattedMessage {...messages.form.header.name}/> </TDGrayBackground>
        <TDGrayBackground className="hidden-xs"> <FormattedMessage {...messages.form.header.age}/> </TDGrayBackground>
        <TDGrayBackground> <FormattedMessage {...messages.form.header.phone}/> </TDGrayBackground>
        <TDGrayBackground> <FormattedMessage {...messages.form.header.email}/> </TDGrayBackground>
      </tr>
  );

  const renderVolunteerRow = (volunteer) => {
    return (
      <tr key={volunteer.id}>
        <TDGrayBackground className="hidden-xs"></TDGrayBackground>
        <TDGrayBackground className="hidden-xs"></TDGrayBackground>
        <TDGrayBackground>{volunteer.name}</TDGrayBackground>
        <TDGrayBackground className="hidden-xs">{volunteer.age}</TDGrayBackground>
        <TDGrayBackground>{volunteer.phone}</TDGrayBackground>
        <TDGrayBackground>{volunteer.email}</TDGrayBackground>
      </tr>
    );
  }
  
  const renderRow = (station) => {
    const noGroups = haveNoGroups(station, offers);
    const group = groups.filter(eventStations => eventStations.stationId === station.id);
    const rows = [
      <tr key={station.id} onClick={() => onRowClick(station.id)}>
        <td>
          <Checkbox
            id={station.id}
            onChange={onChangeStation}
            checked={values && values.includes(station.id)}
            name={station.id}
            disabled={noGroups}
            />
        </td>
        <TDDisabledText className={noGroups ? "no-groups hidden-xs" : "hidden-xs"}>
            {expanded.includes(station.id) ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
        </TDDisabledText>
        <TDDisabledText className={noGroups ? "no-groups" : ""}>{station.name}</TDDisabledText>
        <TDDisabledText className={noGroups ? "no-groups" : ""}>{renderGroupNames(station, offers)}</TDDisabledText>
        <TDDisabledText className={noGroups ? "no-groups hidden-xs" : "hidden-xs"}>{renderVolunteerExpected(station, offers)}</TDDisabledText>
        <TDDisabledText className={noGroups ? "no-groups hidden-xs" : "hidden-xs "}>{station.officialQuantity}</TDDisabledText>
      </tr>
    ];

    if(group.length === 0)
      return rows;
    
    if(expanded.includes(station.id)) {
      rows.push(renderVolunteerRowHeader);
      group.map(g => g.attendees ? rows.push(g.group.volunteers.filter(vol => g.attendees.includes(vol.id)).map(vol => renderVolunteerRow(vol))) : null);
    }
    return rows;
  }

  const onChangeAll = () => {
    let vals = [];

    if (values.length !== stations.length - stationsWithNoGroups.length) {
      vals = stations.reduce((result, s) => {
        if (!haveNoGroups(s, offers)) {
          result.push(parseInt(s.id, 10));
        }
        return result;
      }, []);
    }

    changeValue("stations", vals);
  };

  const renderVolunteerExpected = (station, allOffers) => {
    var count = 0;
    allOffers.filter(o => o.stationId === station.id && o.status === "ACCEPTED").map(o => {
      if (o.attendees) {
        count = count + o.attendees.length;
      }
      return o;
    })
    return count;
  }

  const renderGroupNames = (station, allOffers) => {
    const ofs = allOffers.filter(o => o.stationId === station.id && o.status === "ACCEPTED").map(o => o.group.name);
    return ofs.join(", ");
  };

  if (!stations) {
    return (<P><FormattedMessage {...messages.form.stations.noStations} /></P>);
  }

  return (
    <Fragment>
      <BorderTable>
        <thead>
          <tr>
            <th>
              <Checkbox
                id={"toggle-all"}
                onChange={onChangeAll}
                checked={values && values.length === stations.length - stationsWithNoGroups.length}
                name={"toggle-all"}
              />
            </th>
            <th className="hidden-xs"></th>
            <th><FormattedMessage {...messages.form.stations.name} /></th>
            <th><FormattedMessage {...messages.form.stations.groups} /></th>
            <th className="hidden-xs"><FormattedMessage {...messages.form.volunteerExpected} /></th>
            <th className="hidden-xs"><FormattedMessage {...messages.form.officialQuantity} /></th>
          </tr>
        </thead>
        <tbody>
          {stations.map(stations => renderRow(stations))}
        </tbody>
      </BorderTable>
      {touched &&
      ((error && <Error error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

StationsListCheckboxes.propTypes = {
  stations: PropTypes.array,
  values: PropTypes.array,
  changeValue: PropTypes.func.isRequired,
  offers: PropTypes.array,
};

export default StationsListCheckboxes;

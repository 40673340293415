import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const WhiteTable = styled.table`
  width: 100%;
  max-width: 100%;
  tr {
    transition: background-color 0.1s ease;
  }
  th {
  
  }
  td, th {
    background-color: #FFF;
    border-bottom: 1px solid ${colors.lightPink};
    padding: 13px;
  }
  .right {
    text-align: right;
  }
  .no-background {
    background-color: transparent;
    border: none;
  }
  .small-cell {
    width: 1%;
    white-space:nowrap;
  }
`;

const WhiteTableWithLastToRight = WhiteTable.extend`
  td:last-child {
    width:1%;
    white-space:nowrap;
  }
`;

const BorderTable = styled.table`
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;
  tr {
  }
  th, td {
    padding: 15px 10px;
  }
  td {
    border: 1px solid ${colors.lightPink};
    background-color: #FFF;
    padding: 13px;
  }
  .right {
    text-align: right;
  }
  .no-background {
    background-color: transparent;
    border: none;
  }
`;

const TableStatus = styled.span`
  font-size: 14px;
  &.draft {
    color: ${colors.orange};
  }
  &.historic {
    color: ${colors.lightGray4};
  }
`;

const TableRow = styled.tr`
  cursor: pointer;
  &:hover td:not(.no-background){
    background-color: ${colors.lightGray3}
  }
`;

const TableRowWithClick = ({children, row, callback}) => {
  const handleClick = () => {
    callback(row);
  };

  return (
    <TableRow onClick={handleClick}>
      {children}
    </TableRow>
  );
};

export {
  WhiteTable,
  BorderTable,
  WhiteTableWithLastToRight,
  TableStatus,
  TableRowWithClick,
  TableRow
};

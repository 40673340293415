import React from 'react';
import styled from 'styled-components';
import img from '../../images/VolEx_logo_footer.svg';

const Wrapper = styled.img`
  width: 150px;
`;

const GrayLogo = () => (<Wrapper src={img} />);

export default GrayLogo;

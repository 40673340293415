import React from "react";
import { Row, Col } from "react-bootstrap/lib";
import Menu from "../Menu";
import Logo from "../Logo";
import { FlexSpaceBetween, Container } from "../index";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { headerHeight, breakPoints } from "../../constants/styling-constants";

const Wrapper = styled.header`
  position: sticky;
  z-index: 9999;
  top: 0;
  padding: 0;
  background-color: #fff;
  height: ${headerHeight}px;
  @media (max-width: ${breakPoints.sm}px) {
    padding-top: 15px;
  }
`;

const LinkedLogo = styled(Link)``;

const Header = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col sm={12}>
          <FlexSpaceBetween>
            <LinkedLogo to="/">
              <Logo />
            </LinkedLogo>
            <Menu />
          </FlexSpaceBetween>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Header;

import React, { Component, Fragment } from "react";
import {
  H1,
  H2,
  Input,
  DatePicker,
  MultiSelect,
  FormGroup,
  TextLabel,
  ContactForm,
  TimePicker,
  ListSelect,
} from "../../../components";
import { Button } from "../../../components/Buttons";
import { Row, Col } from "react-bootstrap/lib";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  required,
  minLength3,
  maxLength250,
  organizerNumber,
  afterTodaysDate,
} from "../../../validators";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { eventStatus } from "./reducer";
import { lists } from "../../../constants/lists.js"; // TODO: Add updated volex-data repository
import additionalQuestions from "../../../constants/lists/event-additional-questions";

const { foodOptions, shoeSizes, shirtSizes } = lists;

const withLoadedProps = (LoadedComponent, loadedProps) => (props) =>
  <LoadedComponent {...loadedProps} {...props} />;

class EventNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactList: [],
      submitClicked: false,
      nextClicked: false,
      userAdded: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.setEventId = this.setEventId.bind(this);
    this.updateContactListFromForm = this.updateContactListFromForm.bind(this);
  }

  updateContactListFromForm(contactList) {
    this.setState({ contactList });
  }

  renderShirtSizes() {
    if (!this.props.included) {
      return null;
    }

    const hasShirt = this.props.included.includes("CLOTHES");

    if (hasShirt) {
      const shirtSizesTranslated = shirtSizes.map((opt) => ({
        id: opt,
        name: this.props.intl.formatMessage({
          id: `shirt-size.${opt.toLowerCase()}`,
        }),
      }));
      return (
        <FormGroup>
          <TextLabel>
            <FormattedMessage
              {...messages.registerEvent.form.include.clothesSize.title}
            />
          </TextLabel>
          <Field
            placeholder={this.props.intl.formatMessage(
              messages.registerEvent.form.include.clothesSize.placeholder
            )}
            data={shirtSizesTranslated}
            valueField="id"
            textField="name"
            name="shirtSizes"
            component={MultiSelect}
          />
        </FormGroup>
      );
    }

    return null;
  }

  renderFoodOptions() {
    if (!this.props.included) {
      return null;
    }

    const hasFood = this.props.included.includes("FOOD");

    if (hasFood) {
      const foodOptionsTranslated = foodOptions.map((opt) => ({
        id: opt,
        name: this.props.intl.formatMessage({
          id: `food.${opt.toLowerCase()}`,
        }),
      }));
      return (
        <FormGroup>
          <TextLabel>
            <FormattedMessage
              {...messages.registerEvent.form.include.foodOptions.title}
            />
          </TextLabel>
          <Field
            name="foodOptions"
            placeholder={this.props.intl.formatMessage(
              messages.registerEvent.form.include.foodOptions.placeholder
            )}
            data={foodOptionsTranslated}
            valueField="id"
            textField="name"
            component={MultiSelect}
          />
        </FormGroup>
      );
    }

    return null;
  }
  /*
  renderShoeSizes() {
    if (!this.props.included) {
      return null;
    }

    const hasShoe = this.props.included.includes("SHOE");
    const shoeSizeOptions = shoeSizes.map(size => ({
      id: size,
      name: size
    }));

    if (hasShoe) {
      return (
        <FormGroup>
          <TextLabel>
            <FormattedMessage
              {...messages.registerEvent.form.include.shoeSize.title}
            />
          </TextLabel>
          <Field
            placeholder={this.props.intl.formatMessage(
              messages.registerEvent.form.include.shoeSize.placeholder
            )}
            data={shoeSizeOptions}
            valueField="id"
            textField="name"
            name="shoeSizes"
            component={MultiSelect}
          />
        </FormGroup>
      );
    }

    return null;
  }
  */

  static getDerivedStateFromProps(props, state) {
    const contactListCopy = state.contactList.slice();

    if (
      props.user &&
      !state.contactList.find(
        (contact) => contact.email === props.user.email
      ) &&
      !state.userAdded
    ) {
      contactListCopy.push({
        email: props.user.email,
        name: `${props.user.firstName} ${props.user.lastName}`,
        id: state.contactList.length,
        phone: props.user.phone,
      });
      return {
        contactList: contactListCopy,
        userAdded: true,
      };
    }

    if (props.status === eventStatus.SUCCESS) {
      return {
        submitClicked: false,
        nextClicked: false,
      };
    }

    return null;
  }

  sortShirtSizes(unsorted) {
    const sorted = []
    if(unsorted.includes("XS")){ sorted.push("XS")}
    if(unsorted.includes("S")){ sorted.push("S")}
    if(unsorted.includes("M")){ sorted.push("M")}
    if(unsorted.includes("L")){ sorted.push("L")}
    if(unsorted.includes("XL")){ sorted.push("XL")}
    if(unsorted.includes("XXL")){ sorted.push("XXL")}
    
    return sorted
  }

  setEventId(fieldValues) {
    const newEvent = Object.assign({}, fieldValues, {
      contactPersons: this.state.contactList.filter((c) => !c.toBeDeleted),
    });
    if (this.props.event) {
      newEvent.id = this.props.event.id;
    }

    return newEvent;
  }

  handleSubmit(fieldValues) {

    if (this.state.submitClicked) {
      return;
    }

    this.setState({
      submitClicked: true,
    });

    if(fieldValues.shirtSizes){
      const sorted = this.sortShirtSizes(fieldValues.shirtSizes)
      fieldValues.shirtSizes = sorted
    }

    this.props.submitEvent(this.setEventId(fieldValues));
  }

  handleNext(fieldValues) {

    this.setState({
      nextClicked: true,
    });

    if(fieldValues.shirtSizes){
      const sorted = this.sortShirtSizes(fieldValues.shirtSizes)
      fieldValues.shirtSizes = sorted
    }

    this.props.onClickNext(this.setEventId(fieldValues), 1);
  }

  render() {
    const additionalQuestionOptions = additionalQuestions.map((q) => ({
      id: q,
      value: this.props.intl.formatMessage({
        id: `lists.additionalQuestions.${q}`,
      }),
    }));

    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1>
              <FormattedMessage {...messages.registerEvent.title} />
            </H1>
          </Col>
        </Row>
        <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Row>
            <Col sm={5}>
              <H2>
                <FormattedMessage
                  {...messages.registerEvent.form.event.title}
                />
              </H2>
              <FormGroup>
                <Field
                  name="name"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.event.name
                  )}
                  type="text"
                  component={Input}
                  validate={[required, minLength3, maxLength250]}
                />
                <Field
                  name="date"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.event.date
                  )}
                  component={DatePicker}
                  validate={[required, afterTodaysDate]}
                />
                <Field
                  name="startTime"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.event.startTime
                  )}
                  component={TimePicker}
                  validate={[required]}
                />
                <Field
                  name="city"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.event.city
                  )}
                  component={Input}
                  type="text"
                  validate={[required, maxLength250]}
                />
              </FormGroup>
              {
                <FormGroup>
                  <TextLabel>
                    <FormattedMessage
                      {...messages.registerEvent.form.include.title}
                    />
                  </TextLabel>

                  <Field
                    name="included"
                    component={withLoadedProps(ListSelect, {
                      display: "block",
                    })}
                    valueField="id"
                    textField="value"
                    data={additionalQuestionOptions}
                  />
                  {this.renderShirtSizes()}
                  {this.renderFoodOptions()}
                  <Field
                  name="substitute"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.substitute.title
                  )}
                  component={ListSelect}
                  data={[{id: "active", value: this.props.intl.formatMessage(
                    messages.registerEvent.form.substitute.option
                  )}]}
                  valueField="id"
                  textField="value"
                />
                </FormGroup>
              }
              
              {/*this.renderShoeSizes()*/}
              <FormGroup>
                <H2>
                  <FormattedMessage
                    {...messages.registerEvent.form.organizer.title}
                  />
                </H2>
                <Field
                  name="organizerNumber"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.organizer.organizerNumber
                  )}
                  type="text"
                  component={Input}
                  validate={[required, organizerNumber]}
                />
                <Field
                  name="organizerName"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.organizer.name
                  )}
                  type="text"
                  component={Input}
                  validate={[required]}
                />
                <Field
                  name="organizerWebsite"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.organizer.website
                  )}
                  type="text"
                  component={Input}
                />
                <Field
                  name="organizerEmail"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.organizer.email
                  )}
                  type="text"
                  component={Input}
                />
                <Field
                  name="invoiceAddress"
                  placeholder={this.props.intl.formatMessage(
                    messages.registerEvent.form.organizer.invoiceAddress
                  )}
                  type="text"
                  component={Input}
                />
              </FormGroup>
              <ContactForm
                intl={this.props.intl}
                contactList={this.state.contactList}
                updateContactList={this.updateContactListFromForm}
                type="event"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <Button
                type="submit"
                busy={this.state.submitClicked}
                label={this.props.intl.formatMessage(
                  messages.registerEvent.saveEventButton
                )}
              />
              <Button
                type="button"
                busy={this.state.nextClicked}
                onClick={this.props.handleSubmit(this.handleNext)}
                label={this.props.intl.formatMessage(
                  messages.registerEvent.nextButton
                )}
              />
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

EventNew = reduxForm({
  form: "event",
})(EventNew);

// Decorate with connect to read form values
const selector = formValueSelector("event"); // <-- same as form name

EventNew = connect((state) => {
  const included = selector(state, "included");

  return {
    included,
  };
})(EventNew);

export default injectIntl(EventNew);

import { connect } from "react-redux";
import { reset, submitEvent, confirmEvent } from "../reducer";
import ConfirmEvent from "./ConfirmEvent";

const mapStateToProps = (state, ownProps) => ({
  event: ownProps.event,
  status: state.event.status,
  initialValues: ownProps.event,
});

const mapDispatchToProps = (dispatch) => ({
  submitEvent: data => dispatch(submitEvent(data)),
  confirmEvent: data => dispatch(confirmEvent(data)),
  resetEvent: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmEvent);

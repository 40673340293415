import SendInvitationButton from "./SendInvitationButton";
import { connect } from "react-redux";
import { confirmStationOffers } from "../../../Offer/reducer";

const mapStateToProps = (state) => ({
  status: state.offers.sendInvitationStatus,
});

const mapDispatchToProps = (dispatch) => ({
  confirmStationOffers: data => dispatch(confirmStationOffers(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendInvitationButton);

import { defineMessages } from 'react-intl';

export default defineMessages({
  form: {
    firstName: {
      id: 'my-profile.form.firstName.placeholder',
    },
    lastName: {
      id: 'my-profile.form.lastName.placeholder',
    },
    email: {
      id: 'my-profile.form.email.placeholder',
    },
    phone: {
      id: 'my-profile.form.phone.placeholder',
    },
    countryCode: {
      id: 'register.form.countryCode.placeholder',
    },
  },
  title: {
    id: 'my-profile.title',
  },
  editButton: {
    id: 'my-profile.edit-profile-button',
  },
  saveButton: {
    id: 'my-profile.save-profile-button',
  },
});

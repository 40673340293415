import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (min-width: 500px) {
    button, a {
      width: 240px;
    }
  }
`;

export default ButtonWrapper;

export { default as foodOptions } from './food-options';
const shoeSizes = [
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
];

export default shoeSizes;

import React, { Fragment } from "react";
import { compose } from "redux"
import { Multiselect } from "react-widgets";
import styled, { css } from "styled-components";
import { colors } from "../../constants/styling-constants";
import Error from "./Error";
import { TextLabel } from "../index";
import { Row, Col } from "react-bootstrap/lib";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { withObjectValues } from "./selectValuesHoc";

const StyledMulti = styled(Multiselect)`
  font-size: 17px;
  margin-bottom: 15px;
  .rw-widget-picker {
    &, &:hover, &:active {
      background-color: ${props => (props.error ? colors.lightPink2 : "#FFF")};
    }
    width: 100%;
    border: none;
    border-radius: 0;
  }
  
  .rw-widget-picker > div {
    vertical-align: top;
  }
  
  .rw-widget-input {
    height: 46px;
  }
  input::placeholder {
    color: ${colors.inputPlaceholderGrey};
  }
  .rw-input-reset {
    padding-top: 5px;
    width: 100%;
    ${props => props.error && css`
      &::placeholder {
        color: ${colors.pink};
      }
    `}
  }
  .rw-i {
    color: #FFF;
  }
  .rw-multiselect-taglist {
    li {
      height: 40px;
    }
    button {
      background-color: transparent;
    }
  }
`;

const ReadonlyItem = styled.div`
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
`;

const multiselectMessages = formatMessage => ({
  emptyFilter: formatMessage(messages.multiSelect.messages.emptyFilter),
  emptyList: formatMessage(messages.multiSelect.messages.emptyList),
});


const MultiComponent = ({ input, data, valueField, intl, placeholder, textField, meta: { touched, error, warning }, readonly = false }) => {
  if (readonly) {
    return (
      <Row>
        <Col md={5}>
          {placeholder ? <TextLabel>{placeholder}</TextLabel> : null}
        </Col>
        <Col md={7}>
          {input.value ?
            input.value.map((v) => {
              const item = data.find(d => v === d.id || v === d);
              if (item) {
                return (<ReadonlyItem key={item.id}>{item.name}</ReadonlyItem>);
              }
              return null;
            })
            :
            null}
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <StyledMulti
        {...input}
        data={data}
        valueField={valueField}
        textField={textField}
        messages={multiselectMessages(intl.formatMessage)}
        placeholder={placeholder}
        error={touched && error}
      />
      {touched &&
      ((error && <Error error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

export default compose(withObjectValues, injectIntl)(MultiComponent)

import { connect } from "react-redux";
import EventBase from "../../Event/Base/EventBase";
import { withRouter } from "react-router";
import { fetchTeamleaderEvent, reset } from "../reducer";

const mapStateToProps = (state) => ({
  event: state.teamleader.event,
  error: state.teamleader.error,
  status: state.teamleader.status,
  isTeamleaderView: true,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEvent: data => dispatch(fetchTeamleaderEvent(data)),
  reset: () => dispatch(reset({})),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EventBase));

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as GroupOffersComponent } from "./GroupOffers";
import { fetchGroupsOffers, createLink } from "./reducer";

const mapStateToProps = (state) => ({
  groupOffers: state.offers.groupOffers,
  selfRegistrationLink: state.offers.link
});

const mapDispatchToProps = (dispatch) => ({
  fetchGroupsOffers: data => dispatch(fetchGroupsOffers(data)),
  createLink: data => dispatch(createLink(data)),
});

export const GroupOffers = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupOffersComponent));


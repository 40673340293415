export const VOLUNTEER_SUBMIT = 'VOLUNTEER SUBMIT';
export const VOLUNTEER_SUCCESS = 'VOLUNTEER SUCCESS';
export const VOLUNTEER_FAILED = 'VOLUNTEER FAILED';
export const FETCH_FAILED = 'EVENT FETCH FAILED';
export const FETCHING = 'FETCHING EVENT'
export const FETCH_SUCCESS = 'EVENT FETCH SUCCESS'

export const status = {
    NOT_SUBMITTED: 'NOT_SUBMITTED',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const fetchStatus = {
    NOT_FETCHING: 'NOT_FETCHING',
    FETCHING: 'FETCHING',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILED: 'FETCH_FAILED',
};

export function submitVolunteer(data) {
    return {
        type: VOLUNTEER_SUBMIT,
        payload: data,
    };
};

export function fetchPublicEvent(eventId) {
    return {
        type: FETCHING,
        payload: eventId,
    };
};

const initialState = {
    status: status.NOT_SUBMITTED,
    fetchStatus: fetchStatus.NOT_FETCHING,
    event: null,
    error: null,
};

export default function selfRegistration(state = initialState, action) {
    if (!action) {
      return state;
    }
  
    switch (action.type) {
        case VOLUNTEER_SUBMIT:
            return Object.assign({}, state, {status: status.SUBMITTING, error: null});
        case VOLUNTEER_SUCCESS:
            return Object.assign({}, state, {status: status.SUCCESS, error: null});
        case VOLUNTEER_FAILED:
            return Object.assign({}, state, {status: status.FAILED, error: action.payload});
        case FETCHING:
            return Object.assign({}, state, {fetchStatus: fetchStatus.FETCHING, event:null});
        case FETCH_SUCCESS:
            return Object.assign({}, state, {fetchStatus: fetchStatus.FETCH_SUCCESS, event: action.payload});
        case FETCH_FAILED:
            return Object.assign({}, state, {fetchStatus: fetchStatus.FETCH_FAILED, error: action.payload, event:null});
        default: 
            return state;
    };
};
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import messages from "./messages";
import { Input, Select, GeneralError } from "../../../../components";
import { SmallGrayButton } from "../../../../components/Buttons";
import { Col } from "react-bootstrap/lib";
import { FormattedMessage } from "react-intl";
import { required } from "../../../../validators/index";
import { statuses } from "../../Offer/reducer";

const FormWrapper = styled.div`
  margin: 20px 0;
`;

const StyledTd = styled.td`
  padding: 0 15px 10px 0;
  font-size: 18px;
`;

const maxOfficials = max => value =>
  value > max ? `Du kan max lägga in ${max} funktionärer` : undefined;

const getNumOfOccupiedVolunteers = (group, allOffers) => {
  let occupiedVolunteers = 0;
  if (allOffers) {
    allOffers.forEach(offer => {
      if (
        group &&
        group.groupId === offer.groupId &&
        offer.status !== "REJECTED"
      ) {
        occupiedVolunteers += parseInt(offer.volunteers, 10);
      } else if (
        (group === undefined || group === null) &&
        offer.status !== "REJECTED"
      ) {
        occupiedVolunteers += parseInt(offer.volunteers, 10);
      }
    });
  }
  return occupiedVolunteers;
};

/**
 * Filter out doublets, sort, and filter out all groups that already have an offer
 *
 * @station: Current event station
 * @availableGroups: Array of group availabilities
 */
const getGroupOptions = (station, availableGroups, allOffers, intl) => {
  const groupsWithoutDoublets = availableGroups
    .slice()
    .filter((currentGroup, GroupPos, groupArr) => {
      return (
        groupArr
          .map(group => group["groupId"])
          .indexOf(currentGroup["groupId"]) === GroupPos
      );
    });
  groupsWithoutDoublets.sort((a, b) => {
    let comparision;
    if (a.group.numberOfMembers > b.group.numberOfMembers) {
      comparision = 1;
    } else {
      comparision = -1;
    }
    return comparision * -1;
  });

  // Filter out groups that have an offer on this station
  const groupWithoutOfferOnThisStation = groupsWithoutDoublets.filter(
    gd =>
      !allOffers.find(
        ao => ao.groupId === gd.groupId && ao.stationId === station.id
      )
  );
  // Filter out groups that don't have any available volunteers
  const groupsWithAvailableVolunteers = groupWithoutOfferOnThisStation.filter(
    groupAvailability => {
      const occupiedVolunteers = getNumOfOccupiedVolunteers(
        groupAvailability.group,
        allOffers
      );
      return occupiedVolunteers < groupAvailability.group.numberOfMembers;
    }
  );

  const groupOptions = groupsWithAvailableVolunteers.map(({ group }) => {
    const occupiedVolunteers = getNumOfOccupiedVolunteers(group, allOffers);

    return {
      id: group.id,
      text: `${group.organizationName} - ${
        group.name
      } - ${group.numberOfMembers - occupiedVolunteers} ${
        group.skills
          ? ` - ${group.skills
              .map(skill => intl.formatMessage({ id: `lists.skills.${skill}` }))
              .join(", ")}`
          : ""
      }`
    };
  });

  return groupOptions;
};

const GroupSummary = ({ group, formatMessage, occupiedVolunteers }) => {
  if (!group) {
    return null;
  }
  return (
    <Col sm={12}>
      <table>
        <tbody>
          <tr>
            <StyledTd>
              <FormattedMessage {...messages.addGroup.groupSummary.ages} />
            </StyledTd>
            <td>{group.ages.join(", ")}</td>
          </tr>
          <tr>
            <StyledTd>
              <FormattedMessage {...messages.addGroup.groupSummary.members} />
            </StyledTd>
            <td>{group.numberOfMembers - occupiedVolunteers}</td>
          </tr>
          <tr>
            <StyledTd>
              <FormattedMessage {...messages.addGroup.groupSummary.sports} />
            </StyledTd>
            <td>{(group.sports || []).join(", ")}</td>
          </tr>
          <tr>
            <StyledTd>
              <FormattedMessage {...messages.addGroup.groupSummary.skills} />
            </StyledTd>
            <td>
              {group.skills
                ? group.skills
                    .map(skill =>
                      formatMessage({
                        id: `lists.skills.${skill}`
                      }).toLowerCase()
                    )
                    .join(", ")
                : null}
            </td>
          </tr>
        </tbody>
      </table>
    </Col>
  );
};

class AddGroup extends Component {
  constructor(props) {
    super(props);

    const { availableGroups = [], intl, allOffers, station } = this.props;

    this.state = {
      groupMemberError: false,
      allocatedError: false,
      groupOptions: getGroupOptions(station, availableGroups, allOffers, intl)
    };

    this.handleSubmit = this.handleSubmit.bind(this);
     //this.confirmStationOffers = this.confirmStationOffers.bind(this);

    this.maxAllowedOfficials = maxOfficials(
      parseInt(this.props.station.officialQuantity, 10)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.volunteers !== prevProps.volunteers &&
      this.state.groupMemberError
    ) {
      this.checkGroupMemberError();
    }
    if (this.state.allocatedError) {
      const {
        officialsAllocated,
        officialsProposed,
        officialsDraft
      } = this.props;

      if (
        officialsAllocated !== prevProps.officialsAllocated ||
        officialsProposed !== prevProps.officialsProposed ||
        officialsDraft !== prevProps.officialsDraft
      ) {
        this.checkIfAllIsAllocated(this.props.volunteers);
      }
    }
    if (
      prevProps.offerCreateStatus[this.props.station.id] ===
        statuses.SUBMITTING &&
      this.props.offerCreateStatus[this.props.station.id] ===
        statuses.SUBMIT_SUCCESS
    ) {
      // Clear the group field and untouch it to hide the validation message
      this.props.change("group", "");
      this.props.untouch("group", "");
      this.updateGroupOptions();
    }

    if (
      this.props.availableGroups !== prevProps.availableGroups ||
      prevProps.allOffers.length !== this.props.allOffers.length
    ) {
      this.updateGroupOptions();
    }
  }

  updateGroupOptions() {
    const { availableGroups = [], intl, allOffers, station } = this.props;
    this.setState({
      groupOptions: getGroupOptions(station, availableGroups, allOffers, intl)
    });
  }

  /**
   * Check if requested volunteers are lower or equal to number of group members
   */
  checkGroupMemberError() {
    const group = this.props.availableGroups
      .map(availableGroup => availableGroup.group)
      .find(group => group.id === this.props.group);
    const occupiedVolunteers = getNumOfOccupiedVolunteers(
      group,
      this.props.allOffers
    );
    const toManyRequestedVolunteers =
      this.props.volunteers > group.numberOfMembers - occupiedVolunteers;

    if (this.state.groupMemberError !== toManyRequestedVolunteers) {
      this.setState({ groupMemberError: toManyRequestedVolunteers });
    }

    return toManyRequestedVolunteers;
  }

  /**
   * Check if more volunteers are requested than what is missing on the station.
   *
   * Error = Antal > Behov - Accepted - Proposal - Draft
   */
  checkIfAllIsAllocated(numberOfRequested) {
    const {
      officialsAllocated,
      officialsProposed,
      officialsDraft,
      station
    } = this.props;
    const allocatedError =
      parseInt(numberOfRequested, 10) >
      parseInt(station.officialQuantity, 10) -
        officialsAllocated -
        officialsProposed -
        officialsDraft;
    if (allocatedError !== this.state.allocatedError) {
      this.setState({ allocatedError });
    }

    return allocatedError;
  }

  // confirmStationOffers() {
  //   if (this.props.offers) {
  //     for (const offer of this.props.offers) {
  //       if (offer.status === "DRAFT") {
  //         this.props.confirmStationOffer({
  //           groupId: offer.groupId,
  //           stationId: offer.stationId,
  //           eventId: offer.eventId
  //         });
  //       }
  //     }
  //   }
    
  // }

  handleSubmit(fieldValues) {
    const toManyRequestedFromGroup = this.checkGroupMemberError();
    const allocatedError = this.checkIfAllIsAllocated(fieldValues.volunteers);

    if (!toManyRequestedFromGroup && !allocatedError) {
      this.props.createOffer({
        volunteers: fieldValues.volunteers ? fieldValues.volunteers : 1,
        groupId: fieldValues.group,
        stationId: this.props.station.id,
        eventId: this.props.eventId
      });
    }
  }

  renderGroupMemberError() {
    if (!this.state.groupMemberError) {
      return null;
    }
    return (
      <GeneralError>
        {this.props.intl.formatMessage(messages.addGroup.toManyRequestedError)}
      </GeneralError>
    );
  }

  renderAllocatedError() {
    if (!this.state.allocatedError) {
      return null;
    }
    return (
      <GeneralError>
        {this.props.intl.formatMessage(messages.addGroup.allocatedError)}
      </GeneralError>
    );
  }

  renderCreateOfferError() {
    const { offerCreateStatus, station } = this.props;

    if (
      offerCreateStatus &&
      offerCreateStatus[station.id] &&
      offerCreateStatus[station.id] === statuses.SUBMIT_FAILED
    ) {
      return (
        <Col sm={12}>
          <GeneralError>
            {this.props.intl.formatMessage(messages.addGroup.createOfferError)}
          </GeneralError>
        </Col>
      );
    }

    return null;
  }

  render() {
    const {
      availableGroups = [],
      intl,
      allOffers,
      offerCreateStatus,
      station
    } = this.props;
    const group = this.props.group
      ? availableGroups
          .map(availableGroup => availableGroup.group)
          .find(group => group.id === this.props.group)
      : null;
    const occupiedVolunteers = getNumOfOccupiedVolunteers(group, allOffers);
    return (
      <div>
      {this.props.event.status == "HISTORIC" ? "" : 
        <form onSubmit={this.props.handleSubmit(this.handleSubmit)} noValidate>
          <FormWrapper>
            <Field
              name="group"
              type="select"
              placeholder={intl.formatMessage(messages.addGroup.selectGroup)}
              data={this.state.groupOptions}
              valueField="id"
              textField="text"
              component={Select}
              validate={[required]}
            />
            <GroupSummary
              group={group}
              formatMessage={this.props.intl.formatMessage}
              occupiedVolunteers={occupiedVolunteers}
            />
            <Field
              name="volunteers"
              placeholder={intl.formatMessage(
                messages.addGroup.requestedQuantity
              )}
              type="number"
              min="0"
              component={Input}
              validate={[required, this.maxAllowedOfficials]}
            />
          </FormWrapper>

          {this.renderGroupMemberError()}
          {this.renderAllocatedError()}
          {this.renderCreateOfferError()}
          <SmallGrayButton
            type="submit"
            className="rightPos submit"
            disabled={!this.props.group}
            busy={
              offerCreateStatus &&
              offerCreateStatus[station.id] &&
              offerCreateStatus[station.id] === statuses.SUBMITTING
            }
            label={intl.formatMessage(messages.addGroup.button)}
          
          />
          {/* <SmallPinkButton type="button" onClick={this.confirmStationOffers} label={intl.formatMessage(messages.saveButton)} /> */}
        </form>
        }
      </div>
    );
  }
}

AddGroup.propTypes = {
  createOffer: PropTypes.func.isRequired,
  station: PropTypes.object.isRequired,
  availableGroups: PropTypes.array
};

let AddGroupForm = reduxForm({
  form: `addGroup ${Math.random() * 100}`
})(AddGroup);

AddGroupForm = connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.form);
  return {
    group: selector(state, "group"),
    volunteers: selector(state, "volunteers"),
    initialValues: {
      volunteers: ownProps.station ? ownProps.station.officialQuantity - ownProps.officialsProposed - 
      ownProps.officialsAllocated - ownProps.officialsDraft : 1
    }
  };
})(AddGroupForm);

export default AddGroupForm;

export const FORGOT_PASSWORD_CODE_SEND = "FORGOT_PASSWORD_CODE_SEND";
export const FORGOT_PASSWORD_CODE_FAILED = "FORGOT_PASSWORD_CODE_FAILED";
export const FORGOT_PASSWORD_CODE_SUCCESS = "FORGOT_PASSWORD_CODE_SUCCESS";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_STATE = "RESET_PASSWORD_STATE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const FORGOT_PASSWORD_CODE_RESET_SUCCESS =
  "FORGOT_PASSWORD_CODE_RESET_SUCCESS";

export function sendForgotPasswordCode(data) {
  return {
    type: FORGOT_PASSWORD_CODE_SEND,
    payload: data
  };
}

export function resetPasswordSubmit(data) {
  return {
    type: RESET_PASSWORD,
    payload: data
  };
}

export function resetState(data) {
  return {
    type: RESET_PASSWORD_STATE
  };
}

export const status = {
  CODE_NOT_SENT: "CODE_NOT_SENT",
  CODE_SENT: "CODE_SENT",
  NEW_PASSWORD_SET: "NEW_PASSWORD_SET"
};

export const status2 = status;

const initialState = {
  error: null,
  status: status.CODE_NOT_SENT
};

const stateToUse = initialState;

export default function forgotPassword(state = stateToUse, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case FORGOT_PASSWORD_CODE_RESET_SUCCESS:
      return Object.assign({}, state, {
        status: status.CODE_NOT_SENT,
        error: null
      });
    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        status: status.NEW_PASSWORD_SET,
        error: null
      });
    case RESET_PASSWORD_FAILED:
      return Object.assign({}, state, { error: action.error });
    case FORGOT_PASSWORD_CODE_SUCCESS:
      return Object.assign({}, state, {
        status: status.CODE_SENT,
        error: null
      });
    case FORGOT_PASSWORD_CODE_FAILED:
      return Object.assign({}, state, {
        error: { ...action.payload },
        status: action.type
      });
    default:
      return state;
  }
}

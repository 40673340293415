import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import messages from "./messages";
import PropTypes from "prop-types";
import { H2, P } from "../../../../components";

const Div = styled.div`
  .staff-per-station-table {
    margin-bottom: 50px;
  }
`;

function StaffPerStation({
  intl,
  eventOffers,
  event,
  setEventOffersToBeSubmitted,
}) {
  const [offerRows, setOfferRows] = useState();
  const [selectedRowIndicies, setSelectedRowIndicies] = useState([]);

  function getTotalAttendance(offer) {
    const childAttendance = parseInt(offer.childAttendance);
    const adultAttendance = parseInt(offer.adultAttendance);

    return childAttendance + adultAttendance || 0;
  }

  useEffect(() => {
    if (!eventOffers || !event || !event.id || !eventOffers[event.id]) {
      return;
    }

    try {
      const newOffers = eventOffers[event.id]
        .filter((o) => o.status === "ACCEPTED")
        .map((o, i) => {
          const station = event.stations.find((s) => s.id === o.stationId);
          if (!station) return undefined;

          return {
            id: i,
            stationName: station.name,
            officialQuantity: parseInt(station.officialQuantity),
            groupName: o.group.name,
            signedUp: parseInt(o.volunteers),
            present: parseInt(getTotalAttendance(o)),
            groupId: o.group.groupId,
            stationId: station.id,
          };
        });
      setOfferRows(newOffers);
    } catch (e) {
      console.error(`Failed parsing number with error ${e}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (offerRows) {
      setEventOffersToBeSubmitted(
        offerRows
          .filter((or) => !selectedRowIndicies.includes(or.id))
          .map((o) => ({
            volunteers: o.signedUp,
            groupId: o.groupId,
            stationName: o.stationName,
          }))
      );
    }
  }, [offerRows, selectedRowIndicies]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRowIndicies([...selectedRowIndicies, row.id]);
    } else {
      setSelectedRowIndicies(selectedRowIndicies.filter((r) => r !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      setSelectedRowIndicies(ids);
    } else {
      setSelectedRowIndicies([]);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      sort: true,
    },
    {
      dataField: "stationName",
      text: intl.formatMessage(messages.station.workStation),
      headerStyle: {
        textAlign: "center",
      },
      sort: true,
    },
    {
      dataField: "officialQuantity",
      text: intl.formatMessage(messages.station.officialQuantity),
      headerStyle: {
        textAlign: "center",
      },
      sort: true,
    },
    {
      dataField: "groupName",
      text: intl.formatMessage(messages.group.title),
      headerStyle: {
        textAlign: "center",
      },
      sort: true,
    },
    {
      dataField: "signedUp",
      text: intl.formatMessage(messages.station.signedUp),
      headerStyle: {
        textAlign: "center",
      },
      sort: true,
    },
    {
      dataField: "present",
      text: intl.formatMessage(messages.station.present),
      headerStyle: {
        textAlign: "center",
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "stationName",
      order: "asc",
    },
  ];

  return (
    <Div>
      <H2>
        <FormattedMessage {...messages.staffPerStation.title} />
      </H2>
      <P>
        <FormattedMessage {...messages.staffPerStation.info} />
      </P>
      <br />
      <br />

      <BootstrapTable
        id="staff-per-station-table"
        classes="table-test staff-per-station-table"
        keyField="id"
        data={offerRows || []}
        columns={columns}
        wrapperClasses="table-responsive"
        hover
        selectRow={{
          mode: "checkbox",
          clickToSelect: true,
          selectColumnPosition: "right",
          selected: selectedRowIndicies,
          onSelect: handleOnSelect,
          onSelectAll: handleOnSelectAll,
        }}
        defaultSorted={defaultSorted}
      />
    </Div>
  );
}

StaffPerStation.propTypes = {
  onClickNext: PropTypes.func,
  event: PropTypes.object.isRequired,
};

export default injectIntl(StaffPerStation);

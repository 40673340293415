import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Input } from "../../../components/index";
import { SmallPinkButton, SmallGrayButton } from "../../../components/Buttons/index";
import { status } from "../../../auth/reducer";
import { required } from "../../../validators/index";
import Wrapper from "../Components/Wrapper";
import ButtonWrapper from "../Components/ButtonWrapper";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import { connect } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../constants/styling-constants";
import ReminderModal from "../../../components/ReminderModal";

const formId = "confirm-registration";

const StyledError = styled.p`
  color: ${colors.pink};
`;

class ConfirmRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReminderModal: true
    };
    this.hideReminderModal = this.hideReminderModal.bind(this);
    this.resendConfirmationCode = this.resendConfirmationCode.bind(this);
  }

  hideReminderModal()  {
    this.setState({
      showReminderModal: false
    });
  }

  renderError() {
    if (this.props.auth.status === status.VERIFYING_FAILED) {
      let message = messages.error[this.props.auth.error.code];
      if (!message) {
        message = messages.error.default;
        console.warn("label not found for error:", this.props.auth.error.code);
      }
      return <StyledError><FormattedMessage {...message} /></StyledError>;
    }
    return null;
  }

  renderActivationCodeError() {
    if (this.props.auth.activationCodeStatus === status.SENT_FAILED) {
      return <StyledError><FormattedMessage {...messages.error.activationCodeError} /></StyledError>;
    }
    return null;
  }

  resendConfirmationCode() {
    const { email } = this.props.fieldValues;
    this.props.resendConfirmationCode({ email });
  }

  render() {
    if (this.props.auth.status === status.VERIFYING_SUCCESS) {
      return <Redirect to="/login" />;
    }
    return (
      <Wrapper>
        <ReminderModal
          title={this.props.intl.formatMessage(messages.popupReminder.title)}
          text={this.props.intl.formatMessage(messages.popupReminder.body)}
          theme={'pink'}
          show={this.state.showReminderModal} 
          handleHide={() => this.hideReminderModal()} 
        />
        <form
          onSubmit={this.props.handleSubmit}
          noValidate
        >
          <Field
            name="email"
            placeholder={this.props.intl.formatMessage(messages.form.email)}
            type="email"
            component={Input}
            validate={[required]}
          />
          <Field
            name="verificationCode"
            placeholder={this.props.intl.formatMessage(messages.form.verificationCode)}
            type="text"
            component={Input}
            validate={[required]}
          />
          {this.renderError()}
          {this.renderActivationCodeError()}
          <ButtonWrapper>
            <SmallPinkButton
              type="submit"
              label={this.props.intl.formatMessage(messages.confirmRegistrationButtom)}
              busy={this.props.auth.status === status.VERIFYING}
            />
            <SmallGrayButton
              type="button"
              check={this.props.auth.activationCodeStatus === status.SENT}
              busy={this.props.auth.activationCodeStatus === status.SENDING}
              onClick={this.resendConfirmationCode}
              label={this.props.intl.formatMessage(messages.resendConfirmationCodeButton)}
            />
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  }
}

ConfirmRegistration.propTypes = {
  auth: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Redux form function that will call this.props.onSubmit if form is valid
  resendConfirmationCode: PropTypes.func.isRequired,
};

let ConfirmationRegistrationForm = reduxForm({
  form: formId,
})(injectIntl(ConfirmRegistration));

ConfirmationRegistrationForm = connect(
  state => ({
    initialValues: {
      email: state.register.email,// || state.forgotPassword.email || '',
    },
  })
)(ConfirmationRegistrationForm);

export default ConfirmationRegistrationForm;

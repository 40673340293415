import React from 'react';

// Higher order component to deal with storing id's in store and giving objects to Input
const withObjectValues = (Component) => (props) => {
  return (
    <Component
      {...props}
      input={{
        ...props.input,
        onBlur:() => props.input.onBlur(),
        onChange: (e) => {
          if (props.data[0] && props.data[0][props.valueField] !== undefined) {
            if(Array.isArray(e)) {
              props.input.onChange(e.map(v => v[props.valueField]));
            } else {
              props.input.onChange(e[props.valueField]);
            }
          } else {
            props.input.onChange(e);
          }
        },
        value: Array.isArray(props.input.value) ? props.data.filter(d => (props.input.value || []).includes(d[props.valueField])) : props.input.value === null || props.input.value === undefined ? null : props.valueField ? props.data.find(d => d[props.valueField] === props.input.value) : props.input.value,
      }}
    />
  );
};

export { withObjectValues };
import { defineMessages } from "react-intl";

export default defineMessages({
    title: {
        id: 'self-registration.title'
    },
    info: {
        eventName:{
            id: 'self-registration.info.event-name'
        },
        foodOptionsTitle: {
            id: 'self-registration.info.food-options-title'
        },
        shirtSizesTitle: {
            id: 'self-registration.info.shirt-sizes-title'
        },
    },
    form: {
        submitButton: {
            id: 'self-registration.form.submit-button'
        },
        age: {
            id: 'self-registration.form.age'
        },
        phone: {
            id: 'self-registration.form.phone'
        },
        email: {
            id: 'self-registration.form.email'
        },
        name: {
            id: 'self-registration.form.name'
        },
        emailAlreadyExistError: {
            id: 'self-registration.form.email-already-exists-error'
        },
        substituteTitle: {
            id: 'self-registration.form.substitute-title'
        },
        substituteOption: {
            id: 'self-registration.form.substitute-option'
        },
        substituteField: {
            id: 'self-registration.form.substitute.placeholder'
        }
    },
    confirmationModal: {
        confirmButton: {
            id: 'self-registration.confirmation-modal.confirm-button'
        },
        cancelButton: {
            id: 'self-registration.confirmation-modal.cancel-button'
        },
        text: {
            id: 'self-registration.confirmation-modal.text'
        },
        title: {
            id: 'self-registration.confirmation-modal.title'
        }
    }


})
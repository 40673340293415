import React, { Component } from "react";
import { ContentWrapper, Container, ProgressBar } from "../../../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import RegisterEvent from "../EventNew";
import StationNewBase from "../Station/StationNewBase";
import ConfirmEvent from "../ConfirmNewBase";
import messages from "../messages";
import { colors } from "../../../../constants/styling-constants";
import { FormattedMessage } from "react-intl";
import { eventStatus } from "../reducer";

const states = [
  {
    step: 0,
    name: <FormattedMessage {...messages.progressBar.registerEvent} />,
  },
  {
    step: 1,
    name: <FormattedMessage {...messages.progressBar.registerStation} />,
  },
  {
    step: 2,
    name: <FormattedMessage {...messages.progressBar.confirmEvent} />,
  },
];

class EventNewBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      clickedNext: false,
      nextStep: null,
    };

    this.goToStep = this.goToStep.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === eventStatus.SUCCESS && this.props.status === eventStatus.SUBMITTING && this.state.clickedNext) {
      this.goToStep(this.state.nextStep);
      this.setState({
        clickedNext: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  goToStep(step) {
    if (step >= 0 && step <= states.length) {
      this.setState({
        currentStep: step,
      });

      if (states[step] && states[step].link) {
        this.props.history.push(states[step].link);
      }
    }
  }

  handleNextClick(newEvent, step) {
    this.props.submitEvent(newEvent);
    this.setState({
      clickedNext: true,
      nextStep: step,
    });
  }

  renderPage() {
    switch (this.state.currentStep) {
      case 0:
        return <RegisterEvent {...this.props} initialValues={this.props.event}
                              onClickNext={this.handleNextClick}
        />;
      case 1:
        return <StationNewBase {...this.props} onClickNext={this.goToStep} />;
      case 2:
        return <ConfirmEvent {...this.props} onClickNext={this.handleNextClick} />;
      default:
        return <RegisterEvent {...this.props} onSubmit={this.handleEventSubmit} />;
    }
  }

  render() {
    return (
      <ContentWrapper>
        <Container>
          <Row>
            <Col sm={9}>
              <ProgressBar
                states={states}
                currentStep={this.state.currentStep}
                goToStep={this.goToStep}
                bottomBarBg={colors.mediumPink}
                topBarBg={colors.pink}
                stepNotActiveBorder={colors.mediumPink}
                stepNotActiveColor={colors.mediumPink}
                wrapperBorder={colors.pink}
                stepColor={colors.pink}
                wrapperBg={colors.lightPink}
              />
            </Col>
          </Row>
          {this.renderPage()}
        </Container>
      </ContentWrapper>
    );
  }
}

export default EventNewBase;

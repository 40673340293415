import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { SmallPinkButton } from "../../../../../components/Buttons";
import { statuses } from "../../../Offer/reducer";
import { Error } from "../../../../../components"
import styled from "styled-components";
import messages from "./messages"

const CustomError = styled(Error)`
  font-weight: normal;
  text-align: center;
  margin-top: 5px;
`;

const SentMessageLabel = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-left: -15px;
`

class SendInvitationButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sentToNone: false,
      numberSent: 0,
    }
  }

  onClick () {
    const { eventOffers, eventId, confirmStationOffers } = this.props;
    var numberSent = 0;
    this.setState({sentToNone: false, numberSent: 0})
    if (eventOffers && eventOffers[eventId]) {
	const offers = eventOffers[eventId].reduce((arr, offer) => {
		const { groupId, stationId } = offer
	        return offer.status === "DRAFT" ? arr.concat({groupId, stationId}) : arr
	}, [])
	confirmStationOffers({
	    offers: offers,
	    eventId: eventId
	})
	numberSent = offers.length
    }
    if (numberSent === 0) {
      this.setState({sentToNone: true})
    }
    else {
      this.setState({numberSent: numberSent})
    }
  }

  renderError () {
    const { intl, status } = this.props;
    if (status === statuses.SUBMIT_FAILED) {
      return <CustomError error={intl.formatMessage(messages.sendInvitation.error)} />
    }
    return null;
  }

  renderSuccess () {
    const { numberSent } = this.state;
    if (this.state.sentToNone === true) {
      return <SentMessageLabel><FormattedMessage {...messages.sendInvitation.noInvitationSent}/></SentMessageLabel>
    }
    if (numberSent !== 0) {//status === statuses.SUBMIT_SUCCESS) {
      return <SentMessageLabel>{numberSent} <FormattedMessage {...messages.sendInvitation.success}/></SentMessageLabel>
    }
    return null;
  }


  render () {
    const { intl, status } = this.props;
    return (
      <Fragment>
      <SmallPinkButton
        className="sendInvitation"
        label={intl.formatMessage(messages.sendInvitation.buttonText)}
        onClick={() => this.onClick()}
        busy={status === statuses.SUBMITTING}
        check={status === statuses.SUBMIT_SUCCESS}
      />
      {this.renderError()}
      {this.renderSuccess()}
    </Fragment>
    )
  }
}

// const SendInvitationButton = ({ status, eventId, intl, eventOffers, confirmStationOffer }) => {
//   const onClick = () => {
//     if (eventOffers && eventOffers[eventId]) {
//       for (const offer of eventOffers[eventId]) {
//         if (offer.status === "DRAFT") {
//           confirmStationOffer({
//             groupId: offer.groupId,
//             stationId: offer.stationId,
//             eventId: eventId
//           })
//         }
//       }
//     }
//   };

//   const renderError = () => {
//     if (status === statuses.SUBMIT_FAILED) {
//       return <CustomError error={intl.formatMessage(messages.sendInvitation.error)} />
//     }
//     return null;
//   }

//   const renderSuccess = () => {
//     if (status === statuses.SUBMIT_SUCCESS) {
//       return <SentMessageLabel><FormattedMessage {...messages.sendInvitation.success}/></SentMessageLabel>
//     }
//     return null;
//   }

//   return (
//     <Fragment>
//       <SmallPinkButton
//         className="sendInvitation"
//         label={intl.formatMessage(messages.sendInvitation.buttonText)}
//         onClick={onClick}
//         busy={status === statuses.SUBMITTING}
//         check={status === statuses.SUBMIT_SUCCESS}
//       />
//       {renderError()}
//       {renderSuccess()}
//     </Fragment>
//   );

// };

export default SendInvitationButton;


// actions
export const GROUP_SUBMIT = 'GROUP_SUBMIT';
export const GROUP_FETCHING = 'GROUP_FETCHING';
export const GROUP_FETCHED = 'GROUP_FETCHED';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';
export const GROUP_FAILED = 'GROUP_FAILED';
export const GROUP_RESET = 'GROUP_RESET';
export const GROUPS_SUMMARIES = 'GROUPS_SUMMARIES';
export const GROUPS_SUMMARIES_SUCCESS = 'GROUPS_SUMMARIES_SUCCESS';
export const GROUPS_SUMMARIES_FAILED = 'GROUPS_SUMMARIES_FAILED';
export const FETCH_PUBLIC_EVENTS = 'FETCH_PUBLIC_EVENTS';
export const FETCH_PUBLIC_EVENTS_SUCCESS = 'FETCH_PUBLIC_EVENTS_SUCCESS';
export const FETCH_PUBLIC_EVENTS_FAILED = 'FETCH_PUBLIC_EVENTS_FAILED';
export const FETCH_COMPLETE_EVENT = 'FETCH_COMPLETE_EVENT';
export const FETCH_COMPLETE_EVENT_SUCCESS = 'FETCH_COMPLETE_EVENT_SUCCESS';
export const FETCH_COMPLETE_EVENT_FAILED = 'FETCH_COMPLETE_EVENT_FAILED';
export const GROUP_CONFIRM = 'GROUP_CONFIRM';
export const GROUP_CONFIRM_SUCCESS = 'GROUP_CONFIRM_SUCCESS';
export const GROUP_CONFIRM_FAILED = 'GROUP_CONFIRM_FAILED';
export const LINK = 'LINK';
export const LINK_SUCCESS = 'LINK_SUCCESS';
export const LINK_FAILED = 'LINK_FAILED';


export const status = {
  INIT: 'INIT',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  SUBMITTING: 'SUBMITTING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const groupStatus = status;

export function submitGroup (data) {
  return {
    type: GROUP_SUBMIT,
    payload: data,
  };
}

export function fetchGroup (data) {
  return {
    type: GROUP_FETCHING,
    payload: data,
  };
}

export function confirmGroup (data) {
  return {
    type: GROUP_CONFIRM,
    payload: data,
  };
}

export function reset () {
  return {
    type: GROUP_RESET
  };
}

export function getGroupSummaries () {
  return {
    type: GROUPS_SUMMARIES,
  };
}

export function fetchPublicEvents (data) {
  return {
    type: FETCH_PUBLIC_EVENTS,
    payload: data,
  };
}

export function fetchCompleteEvent (data) {
  return {
    type: FETCH_COMPLETE_EVENT,
    payload: {
      id: data
    }
  }
}

export function createLink( data ) {
  return {
    type: LINK,
    payload: {
      id: data
    }
  }
}

// reducer with initial state
const initialState = {
  status: status.NOT_SUBMITTED,
  statusGroupSummaries: status.INIT,
  group: null,
  error: null,
  groupsSummaries: [],
  publicEvents: [],
  publicEventError: null,
  completeEvents: [],
  completeEventError: null
};

export default function group(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case GROUP_SUBMIT:
      return Object.assign({}, state, {status: status.SUBMITTING});
    case GROUP_CONFIRM:
      return Object.assign({}, state, {status: status.SUBMITTING});
    case GROUP_FETCHING:
      return Object.assign({}, state, {status: status.FETCHING});
    case GROUP_FETCHED:
      return Object.assign({}, state, {status: status.FETCHED, group: action.payload});
    case GROUP_SUCCESS:
      return Object.assign({}, state, {status: status.SUCCESS, group: action.payload});
    case GROUP_FAILED:
      return Object.assign({}, state, action.payload, {status: status.FAILED, error: action.payload});
    case GROUP_RESET:
      return Object.assign({}, initialState, {publicEvents: state.publicEvents});
    case GROUPS_SUMMARIES:
      return Object.assign({}, state, {statusGroupSummaries: status.FETCHING});
    case GROUPS_SUMMARIES_SUCCESS:
      return Object.assign({}, state, {groupsSummaries: action.payload, statusGroupSummaries: status.INIT});
    case GROUPS_SUMMARIES_FAILED:
      return Object.assign({}, state, {groupsSummaries: [], statusGroupSummaries: status.INIT});
    case FETCH_PUBLIC_EVENTS_SUCCESS:
      return Object.assign({}, state, {publicEvents: action.payload, publicEventError: null});
    case FETCH_PUBLIC_EVENTS_FAILED:
      return Object.assign({}, state, {publicEvents: [], publicEventError: action.error});
    case FETCH_COMPLETE_EVENT_SUCCESS:
      // Only add an event if it isn't stored already
      const existingEvent = state.completeEvents.find((e) => e.eventId === action.payload.eventId);

      if (existingEvent) {
        return state;
      }
      // Immutable copy of array of objects
      const completeEvents = state.completeEvents.map(e => {
        return Object.assign({}, e);
      });
      completeEvents.push(action.payload);
      return Object.assign({}, state, {completeEvents: completeEvents});
    case FETCH_COMPLETE_EVENT_FAILED:
      return Object.assign({}, state, {completeEvents: [], completeEventError: action.error});
    case GROUP_CONFIRM_SUCCESS:
      return Object.assign({}, state, {status: status.SUCCESS, group: action.payload});
    case LINK_SUCCESS:
      return Object.assign({}, state, {status: status.SUCCESS, link: action.payload});
    case LINK_FAILED:
      return Object.assign({}, state, {status: status.FAILED, link: action.payload});
    default:
      return state;
  }
}

import React from 'react';
import { Row, Col } from 'react-bootstrap/lib';
import {
  ContentWrapper,
  Container,
  H3,
} from '../../components/index';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';

const InfoSection = () => (
    <ContentWrapper>
      <Container>
        <Row>
          <Col sm={12} className="text-center">
            <H3><FormattedMessage {...messages.InfoSection.section1}/></H3>
            <H3><FormattedMessage {...messages.InfoSection.section2}/></H3>
            <H3><FormattedMessage {...messages.InfoSection.section3}/></H3>
            <H3><FormattedMessage {...messages.InfoSection.section4}/></H3>
            <H3><FormattedMessage {...messages.InfoSection.section5}/></H3>
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
);

export default injectIntl(InfoSection);

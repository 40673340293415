import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap/lib";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  H2,
  P,
  WhiteTableWithLastToRight,
  TableRowWithClick,
  GeneralError
} from "../../../../components/index";
import { TableButton } from "../../../../components/Buttons/index";
import moment from "moment";
import { withRouter } from "react-router";
import { statuses } from "../../Offer/reducer";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

const sortOnStartTime = (a, b) => {
  if (a.start < b.start)
    return -1;
  if (a.start > b.start)
    return 1;
  return 0;
};

const AttendanceListRow = ({ station, intl, history, match }) => {
  const onClick = () => {
    history.push(`${match.url}/${station.id}`);
  };

  const renderGroupNames = (station) => {
    if (!station.offers) {
      return "";
    }

    const names = station.offers
      .filter(o => o.status === "ACCEPTED")
      .map(o => o.group.name);

    return names.join(", ");
  };

  return (
    <TableRowWithClick
      key={station.id}
      row={station}
      callback={onClick}
    >
      <td>{station.name}</td>
      <td>{moment(station.start).format("HH:mm")}</td>
      <td>{renderGroupNames(station)}</td>
      <td className="hidden-xs"><TableButton label={intl.formatMessage(messages.attendanceList.showButton)} /></td>
    </TableRowWithClick>
  )

};

const AttendanceListRowWithRouter = withRouter(AttendanceListRow);

class AttendanceList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      stationsWithOffers: this.matchOfferWithStation(this.props.event.stations, this.props.eventOffers)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.eventOffers.hasOwnProperty(this.props.event.id) && this.props.eventOffers.hasOwnProperty(this.props.event.id)) {
      this.setState({
        stationsWithOffers: this.matchOfferWithStation(this.props.event.stations, this.props.eventOffers)
      });
    }
  }

  componentDidMount() {
    this.props.fetchEventOffers({ eventId: this.props.event.id });
  }

  matchOfferWithStation(stations, offers) {
    if (!offers.hasOwnProperty(this.props.event.id)) {
      return stations || [];
    }

    const stationsWithOffers = JSON.parse(JSON.stringify(stations));
    const eventOffers = offers[this.props.event.id].filter(o => stationsWithOffers.find(s => s.id === o.stationId));

    eventOffers.forEach(o => {
      const station = stationsWithOffers.find(s => s.id === o.stationId);
      if (!station.offers) {
        station.offers = [];
      }
      station.offers.push(o);
    });

    stationsWithOffers.sort(sortOnStartTime);

    return stationsWithOffers;
  }

  renderTable() {
    return (
      <WhiteTableWithLastToRight>
        <tbody>
          {this.state.stationsWithOffers.length === 0
            ? <tr>
              <td><FormattedMessage {...messages.attendanceList.noStations} /></td>
            </tr>
            : this.state.stationsWithOffers.map(station => <AttendanceListRowWithRouter
              key={station.id}
              station={station}
              intl={this.props.intl}
            />)}
        </tbody>
      </WhiteTableWithLastToRight>
    );
  }

  renderContent() {
    if (this.props.offerError) {
      return (
        <GeneralError>
          <FormattedMessage {...messages.attendanceList.offerError} />
        </GeneralError>
      )
    }

    if (this.props.offerStatus !== statuses.FETCHED) {
      return <i className="fas fa-spinner fa-pulse" />;
    }

    return this.renderTable();
  }

  render() {
    return (
      <Wrapper>
        <Row>
          <Col sm={12}>
            <H2><FormattedMessage {...messages.attendanceList.title} /></H2>
            <P><FormattedMessage {...messages.attendanceList.description} /></P>
            {this.renderContent()}
          </Col>
        </Row>

      </Wrapper>
    );
  }

}

export default injectIntl(AttendanceList);

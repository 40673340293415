import { connect } from "react-redux";
import TeamLeaderList from "./TeamLeaderList";
import { withRouter } from "react-router";
import { fetchTeamleaderEvents } from "../reducer";

const mapDispatchToProps = (dispatch) => ({
  fetchTeamleaderEvents: () => dispatch(fetchTeamleaderEvents()),
});

const mapStateToProps = (state) => ({
  eventsSummaries: state.teamleader.eventsSummaries,
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(TeamLeaderList));

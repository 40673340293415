import { defineMessages } from 'react-intl';

export default defineMessages({
  progressBar: {
    chooseEvent: {
      id: 'progress-bar.choose-event',
    },
    adjustEvent: {
      id: 'progress-bar.adjust-event',
    },
    registerEvent: {
      id: 'progress-bar.register-event',
    },
    registerStation: {
      id: 'progress-bar.register-station',
    },
    staffStation: {
      id: 'progress-bar.staff-station',
    },
    confirmEvent: {
      id: 'progress-bar.confirm-event',
    },
    newStations: {
      id: 'progress-bar.new-stations'
    }
  },
  title: {
    id: 'event-start.title',
  },
  text: {
    id: 'event-start.text',
  },
  newEventLink: {
    id: 'event-start.new-event-link',
  },
  copyEventLink: {
    id: 'event-start.copy-event-link',
  },
  searchVolunteerGroup: {
    id: 'event-start.search-volunteer-group-link',
  },
  copyEvent: {
    listTitle: {
      id: 'copy-event.list-title'
    },
    settingsTitle: {
      id: 'copy-event.settings-title'
    },
    stationsListTitle : {
      id: 'copy-event.stations-list-title'
    },
    nextButton: {
      id: 'copy-event.next-button'
    },
    saveButton: {
      id: 'copy-event.save-button'
    },
    choose: {
      id: 'copy-event.choose'
    },
    nextStep: {
      id: 'copy-event.next-step'
    },
    form: {
      title: {
        id: 'copy-event.form.title'
      },
      name: {
        id: 'copy-event.form.name'
      },
      date: {
        id: 'copy-event.form.date'
      },
      city: {
        id: 'copy-event.form.city'
      },
      startTime: {
        id: 'copy-event.form.startTime'
      }
    },
    sameNameModal: {
      title: {
        id: 'copy-event.same-name-modal.title'
      },
      text: {
        id: 'copy-event.same-name-modal.text'
      },
      cancel: {
        id: 'copy-event.same-name-modal.cancel'
      },
      confirm: {
        id: 'copy-event.same-name-modal.confirm'
      }
    }
  },
  myEvents: {
    title: {
      id: 'my-events.title',
    },
    show: {
      id: 'my-events.show-event-link',
    },
    showPrevious: {
      id: 'my-events.show-previous-events-link',
    },
    list: {
      noEventsMessage: {
        id: 'my-events.list.no-events',
      },
    },
    removalModal: {
      title: {
        id: 'my-events.removalModal.title'
      },
      body: {
        id: 'my-events.removalModal.body'
      }
    },
    removalCheckbox: {
      id: 'my-events.removalCheckbox'
    },
    header: {
      eventTitle: {
        id: 'my-events.header.title',
      },
      eventDate: {
        id: 'my-events.header.date',
      },
      eventStatus: {
        id: 'my-events.header.status',
      },
    },
  },
  registerEvent: {
    title: {
      id: 'register-event.page.title',
    },
    form: {
      event: {
        title: {
          id: 'register-event.event.form.title',
        },
        name: {
          id: 'register-event.form.event.name.placeholder',
        },
        date: {
          id: 'register-event.form.event.date.placeholder',
        },
        startTime: {
          id: 'register-event.form.event.startTime.placeholder',
        },
        city: {
          id: 'register-event.form.event.city.placeholder',
        },
      },
      substitute: {
        title: {
          id: 'register-event.form.substitute.title',
        },
        option: {
          id: 'register-event.form.substitute.option',
        },
      },
      include: {
        title: {
          id: 'register-event.include.form.title',
        },
        list: {
          clothesSize: {
            id: 'register-event.include.list.volunteer-clothes-size.placeholder',
          },
          foodOptions: {
            id: 'register-event.include.list.food-options.placeholder',
          },
          shoeSize: {
            id: 'register-event.include.list.shoe-size.placeholder',
          },
        },
        clothesSize: {
          title: {
            id: 'register-event.include.clothes-size.form.title',
          },
          placeholder: {
            id: 'register-event.form.include.clothes-size.placeholder',
          },
        },
        foodOptions: {
          title: {
            id: 'register-event.form.include.food-options.title',
          },
          placeholder: {
            id: 'register-event.form.include.food-options.placeholder',
          },
        },
        shoeSize: {
          title: {
            id: 'register-event.include.shoe-size.form.title',
          },
          placeholder: {
            id: 'register-event.form.include.shoe-size.placeholer',
          },
        },
      },
      organizer: {
        title: {
          id: 'register-event.organizer.form.title',
        },
        organizerNumber: {
          id: 'register-event.form.organizer.organizer-number.placeholder',
        },
        name: {
          id: 'register-event.form.organizer.name.placeholder',
        },
        email: {
          id: 'register-event.form.organizer.email.placeholder'
        },
        invoiceAddress: {
          id: 'register-event.form.organizer.invoice-address.placeholder'
        },
        website: {
          id: 'register-event.form.organizer.website.placeholder',
        },
      },
      contact: {
        title: {
          id: 'register-event.organizer.contact.form.title',
        },
        email: {
          id: 'register-event.form.organizer.contact.email.placeholder',
        },
        button: {
          id: 'register-event.form.organizer.contact.add-button',
        },
      },
    },
    contactList: {
      name: {
        id: 'register-station.list.contacts.name',
      },
      email: {
        id: 'register-station.list.contacts.email',
      },
      button: {
        id: 'register-station.list.contacts.add-button',
      },
    },
    deleteContactMessage: {
      id: 'register-event.my-station.delete.text',
    },
    saveEventButton: {
      id: 'register-event.save-button',
    },
    nextButton: {
      id: 'register-event.next-step-button',
    },
    contactEmptyMessage: {
      id: 'register-event.contact-field-empty-message',
    },
    contactExistMessage: {
      id: 'register-event.contact-already-exist-message',
    },
  },
  statusApproved: {
    id: 'event.status-approved',
  },
  statusUnconfirmed: {
    id: 'event.status-unconfirmed',
  },
  statusHistoric: {
    id: 'event.status-historic',
  },
  statusUnknown: {
    id: 'event.status-unknown',
  },
});

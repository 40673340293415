import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const H2 = styled.h2`
  color: ${colors.text};
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 35px;
`;

const H2Large = H2.extend`
  font-size: 35px;
`;

const H2LargeCenter = H2Large.extend`
  text-align: center;
`;

const H2WithButton = styled(H2)`
  margin-bottom: 40px;
  position: relative;
  .right-content {
    position: absolute;
    right: 0;
    top: -5px;
    text-align: right;
  }
  button {
    margin-top: 0;
    margin-right: 0;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    font-style: italic;
  }
`;

export {
  H2,
  H2Large,
  H2LargeCenter,
  H2WithButton
};

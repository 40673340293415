import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap/lib";
import { SmallGrayButton, DeleteButton } from "../Buttons/index";
import messages from "./messages";
import { status as userEmailStatus } from "../../containers/User/reducer";
import {
  FormSectionTitle,
  Input,
  FormGroup,
  GeneralError,
  ConfirmationModal,
  BorderTable,
} from "../index";
import { FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import AddUserForm from "./AddUserForm/index";
import ReactDOM from "react-dom";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

const DeleteRowButton = ({ row, callback }) => {
  const onClick = () => {
    callback(row);
  };

  return (
    <DeleteButton onClick={onClick} />
  );
};

class ContactForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showDeleteContactModal: false,
      deletingContactPerson: false,
      contactToDelete: null,
      newContactEmail: null,
      contactError: null,
      showAddUserModal: false,
    };

    this.onDeleteContact = this.onDeleteContact.bind(this);
    this.onConfirmDeleteContact = this.onConfirmDeleteContact.bind(this);
    this.renderContactsTableRow = this.renderContactsTableRow.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onContactFieldChange = this.onContactFieldChange.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
    this.toggleAddUserModal = this.toggleAddUserModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userEmail.status === "FETCHING" && this.props.userEmail.status === "FETCHED") {
      this.updateListFromRequest(this.props.userEmail.user);
    }
    if (this.props.userEmail.status === userEmailStatus.FAILED && prevProps.userEmail.status !== userEmailStatus.FAILED) {
      this.toggleAddUserModal();
    }
    if (this.props.newUserStatus === userEmailStatus.SUBMIT_SUCCESS && prevProps.newUserStatus !== userEmailStatus.SUBMIT_SUCCESS) {
      this.updateListFromRequest(this.props.newUser);
      this.toggleAddUserModal();
    }
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  toggleAddUserModal() {
    this.setState({ showAddUserModal: !this.state.showAddUserModal });
  }

  updateListFromRequest(user) {
    const contactList = JSON.parse(JSON.stringify(this.props.contactList));

    contactList.push({
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone || user.phoneNumber,
      id: contactList.length
    });

    this.props.change("contactPerson", "");

    this.props.updateContactList(contactList);
  }

  handleContactSubmit() {
    this.validateContact(this.props.contactList);
  }

  validateContact(contactList) {
    const listContainsSubmittedEmail = contactList.find(contact => contact.email === this.state.newContactEmail && !contact.toBeDeleted);

    if (!this.state.newContactEmail) {
      this.setState({
        contactError: <GeneralError><FormattedMessage {...messages.contactForm.contactEmptyMessage} /></GeneralError>,
      });
    } else if (listContainsSubmittedEmail) {
      this.setState({
        contactError: <GeneralError><FormattedMessage {...messages.contactForm.contactExistMessage} /></GeneralError>,
      });
    } else if (!this.state.emailInvalid) {
      this.setState({ contactError: null });
      this.props.getUserByEmail({ email: this.state.newContactEmail });
    }
  }

  toggleModal() {
    this.setState({
      showDeleteContactModal: !this.state.showDeleteContactModal,
    });
  }

  onDeleteContact(row) {
    this.setState({
      showDeleteContactModal: true,
      contactToDelete: row,
    });
  }

  onContactFieldChange(e, fieldValue) {
    this.setState({
      newContactEmail: fieldValue,
      emailInvalid: e.target.validity.typeMismatch,
      contactError: null,
    });
  }

  onConfirmDeleteContact() {
    const updatedContactList = this.props.contactList.slice();
    const indexToRemove = updatedContactList.indexOf(this.state.contactToDelete);

    updatedContactList.splice(indexToRemove, 1);
    updatedContactList.push(Object.assign({}, this.state.contactToDelete, { toBeDeleted: true }));

    this.setState({
      showDeleteContactModal: false,
      contactToDelete: null,
      deletingContactPerson: false,
    });

    this.props.updateContactList(updatedContactList);
  }

  renderContactsTableRow(row) {
    return (
      <tr key={row.email}>
        <td>{row.name}</td>
        <td>{row.email.toLowerCase()}</td>
        <td>{row.phone}</td>
        {!this.props.readonly && row.email !== this.props.user.email ? <td className="no-background"><DeleteRowButton
          row={row}
          callback={this.onDeleteContact}
        /></td> : null}
      </tr>
    );
  }

  renderContactList() {
    return (
      <Wrapper>
        <Row>
          <Col sm={12}>
            <BorderTable>
              <thead>
                <tr>
                  <th><FormattedMessage {...messages.contactForm.contactList.name} /></th>
                  <th><FormattedMessage {...messages.contactForm.contactList.email} /></th>
                  <th><FormattedMessage {...messages.contactForm.contactList.phone} /></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.contactList.filter(c => !c.toBeDeleted).map(this.renderContactsTableRow)}
              </tbody>
            </BorderTable>
          </Col>
        </Row>
        <ConfirmationModal
          show={this.state.showDeleteContactModal}
          handleConfirm={this.onConfirmDeleteContact}
          handleHide={this.toggleModal}
          text={this.props.intl.formatMessage(messages.contactForm.deleteText, { name: this.state.contactToDelete ? this.state.contactToDelete.name : "" })}
          busy={this.state.deletingContactPerson}
          theme="green"
        />
      </Wrapper>
    );
  }

  renderAddUserForm() {
    const el = document.getElementById("add-new-user");

    if (this.state.showAddUserModal && el) {
      // Render AddUserForm in Portal to keep it away from parent redux form which causes conflicts
      return ReactDOM.createPortal(
        <AddUserForm
          show={this.state.showAddUserModal}
          hide={this.toggleAddUserModal}
          intl={this.props.intl}
          newEmail={this.state.newContactEmail}
          type={this.props.type}
        />,
        el,
      );
    }
  }

  render() {
    const { readonly, intl } = this.props;

    return (
      <FormGroup>
        <FormSectionTitle><FormattedMessage {...messages.contactForm.title} /></FormSectionTitle>
        {!readonly ?
          <Fragment>
            <Field
              name="contactPerson"
              placeholder={intl.formatMessage(messages.contactForm.contact)}
              type="email"
              component={Input}
              onChange={this.onContactFieldChange}
              readonly={readonly}
            />
            {this.state.contactError}
          </Fragment>
          : null
        }
        {!readonly ?
          <SmallGrayButton
            type="button"
            onClick={this.handleContactSubmit}
            label={intl.formatMessage(messages.contactForm.addContactButton)}
            busy={this.props.userEmail.status === userEmailStatus.FETCHING}
          />
          :
          null
        }
        {this.renderContactList()}
        {this.renderAddUserForm()}
      </FormGroup>

    );
  }

}

ContactForm.propTypes = {
  contactList: PropTypes.array.isRequired,
  updateContactList: PropTypes.func.isRequired,
  getUserByEmail: PropTypes.func.isRequired,
  resetUser: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

const ContactFormForm = reduxForm({
  form: "contact-form",
})(ContactForm);

export default ContactFormForm;

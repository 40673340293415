import { call, put } from 'redux-saga/effects';
import { FETCH_CALENDAR_EVENTS_SUCCESS, FETCH_CALENDAR_EVENTS_FAILED } from './reducer';
import { getEventsByDateRange } from '../MyPages/Event/service';

export function* fetchCalendarEvents (action) {
  try {
    const response = yield call(() => getEventsByDateRange(action.payload));
    yield put({type: FETCH_CALENDAR_EVENTS_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: FETCH_CALENDAR_EVENTS_FAILED, payload: e});
  }
}

import { Auth } from 'aws-amplify';

// Auth.signIn stores token in Auth singleton, all API calls
// using API from aws-amplify will get right token automatically
export function login ({email, password}) {
  return Auth.signIn(email.toLowerCase(), password)
    .then((user) => {
      return user;
    });
}

export function confirmRegistration ({email, verificationCode}) {
  return Auth.confirmSignUp(email.toLowerCase(), verificationCode)
    .then((resp) => {
      return resp;
    });
}

export function resendConfirmationCode ({email}) {
  return Auth.resendSignUp(email.toLowerCase());
}

export function logout () {
  return Auth.signOut();
}

export function loadAuthenticatedUser () {
  return Auth.currentAuthenticatedUser();
}

export function loadUserInfo () {
  return Auth.currentUserInfo()
    .then(resp => ({
      firstName: resp.attributes.given_name,
      lastName: resp.attributes.family_name,
      email: resp.attributes.email.toLowerCase(),
      phone: resp.attributes.phone_number,
      emailVerified: resp.attributes.email_verified,
      phoneNumberVerified: resp.attributes.phone_number_verified,
    }));
}

export function updateUserAttributes ({firstName, lastName, countryCode, phoneWithoutCountryCode, email}) {
  const phoneWithCountryCode = countryCode + ((phoneWithoutCountryCode.substr(0,1) === '0') ? phoneWithoutCountryCode.substr(1) : phoneWithoutCountryCode);

  return Auth.currentUserPoolUser()
    .then(user => Auth.updateUserAttributes(user, {
      given_name: firstName,
      family_name: lastName,
      phone_number: phoneWithCountryCode,
      email: email.toLowerCase()
    }));
}

export function changePassword ({user, newPassword}) {
  return Auth.completeNewPassword(user, newPassword)
    .then(user => {
      return user;
    });
}

import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap/lib";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import { Field, reduxForm } from "redux-form";
import { BigGreenButton } from "../../../../components/Buttons/index";
import { groupStatus } from "../reducer";
import {
  P,
  Input,
  ConfirmationModal,
} from "../../../../components";

class GroupConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      confirming: false,
      groupStatus: this.props.group ? this.props.group.status : {},
    };

    this.handleConfirm = this.handleConfirm.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }

  componentDidMount() {
    if (this.props.location && this.props.location.pathname === "/my-pages/volunteers/new") {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === groupStatus.SUCCESS && prevProps === groupStatus.SUBMITTING && this.state.confirming) {
      this.toggleModal();
      this.setState({
        confirming: false,
        groupStatus: this.props.group.status,
      });
    }

    if (prevProps.group.status !== "CONFIRMED" && this.props.group.status === "CONFIRMED") {
      this.setState({
        confirming: false,
        showModal: false,
        groupStatus: this.props.group ? this.props.group.status : {},
      });

      const url = `/my-pages/volunteers/${this.props.group.groupId}/confirm`;
      if (url !== this.props.history.location.pathname) {
        this.props.history.push(`/my-pages/volunteers/${this.props.group.groupId}/confirm`);
      }
    }
  }

  handleConfirm() {
    this.setState({
      confirming: true,
    });

    this.props.confirmGroup(this.props.group);
  }

  toggleModal(e) {
    e && e.preventDefault();
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  onClickNext() {
    this.props.onClickNext(2);
  }

  render() {
    return (
      <Fragment>
        <form>
          <Row>
            <Col sm={7}>
              <Field
                name="numberOfMembers"
                placeholder={this.props.intl.formatMessage(messages.group.numberOfMembers)}
                type="number"
                component={Input}
                readonly
              />
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <P><FormattedMessage {...messages.confirmText2} /></P>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              {this.props.isNew ? <BigGreenButton
                type="button"
                busy={this.state.clickedNext}
                onClick={this.onClickNext}
                label={this.props.intl.formatMessage(messages.nextButton)}
              /> : null}
            </Col>
          </Row>
        </form>
        <ConfirmationModal
          label={this.props.intl.formatMessage(messages.cancelConfirmButton)}
          show={this.state.showModal}
          handleConfirm={this.handleConfirm}
          handleHide={this.toggleModal}
          buttonText={this.props.intl.formatMessage(messages.confirmModalButton)}
          title={this.props.intl.formatMessage(messages.confirmationModalTitle)}
          text={
            <FormattedMessage {...messages.confirmationModalText} values={{ name: this.props.group ? this.props.group.name : "" }} />}
          busy={this.state.confirming}
          theme="green"
        />
      </Fragment>
    );
  }
}

const GroupConfirmForm = reduxForm({
  form: "group-confirm",
})(GroupConfirm);

export default injectIntl(GroupConfirmForm);

import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const H3 = styled.h3`
  color: ${colors.text};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const H3Large = H3.extend`
  font-size: 35px;
`;

const H3LargeCenter = H3Large.extend`
  text-align: center;
`;

export {
  H3,
  H3Large,
  H3LargeCenter
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  modal: {
    newVolunteers: {
      id: 'group.volunteer.modal.newVolunteers'
    },
    newVolunteersCreate: {
	id: 'group.volunteer.modal.newVolunteersCreate'
    },
    missingSpecialInfo: {
      id: 'group.volunteer.modal.missingSpecialInfo'
    },
  },
  attendees: {
    title: {
      id: 'group.volunteer.form.list.title'
    },
    name: {
      id: 'group.volunteer.form.name.placeholder',
    },
    email: {
      id: 'group.volunteer.form.email.placeholder',
    },
    phone: {
      id: 'group.volunteer.form.phone.placeholder',
    },
    age: {
      id: 'group.volunteer.form.age.placeholder',
    },
    notEnoughAttendee: {
      id: 'group.volunteer.form.notEnoughAttendee'
    },
    addButton: {
      id: 'group.volunteer.form.addButton'
    },
    createButton: {
      id: 'group.volunteer.form.createButton'
    },
    submitButton: {
      id: 'group.volunteer.form.submitButton'
    },
    closeButton: {
      id: 'group.volunteer.popUp.closeButton'
    },
    specialDiets: {
      title: {
        id: 'group.volunteer.list.special-diets.title'
      },
      lactoseFree: {
        id: 'group.volunteer.form.special-diets.lactose-free.placeholder',
      },
      glutenFree: {
        id: 'group.volunteer.form.special-diets.gluten-free.placeholder',
      },
      vegetarian: {
        id: 'group.volunteer.form.special-diets.vegetarian.placeholder',
      },
      vegan: {
        id: 'group.volunteer.form.special-diets.vegan.placeholder',
      },
    },
    shirtSize: {
      id: 'group.volunteer.form.shirt-size.placeholder',
    },
    substitute: {
      id: 'group.engagement.volunteer.form.substitute'
    },
  },
  form: {
    name: {
      id: 'engagement-proposal.form.station-name.placeholder'
    },
    date: {
      id: 'engagement-proposal.form.date.placeholder',
    },
    start: {
      id: 'engagement-proposal.form.start-time.placeholder',
    },
    end: {
      id: 'engagement-proposal.form.end-time.placeholder',
    },
    officialQuantity: {
      id: 'engagement-proposal.form.official-quantity.placeholder',
    },
    description: {
      id: 'engagement-proposal.form.description.placeholder',
    },
    paymentType: {
      id: 'engagement-proposal.form.payment-type.placeholder',
    },
    paymentAdult: {
      id: 'engagement-proposal.form.payment-adult.placeholder',
    },
    paymentChild: {
      id: 'engagement-proposal.form.payment-child.placeholder',
    },
    groupPayment: {
      id: 'engagement-proposal.form.group-payment.placeholder',
    },
    requiredSkills: {
      id: 'engagement-proposal.form.skills.placeholder'
    }
  },
  confirmTitle: {
    id: 'engagement-proposal.title',
  },
  confirmText: {
    id: 'engagement-proposal.info',
  },
  confirmText2: {
    id: 'engagement-proposal.info.2',
  },
  confirmText3: {
    id: 'engagement-proposal.info.3',
  },
  confirmButton: {
    id: 'engagement-proposal.confirm-button',
  },
  rejectButton: {
    id: 'engagement-proposal.reject-button',
  },
  confirmModalText: {
    id: 'engagement-proposal.modal-confirm-text',
  },
  confirmModalTitle: {
    id: 'engagement-proposal.modal-confirm.-title',
  },
  confirmModalCancelButton: {
    id: 'engagement-proposal.modal-confirm.cancel-button',
  },
  confirmModalRejectButton: {
    id: 'engagement-proposal.modal-confirm.confirm-button',
  },
  rejectModalText: {
    id: 'engagement-proposal.modal-reject-text',
  },
  rejectModalCancelButton: {
    id: 'engagement-proposal.modal-reject.cancel-button',
  },
  rejectModalRejectButton: {
    id: 'engagement-proposal.modal-reject.reject-button',
  },
  rejectModalTitle: {
    id: 'engagement-proposal.modal-reject.-title',
  },
  offerConfirmedText: {
    id: 'engagement-proposal.confirmed-text',
  },
  offerRejectedText: {
    id: 'engagement-proposal.rejected-text',
  }
});

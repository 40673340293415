import React from 'react';
import { Container } from '../../components';
import { NavLinkButton, GreenNavLinkButton } from '../../components/Links';
import { Row, Col } from 'react-bootstrap/lib';
import messages from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';

const Wrapper = Container.extend`
  margin-bottom: 70px;
`;

const TopNavigation = () => (
  <Wrapper>
    <Row>
      <Col sm={12}>
        <NavLinkButton to="/my-pages/events"><FormattedMessage {...messages.eventsLink} /></NavLinkButton>
        <NavLinkButton to="/my-pages/teamleaders"><FormattedMessage {...messages.teamLeadersLink} /></NavLinkButton>
        <GreenNavLinkButton to="/my-pages/volunteers"><FormattedMessage {...messages.volunteersLink} /></GreenNavLinkButton>
      </Col>
    </Row>
  </Wrapper>
);

export default injectIntl(TopNavigation);

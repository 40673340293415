import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SmallGrayButton } from '../../../../../components/Buttons';
import { statuses } from '../../../Offer/reducer';
import { Error } from '../../../../../components'
import styled from 'styled-components';
import messages from './messages';

const CustomError = styled(Error)`
  font-weight: normal;
  text-align: center;
  margin-top: 5px;
`;

const SentMessageLabel = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-right: -15px;
`

const SendReminderButton = ({status, sendOfferReminder, sendOfferReminderGroups, eventId, intl}) => {
  const onClick = () => {
    sendOfferReminder({eventId});
  };

  const renderError = () => {
    if (status === statuses.SUBMIT_FAILED) {
      return <CustomError error={intl.formatMessage(messages.sendReminder.error)}/>
    }

    return null;
  }

  const renderSuccess = () => {
    if (status === statuses.SUBMIT_SUCCESS) {
      if (sendOfferReminderGroups.length > 0) {
        const groups = sendOfferReminderGroups.map(s => s.group.name);

        return <p><FormattedMessage {...messages.sendReminder.success} values={{groups: groups.join(', ')}}/></p>
      } else {
        return <SentMessageLabel><FormattedMessage {...messages.sendReminder.noGroupsMatched}/></SentMessageLabel>
      }
    }

    return null;
  }

  return (
    <div className='right-content'>
      <SmallGrayButton
        className="sendReminder"
        label={<FormattedMessage {...messages.sendReminder.buttonText} />} 
        onClick={onClick} 
        busy={status === statuses.SUBMITTING}
        check={status === statuses.SUBMIT_SUCCESS}
      />
      {renderError()}
      {renderSuccess()}
    </div>
  );
};

export default SendReminderButton;
import React, { Fragment } from "react";
import styled from "styled-components";
import { SmallError } from "./Error";
import { colors } from "../../constants/styling-constants";
import { TextLabel, P } from "../index";
import { Row, Col } from "react-bootstrap/lib";

const StyledInput = styled.input`
  font-size: 17px;
  padding: 13px 15px;
  background-color: #FFF;
  width: 100%;
  margin-bottom: 15px;
  border: none;
  outline: none;
  &::placeholder {
    color: ${colors.lightGray4};
  }
  &.has-error {
    margin-bottom: 5px;
    background-color: ${colors.lightPink2}
    &::placeholder {
      color: ${colors.pink};
    }
  }
  &.border {
    border: 1px solid ${colors.lightGray4};
    border-radius: 3px;
  &:disabled {
    background-color: ${colors.lightGray3};
    color: ${colors.mediumLightGray};
  }
`;

const Input = (ErrorComponent = SmallError) => ({ className = "", initialValue, input, placeholder, min, type, disabled, meta: { touched, error, warning }, readonly = false }) => {
  if (readonly) {
    return (
      <Row>
        <Col md={5}>
          <TextLabel>{placeholder}</TextLabel>
        </Col>
        <Col md={7}>
          <P>{initialValue ? initialValue : input.value}</P>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <StyledInput
        className={`${className} ${touched && error ? "has-error" : ""}`}
        placeholder={placeholder}
        {...input}
        disabled={disabled}
        type={type || "text"}
        min={type === "number" ? min : null}
      />
      {touched &&
      ((error && <ErrorComponent error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

const SmallInput = styled(Input(SmallError))`
  font-size: 12px;
  padding: 9px 11px;
  margin-bottom: 11px;
`;

export default Input();
export {
  SmallInput
};

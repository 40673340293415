import React, { Fragment } from "react";
import { compose } from "redux"
import { DropdownList } from "react-widgets";
import styled from "styled-components";
import { colors } from "../../constants/styling-constants";
import { SmallError } from "./Error";
import { TextLabel, P } from "../index";
import { Row, Col } from "react-bootstrap/lib";
import messages from "./messages";
import { injectIntl } from "react-intl";
import { withObjectValues } from "./selectValuesHoc";

const StyledSelect = styled(DropdownList)`
  font-size: 17px;
  margin-bottom: 15px;
  .rw-widget-picker {
    background-color: #FFF;
    width: 100%;
    border: none;
    border-radius: 0;
  }
  .rw-widget-input {
    height: 46px;
  }
  .rw-input {
    outline: none;
    padding: 15px;
  }
  button {
    background-color: ${colors.gray};
  }
  .rw-i {
    color: #FFF;
  }
  .rw-placeholder {
    color: ${colors.lightGray4};
  }
  .rw-filter-input {
    width: inherit;
  }
  &.has-error {
    margin-bottom: 5px;
    .rw-input {
      background-color: ${colors.lightPink2};
    }
    .rw-placeholder {
      color: ${colors.pink};
    }
  }
  &.border {
    border: 1px solid ${colors.lightGray4};
  }
`;

const selectMessages = formatMessage => ({
  emptyFilter: formatMessage(messages.select.messages.emptyFilter),
  emptyList: formatMessage(messages.select.messages.emptyList),
});

const SelectComponent = (ErrorComponent = SmallError) => ({ className = "", input, readonly = false, data = [], intl, valueField, filter, placeholder, textField, meta: { touched, error, warning } }) => {
  if (readonly) {
    const item = typeof input.value === "object" && input.value !== null ? data.find(d => d.id === input.value.id) : data.find(d => d.id === input.value);
    return (
      <Row>
        <Col md={5}>
          <TextLabel>{placeholder}</TextLabel>
        </Col>
        <Col md={7}>
          <P>{item ? item.name : input.value}</P>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <StyledSelect
        {...input}
        data={data}
        filter={filter}
        placeholder={placeholder}
        messages={selectMessages(intl.formatMessage)}
        valuedField={valueField}
        textField={textField}
        className={`${className} ${touched && error ? "has-error" : ""}`}
      />
      {touched &&
      ((error && <ErrorComponent error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

const SmallSelect = styled(SelectComponent(SmallError))`
  font-size: 12px;
  min-width: 135px;
  margin-bottom: 11px;
  .rw-widget-input {
    height: 32px;
  }
`;

const IntlSmallSelect = withObjectValues(injectIntl(SmallSelect));

export default compose(withObjectValues, injectIntl)(SelectComponent())

export {
  IntlSmallSelect as SmallSelect
};

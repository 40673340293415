import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    noMailSent: {
      id: "station-allocation.table.header.noMailSent"
    },
    lastReminder: {
      id: "station-allocation.table.header.lastReminderSent"
    },
    stations: {
      id: "station-allocation.table.header.stations",
    },
  },
  station: {
    workStation: {
      id: `station-allocation.table.workStation`
    },
    officialQuantity: {
      id: "station-allocation.table.officialQuantity",
    },
    officialsAllocated: {
      id: "station-allocation.table.officialsAllocated",
    },
    officialsProposed: {
      id: "station-allocation.table.officialsProposed",
    },
    officialPreliminary: {
      id: "station-allocation.table.officialPreliminary",
    },
    officialsAwaitingAnswer: {
      id: "station-allocation.table.officialsAwaitingAnswer",
    },
    officialsAccepted: {
      id: "station-allocation.table.officialsAccepted",
    },
    officialsMissing: {
      id: "station-allocation.table.officialsMissing",
    },
    officialsNotAllocated: {
      id: "station-allocation.table.officialsNotAllocated",
    },
    officialMinimumAge: {
      id: "station-allocation.table.age-limit",
    },
    skills: {
      id: "station-allocation.table.skills",
    },
    noSkills: {
      id: "station-allocation.table.noSkills",
    },
  },
  groupTable: {
    header: {
      groupName: {
        id: "station-allocation.table.header.groupName",
      },
      requestedQuantity: {
        id: "station-allocation.table.header.requestedQuantity",
      },
      ages: {
        id: "station-allocation.table.header.ages",
      },
      skills: {
        id: "station-allocation.table.header.skills",
      },
    },
    draft: {
      id: "station-allocation.table.status-draft",
    },
    proposal: {
      id: "station-allocation.table.status-proposal",
    },
    accepted: {
      id: "station-allocation.table.status-accepted",
    },
    rejected: {
      id: "station-allocation.table.status-rejected",
    }
  },
  addGroup: {
    title: {
      id: "station-allocation.addGroup.title",
    },
    button: {
      id: "station-allocation.addGroup.button",
    },
    selectGroup: {
      id: "station-allocation.addGroup.selectGroup.placeholder",
    },
    requestedQuantity: {
      id: "station-allocation.addGroup.requestedQuantity",
    },
    groupSummary: {
      title: {
        id: "station-allocation.addGroup.groupSummary.title",
      },
      name: {
        id: "station-allocation.addGroup.groupSummary.name",
      },
      ages: {
        id: "station-allocation.addGroup.groupSummary.ages",
      },
      members: {
        id: "station-allocation.addGroup.groupSummary.members",
      },
      sports: {
        id: "station-allocation.addGroup.groupSummary.sports",
      },
      skills: {
        id: "station-allocation.addGroup.groupSummary.skills",
      },
    },
    toManyRequestedError: {
      id: "station-allocation.addGroup.to-many-requested-error",
    },
    allocatedError: {
      id: "station-allocation.addGroup.allocated-error",
    },
    createOfferError: {
      id: "station-allocation.addGroup.create-offer-error",
    }
  },
  offers: {
    groupName: {
      id: "station-allocation.offer.groupName",
    },
    requestedQuantity: {
      id: "station-allocation.offer.requestedQuantity",
    },
    status: {
      id: "station-allocation.offer.status",
    },
  },
  saveButton: {
    id: "station-offer.send-button",
  },
  confirmModalTitle: {
    id: "station-offers.confirm-delete-group-offer-modal.title",
  },
  confirmModalText: {
    id: "station-offers.confirm-delete-group-offer-modal.text",
  },
  confirmModalButton: {
    id: "station-offers.confirm-delete-modal.confirm-button",
  },
  cancelConfirmModalButton: {
    id: "station-offers.confirm-delete-modal.cancel-button",
  },
  notConfirmedWarning: {
    id: "station-offers.not-confirmed-warning",
  },
  historicWarning: {
    id: "station-offers.historic-warning",
  },
});

import React, { Fragment } from "react";
import styled from "styled-components";
import { colors, buttonStyles } from "../../constants/styling-constants";
import { GrayCircleIcon } from "../Icons/index";

const commonStyles = {
  fontSize: "18px",
  color: "#FFF",
  padding: "30px 70px",
  borderRadius: "5px",
};

const ButtonTemplate = ({ label, onClick, icon = null, busy = false, check = false, disabled = false, type = "submit", className, children, style }) => (
  <button
    disabled={disabled}
    onClick={onClick}
    type={type}
    className={className}
    style={style}
  >
    {icon && !busy ? <i className={`fas ${icon}`} /> : null}
    {busy ? <i className="fas fa-spinner fa-pulse" /> : null}
    {check ? <i className="fas fa-check" /> : null}
    {children && !label ? children : <Fragment>{label}</Fragment>}
  </button>
);

const Button = styled(ButtonTemplate)`
  display: inline-block;
  margin-right: 55px;
  margin-bottom: 40px;
  font-size: ${buttonStyles.fontSize};
  color: ${buttonStyles.color};
  padding: 10px 21px;
  vertical-align: middle;
  background-color: ${colors.pink};
  background: linear-gradient(${colors.pink}, ${colors.pinkEndGradient});
  border-radius: ${buttonStyles.borderRadius};
  border: none;
  display: inline-block;
  outline: none;
  width: 196px;
  height: 80px;
  &:disabled {
    background: linear-gradient(${colors.mediumPink}, ${colors.mediumPink});
    &:hover {
     background: linear-gradient(${colors.mediumPink}, ${colors.mediumPink});
    }
  }
  i {
    margin-right: 8px;
  }
  // TODO: Add transition for hover effect
  &:hover {
    background: linear-gradient(${colors.darkPink}, ${colors.pinkEndGradient});
  }
`;

const BigGreenButton = styled(Button)`
  background: ${colors.green};
  background: linear-gradient(${colors.green}, ${colors.greenEndGradient});
  &:hover {
    background: linear-gradient(${colors.darkGreen}, ${colors.greenEndGradient});
  }
  &:disabled {
    background: linear-gradient(${colors.lighterGreen}, ${colors.lighterGreen});
    &:hover {
     background: linear-gradient(${colors.lighterGreen}, ${colors.lighterGreen});
    }
  }
`;

const BigGrayButton = styled(Button)`
  background: ${colors.gray};
  background: linear-gradient(${colors.grayStartGradient}, ${colors.gray});
  &:hover {
    background: linear-gradient(${colors.gray}, ${colors.gray});
  }
  &:disabled {
    background: linear-gradient(${colors.lightGray4}, ${colors.lightGray4});
    &:hover {
     background: linear-gradient(${colors.lightGray4}, ${colors.lightGray4});
    }
  }
`;

const TableButton = styled(ButtonTemplate)`
  display: inline-block;
  color: #FFF;
  background-color: ${colors.gray};
  background: linear-gradient(${colors.grayStartGradient}, ${colors.gray});
  border: ${colors.gray};
  outline: none;
  font-size: 12px;
  padding: 7px 13px;
  // TODO: Add transition for hover effect
  &:hover {
    background: linear-gradient(${colors.gray}, ${colors.gray});
  }
`;

const StyledToggleButton = styled.button`
  display: inline-block;
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  margin: 25px 0;
  span:first-child {
    margin-right 13px;
  }
`;

const ToggleButton = ({ label, callback, icon }) => (
  <StyledToggleButton
    type="button"
    onClick={callback}
  >
    <GrayCircleIcon icon={icon} />

    <Fragment>{label}</Fragment>
  </StyledToggleButton>
);

const SmallGrayButton = styled(ButtonTemplate)`
  font-size: 14px;
  color: ${commonStyles.color};
  border-color: transparent;
  border: none;
  padding: 6px 20px;
  vertical-align: middle;
  background-color: ${colors.gray};
  background: linear-gradient(${colors.grayStartGradient}, ${colors.gray});
  border-radius: ${commonStyles.borderRadius};
  display: inline-block;
  margin-top: 20px;
  margin-right: 20px;
  text-align: center;
  outline: none;
  height: 40px;
  width: 196px;
  &.sendReminder {
    margin-top: 0px;
    margin-right: -15px;
  }
  &.submit {
    margin-top: 0px;
    margin-right: 0px;
  }
  &.rightPos {
    float: right;
  }
  i {
    margin-right: 8px;
  }
  // TODO: Add transition for hover effect
  &:hover {
    background: linear-gradient(${colors.gray}, ${colors.gray});
  }
  &:disabled {
    background: linear-gradient(${colors.lightGray4}, ${colors.lightGray4});
    &:hover {
     background: linear-gradient(${colors.lightGray4}, ${colors.lightGray4});
    }
  }
`;

const SmallPinkButton = styled(SmallGrayButton)`
  background-color: ${colors.pink};
  background: linear-gradient(${colors.pink}, ${colors.pinkEndGradient});
  border: none;
  &:disabled {
    background: linear-gradient(${colors.mediumPink}, ${colors.mediumPink});
    &:hover {
     background: linear-gradient(${colors.mediumPink}, ${colors.mediumPink});
    }
  }
  // TODO: Add transition for hover effect
  &:hover {
    background: linear-gradient(${colors.darkPink}, ${colors.pinkEndGradient});
  }
  &.sendInvitation {
    margin-top: 0px;
    margin-left: -15px;
    margin-right: 0px;
  }
`;

const SmallGreenButton = styled(SmallGrayButton)`
  background-color: ${colors.green};
  background: linear-gradient(${colors.green}, ${colors.greenEndGradient});
  border: none;
  &:disabled {
    background: linear-gradient(${colors.lighterGreen}, ${colors.lighterGreen});
    &:hover {
     background: linear-gradient(${colors.lighterGreen}, ${colors.lighterGreen});
    }
  }
  // TODO: Add transition for hover effect
  &:hover {
    background: linear-gradient(${colors.darkGreen}, ${colors.greenEndGradient});
  }
`;

const MediumGrayButton = SmallGrayButton.extend`
  padding: 0;
  font-size: 16px;
  height: 40px;
  width: 196px;
  border: none;
`;

const MediumPinkButton = Button.extend`
  padding: 0;
  font-size: 16px;
  height: 40px;
  width: 196px;
`;

const MediumButton = MediumGrayButton.extend`
  background-color: ${props => props.bgColor};
   background: linear-gradient(${props => props.startGradientColor}, ${props => props.endGradientColor});
  &:hover {
    background: linear-gradient(${props => props.hoverStartGradientColor}, ${props => props.hoverEndGradientColor});
  }
`;

const DeleteButtonTemplate = ({ onClick, className, type = "button" }) =>
  // TODO: Change to light version in FontAwesome Pro
  (
    <button
      onClick={onClick}
      className={className}
      type={type}
    >
      <i className="fas fa-times-circle" />
    </button>
  )
;

const DeleteButton = styled(DeleteButtonTemplate)`
  font-size: 20px;
  border: none;
  background-color: transparent;
  color: ${colors.gray};
  display: inline-block;
  padding: 0;
  text-align: center;
  outline: none;
  &.hidden2 {
    visibility: hidden;
  }
`;

const TransparentButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: none;
`;

const removeMargin = component => styled(component)` margin: 0; `;

export {
  Button,
  SmallGrayButton,
  SmallPinkButton,
  SmallGreenButton,
  TableButton,
  MediumButton,
  ToggleButton,
  BigGrayButton,
  BigGreenButton,
  DeleteButton,
  MediumGrayButton,
  MediumPinkButton,
  removeMargin,
  TransparentButton
};

import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.status === "LOGGED_IN") {
        return <Component {...props} />;
      } else if (auth.status === "PENDING") {
        return null;
      } else {
        return <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />;
      }
    }
    }
  />
);

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);

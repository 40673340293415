import React, { Component } from "react";
import moment from "moment";
import {
  BorderTable,
  FormGroup,
  H2,
  P,
} from "../../../../../components";
import messages from "../messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { status, fetchStatus } from "../reducer";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

const TableRowWithClick = styled("tr")`
  cursor: pointer;
`;

class SendOutsList extends Component {

  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchSendOuts(this.props.event.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.status === status.SEND_SUCCESS && prevProps.status !== status.SEND_SUCCESS) {
      this.props.fetchSendOuts(this.props.event.id);
    }
  }

  onRowClick(sendOut) {
    const linkBase = this.props.isTeamleaderView ? "/my-pages/teamleaders" : "/my-pages/events";
    this.props.history.push(`${linkBase}/${this.props.event.id}/sendouts/${sendOut.sendOutId}`);
  }

  renderSpinner() {
    if (this.props.fetchStatus === fetchStatus.FETCHING) {
      return (<i className="fas fa-spinner fa-pulse" />);
    }

    return null;
  }

  renderTable() {
    if (this.props.fetchStatus === fetchStatus.FETCH_SUCCESS && (!this.props.sendOuts || this.props.sendOuts.length === 0)) {
      return (<P><FormattedMessage {...messages.list.noSendOuts} /></P>);
    }

    if (!this.props.sendOuts) {
      return null;
    }

    return (
      <BorderTable>
        <thead>
          <tr>
            <th><FormattedMessage {...messages.list.created} /></th>
            <th><FormattedMessage {...messages.list.subject} /></th>
          </tr>
        </thead>
        <tbody>
          {this.props.sendOuts.map((s, index) => {
            return (
              <TableRowWithClick
                key={index}
                onClick={() => this.onRowClick(s)}
              >
                <td>{moment(s.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                <td>{s.subject}</td>
              </TableRowWithClick>
            )
          })}
        </tbody>
      </BorderTable>
    )
  }

  render() {
    return (
      <FormGroup>
        <H2><FormattedMessage {...messages.list.title} /></H2>
        {this.renderSpinner()}
        {this.renderTable()}
      </FormGroup>
    );
  }
}

export default withRouter(injectIntl(SendOutsList));

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'group-availability.title'
  },
  loading: {
    id: 'commmon.loading.message'
  },
  addForm: {
    title: {
      id: 'group-availability.add-form.title'
    },
    submit: {
      id: 'group-availability.add-form.submit'
    }
  },
  table: {
    type: {
      id: 'group-availability.table.header.type',
    },
    location: {
      id: 'group-availability.table.header.location',
    },
    period: {
      id: 'group-availability.table.header.period',
    },
    event: {
      id: 'group-availability.table.header.event',
    },
    input: {
      type: {
        id: 'group-availability.table.input.type',
      },
      types: {
        period: {
          id: 'group-availability.table.input.type.PERIOD',
        },
        event: {
          id: 'group-availability.table.input.type.EVENT',
        },
      },
      location: {
        id: 'group-availability.table.input.location',
      },
      startDate: {
        id: 'group-availability.table.input.startDate',
      },
      endDate: {
        id: 'group-availability.table.input.endDate',
      },
      event: {
        id: 'group-availability.table.input.event',
      },
    },
    newRow: {
      id: 'group-availability.table.addAvailability',
    },
  },
  saveButton: {
    id: 'group-availability.save-button',
  },
});


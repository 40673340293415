/*
 * User for checking if user exist when adding contact persons
 */

export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';
export const GET_USER_BY_EMAIL_SUCCESS = 'GET_USER_BY_EMAIL_SUCCESS';
export const GET_USER_BY_EMAIL_FAILED = 'GET_USER_BY_EMAIL_FAILED';
export const RESET_USER = 'RESET_USER';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const status = {
  INIT: 'INIT',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED',
  SUBMITTING: 'SUBMITTNG',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAILED: 'SUBMIT_FAILED',
};

export function getUserByEmail (data) {
  return {
    type: GET_USER_BY_EMAIL,
    payload: data,
  };
}

export function resetUser () {
  return {
    type: RESET_USER,
  };
}

export function createUser (data) {
  return {
    type: CREATE_USER,
    payload: data,
  }
}

const initialState = {
  status: status.INIT,
  user: null,
  error: null,
  newUser: null,
  newUserStatus: status.INIT,
  newUserError: null,
};

export default function user(state = initialState, action) {

  if (!action) {
    return state;
  }

  switch (action.type) {
    case GET_USER_BY_EMAIL:
      return Object.assign({}, state, {status: status.FETCHING});
    case GET_USER_BY_EMAIL_SUCCESS:
      return Object.assign({}, state, {status: status.FETCHED, user: action.payload});
    case GET_USER_BY_EMAIL_FAILED:
      return Object.assign({}, state, {status: status.FAILED, user: null, error: action.payload});
    case RESET_USER:
      return Object.assign({}, initialState);
    case CREATE_USER:
      return Object.assign({}, state, {newUserStatus: status.SUBMITTING});
    case CREATE_USER_SUCCESS:
      return Object.assign({}, state, {newUserStatus: status.SUBMIT_SUCCESS, newUser: action.payload});
    case CREATE_USER_FAILED:
      return Object.assign({}, state, {newUserStatus: status.SUBMIT_FAILED, newUser: null, newUserError: action.payload});
    default:
      return state;
  }
}
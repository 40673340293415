import React from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import styled from 'styled-components';
import EventRouter from './Event/EventRouter';
import VolunteerContextRouter from './VolunteerContext/VolunteerContextRouter';
import TeamLeaderRouter from './TeamLeader/TeamLeaderRouter';
//import Toolbar from '../../components/Toolbar';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const MyPagesRouter = ({ match }) => {
  const defaultLocation = localStorage.getItem('homePath') || 'volunteers';
  return (
    <Wrapper>
      {/* <Toolbar /> */}
      <Switch>
        <Route exact path={`${match.url}`} component={() => <Redirect to={`/my-pages/${defaultLocation}`} />} />
        <Route path={`${match.url}/events`} component={EventRouter} />
        <Route path={`${match.url}/volunteers`} component={VolunteerContextRouter} />
        <Route path={`${match.url}/teamleaders`} component={TeamLeaderRouter} />
      </Switch>
    </Wrapper>
  );
};

export default MyPagesRouter;

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { GoogleMap, StandaloneSearchBox, Marker, useJsApiLoader } from '@react-google-maps/api';
import { injectIntl } from 'react-intl';
import messages from './messages';
import Geocode from 'react-geocode';
import memoize from 'memoize-one';
import { API_KEY } from "./apiConfig";

Geocode.setApiKey(API_KEY);
Geocode.setLanguage("sv");
Geocode.setRegion("se");


// Coordinate bounds for sweden
const SWEDEN_NE = {lat: 69.1062472602, lng: 23.9033785336};
const SWEDEN_SW = {lat: 55.3617373725, lng: 11.0273686052};

const containerStyle = {
  height: '100%',
  width: '100%',
  alignContent: 'center',
  marginBottom: '15px'
};

const inputStyle = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `50%`,
  height: `100%`,
  padding: `0 12px`,
  borderRadius: `3px`,
  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
  fontSize: `24px`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: 'relative',
  top: 15,
  left: 215
};

const libraries = ['places'];

function MapsComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    region: 'se',
    googleMapsApiKey: API_KEY,
    libraries: libraries
  })

  const [mapRef, setMapRef] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(props.markerLocation);
  const [showMarker, setShowMarker] = useState(props.showMarker);
  const [markerAnimation, setMarkerAnimation] = useState(2); //Animation 2 == DROP

  const positionMarker = (coordinates) => {
    if(!showMarker){
      setMarkerAnimation(2);
      setShowMarker(true);
    }
    setMarkerPosition(coordinates);
    props.onMarkerChange(coordinates);
  }

  const bounceMarker = () => {
    setMarkerAnimation(1); // Animation 1 == BOUNCE, one bounce = 750ms
    setTimeout(() => setMarkerAnimation(null), 750); // this means it will bounce once
  }

  const onMapClick = (e) => {
    if (props.readOnly) 
      return;
    
    if (showMarker)
      bounceMarker();
    else {
      const coordinates = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      };
      positionMarker(coordinates);
    }
  }

  const onMapDblClick = (e) => {
    if (props.readOnly)
      return;
    
    const coordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };

    positionMarker(coordinates);
  }

  const onMarkerRightClick = (e) => {
    if (props.readOnly)
      return;

    setShowMarker(false);
    setMarkerPosition(null);
    props.onMarkerRemove();
  }

  const onMarkerClick = (e) => {
    //TODO: make the map fitbounds on marker location
  }

  const onMarkerDragEnd = (e) => {
    bounceMarker();

    const coordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };

    positionMarker(coordinates);
  };

  const onSearch = () => {
    const place = searchBox.getPlaces()[0];
    if(place.plus_code) { //If searching for a coordinate you probably want the pin to go exactly there
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      bounceMarker();
      positionMarker(coordinates);
    }
    mapRef.fitBounds(place.geometry.viewport);
    props.onSearch(place);
  }

  const onLoad = map => {
    initBounds();
    setMapRef(map);
  }

  const initBounds = useCallback(function callback() {
      // Set coordinate bounds for the search box
      // Can only access maps API in this function
      const ne = new window.google.maps.LatLng(SWEDEN_NE);
      const sw = new window.google.maps.LatLng(SWEDEN_SW);
      setBounds(new window.google.maps.LatLngBounds(sw, ne));
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.center}
      onClick={onMapClick}
      zoom={props.zoom}
      onLoad={onLoad}
      onDblClick={onMapDblClick}
      options={{disableDoubleClickZoom: true}}
    >
      {props.showSearchBox &&
        <StandaloneSearchBox
          onLoad={setSearchBox}
          onPlacesChanged={onSearch}
          bounds={bounds}
        >
          <input
            type='text'
            placeholder= {props.intl.formatMessage(
              messages.inputFieldPlaceholder)}
            style={inputStyle}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} // Prevent the enter key from submitting forms when searching
          />
        </StandaloneSearchBox>
      }
      <Marker
        draggable={!props.readOnly}
        visible={showMarker || props.readOnly}
        position={markerPosition}
        onDragEnd={onMarkerDragEnd}
        onRightClick={onMarkerRightClick}
        onClick={onMarkerClick}
        animation={markerAnimation}
      />
    </GoogleMap>
  ) : <></>
}

export function formatCoordinates(coordinates) {
  return "( " + coordinates.lat.toFixed(6) + " , " + coordinates.lng.toFixed(6) + " )"
}

export const getCoordinatesFromLocation = memoize(
  location => Geocode.fromAddress(location).then(
    response => { 
      return response.results[0].geometry.location;
    },
    error => {
      console.error(error);
      return null;
    }
  )
)

export const getLocationFromCoordinates = memoize(
  coordinates => Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
    response => {
      return response.results[0].formatted_address;
    },
    error => {
      console.error(error);
      return null;
    }
  )
)

MapsComponent.propTypes = {
  center: PropTypes.object.isRequired,
  showSearchBox: PropTypes.bool.isRequired,
  zoom: PropTypes.number.isRequired,
  onSearch: PropTypes.func,
  onMarkerChange: PropTypes.func,
  markerLocation: PropTypes.object,
  showMarker: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default injectIntl(MapsComponent);
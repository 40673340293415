import { defineMessages } from 'react-intl';

export default defineMessages({
  introText1: {
    id: 'register.intro-text-1',
  },
  introText2: {
    id: 'register.intro-text-2',
  },
  introText3: {
    id: 'register.intro-text-3',
  },
  form: {
    email: {
      id: 'register.form.email.placeholder',
    },
    firstName: {
      id: 'register.form.firstName.placeholder',
    },
    lastName: {
      id: 'register.form.lastName.placeholder',
    },
    countryCode: {
      id: 'register.form.countryCode.placeholder',
    },
    phone: {
      id: 'register.form.phone.placeholder',
    },
    password: {
      id: 'register.form.password.placeholder',
    },
    repeatPassword: {
      id: 'register.form.repeatPassword.placeholder',
    },
  },
  passwordRules: {
    title: {
      id: 'register.passwordRules.title',
    },
    lowerCase: {
      id: 'register.passwordRules.lowerCase',
    },
    upperCase: {
      id: 'register.passwordRules.upperCase',
    },
    number: {
      id: 'register.passwordRules.number',
    },
    length: {
      id: 'register.passwordRules.length',
    },
  },
  loginButton: {
    id: 'register.login-button',
  },
  registerButton: {
    id: 'register.register-button',
  },
  error: {
    UserNotFoundException: {
      id: 'login.error.user-not-found',
    },
    ResourceNotFoundException: {
      id: 'login.error.cognito-service-not-found',
    },
    UserNotConfirmedException: {
      id: 'login.error.user-not-confirmed',
    },
    InvalidParameterException: {
      id: 'login.error.invalid-parameter',
    },
    InvalidPhoneNumber: {
      id: 'login.error.invalid-phone-number',
    },
    default: {
      id: 'login.error.unknown-error',
    },
  },
});

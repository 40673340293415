import { connect } from "react-redux";
import {
  resetPasswordSubmit,
  sendForgotPasswordCode,
  resetState
} from "./reducer";
import ForgotPassword from "./ForgotPassword";

const mapStateToProps = state => {
  return {
    submitError: state.forgotPassword.error,
    status: state.forgotPassword.status
  };
};

const mapDispatchToProps = dispatch => ({
  resetPasswordSubmit: data => dispatch(resetPasswordSubmit(data)),
  sendForgotPasswordCode: data => dispatch(sendForgotPasswordCode(data)),
  resetState: data => dispatch(resetState(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

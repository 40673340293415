const colors = {
  text: '#585a5f',
  grayStartGradient: '#757378',
  gray: '#585a5f',
  lightGray: '#ebe7ea',
  lightGray2: '#d0d3d9',
  lightGray3: '#fcfcfc',
  lightGray4: '#bcbcc1',
  lightGray5: '#f9f8f9',
  lightGray6: '#e7e3e5',
  mediumLightGray: '#949398',
  pink: '#ef7f78 ',
  pinkEndGradient: '#d6686b',
  darkPink: '#eb6c6d',
  lightPink: '#fbf0ee',
  lightPink2: '#f8d9d7',
  lightPink3: '#ed807a',
  mediumPink: '#f7dfda',
  orange: '#f2746d',
  green: '#00adba',
  darkGreen: '#009fac',
  greenEndGradient: '#009aa2',
  lightGreen: '#e2f0f1',
  lighterGreen: '#bdddd8',
  inputPlaceholderGrey: '#BCBCC1',
};

const breakPoints = {
  // To be used with max-width. Min-width needs +1
  lg: 1199,
  md: 991,
  sm: 767,
  xs: 575,
};

const headerHeight = '75';

const buttonStyles = {
  fontSize: '18px',
  color: '#FFF',
  padding: '23px 70px',
  borderRadius: '5px',
};

export {
  colors,
  breakPoints,
  headerHeight,
  buttonStyles
};

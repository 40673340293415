import { defineMessages } from 'react-intl';

export default defineMessages({
  attendance: {
    form: {
      name: {
        id: 'attendance.form.name',
      },
      start: {
        id: 'attendance.form.start',
      },
      end: {
        id: 'attendance.form.end',
      },
      officialQuantity: {
        id: 'attendance.form.officialQuantity',
      },
      officialMinimumAge: {
        id: 'attendance.form.officialMinimumAge',
      }
    },
    offerError: {
      id: 'attendance.offer-error'
    },
    noOffers: {
      id: 'attendance.no-offers'
    }
  }
});

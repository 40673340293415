import React, { Component, Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Field, reset, reduxForm } from "redux-form";
import { required, email, integer } from "../../../../validators/index";
import { groupStatus } from "../reducer";
import messages from "./messages";
import { Row, Col } from "react-bootstrap/lib";
import { positiveNumbers } from "../../../../validators/index";
import {
  Input,
  FormSectionTitle,
  FormGroup,
  GeneralError,
  ListSelect,
  Select,
  H4,
  Checkbox,
} from "../../../../components";
import {
  BigGreenButton,
  BigGrayButton,
} from "../../../../components/Buttons/index";
import { v4 as uuidv4 } from "uuid";
import { lists } from "../../../../constants/lists.js"; // TODO: Add updated volex-data repository

const { foodOptions, shirtSizes } = lists;

function validateEmailComparison(newVolunteer, volunteers, volunteerToEdit) {
  const errors = {};
  if (
    !newVolunteer.email ||
    (volunteers.find((v) => v.email === newVolunteer.email) &&
      (!volunteerToEdit ||
        (volunteerToEdit && volunteerToEdit.email !== newVolunteer.email)))
  ) {
    errors.email = (
      <GeneralError>
        <FormattedMessage
          {...messages.volunteers.form.emailAlreadyExistError}
        />
      </GeneralError>
    );
  }
  return Object.keys(errors).length > 0 ? errors : null;
}

const withLoadedProps = (LoadedComponent, loadedProps) => (props) =>
  <LoadedComponent {...loadedProps} {...props} />;

class VolunteerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readonly: false, // !this.isNew(),
      newVolunteerErrors: null,
      submitting: false,
      emailInvalid: null,
      buttonDisabled: false,
      substituteCheckbox: false,
    };

    this.handleVolunteerSubmit = this.handleVolunteerSubmit.bind(this);
    this.onContactFieldChange = this.onContactFieldChange.bind(this);
    this.fillFormWithVolunteer = this.fillFormWithVolunteer.bind(this);
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.pathname === "/my-pages/volunteers/new"
    ) {
      window.scrollTo(0, 0);
    }

    if(this.props.volunteerToEdit){
      this.fillFormWithVolunteer(this.props.volunteerToEdit);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.volunteerToEdit !== this.props.volunteerToEdit &&
      nextProps.volunteerToEdit !== null &&
      !this.state.submitting
    ) {
      this.fillFormWithVolunteer(nextProps.volunteerToEdit);
    }
    if (nextProps.status === groupStatus.SUCCESS && this.state.submitting) {
      this.setState({
        submitting: false,
      });
      this.props.reset();
    }
  }

  isNew() {
    return !this.props.match.params.id;
  }

  handleVolunteerSubmit(fieldValues) {
    this.setState({ buttonDisabled: true });
    setTimeout(() => this.setState({ buttonDisabled: false }), 500);
    const specialDiets = fieldValues.specialDiets ? (fieldValues.specialDiets || []).filter((v) => !!v) : null;
    const participant = []
    const participate = {}
    participate.eventId = this.props.offer ? this.props.offer.eventId : null;

    if(fieldValues.shirtSize || fieldValues.specialDiets || fieldValues.substitute){
      const shirtSize = (fieldValues.shirtSize || []);

      if(!fieldValues.shirtSize){
        participate.specialDiets = specialDiets
      }
      else if(!fieldValues.specialDiets){
        participate.shirtSize = shirtSize
      }
      else{
        participate.specialDiets = specialDiets
        participate.shirtSize = shirtSize
      }

      if(fieldValues.substitute && this.state.substituteCheckbox){
        participate.substitute = fieldValues.substitute
      }

      participant.push(participate)
    }

    const newVolunteer = Object.assign({}, fieldValues);
    const volunteersList = this.props.group.volunteers
      ? this.props.group.volunteers.slice()
      : [];
    const errors = validateEmailComparison(
      newVolunteer,
      volunteersList,
      this.props.volunteerToEdit
    );
    if (!errors && !this.state.emailInvalid) {
      this.setState({
        newVolunteerErrors: null,
        submitting: false,
      });

      if (this.props.volunteerToEdit) {
        if(this.props.volunteerToEdit.participation){
          newVolunteer.participation = this.props.volunteerToEdit.participation;
        }

        if(newVolunteer.participation && this.props.offer){
          const index = newVolunteer.participation.findIndex((event) => event.eventId === this.props.offer.eventId)
          index === -1 ? newVolunteer.participation.push(participate) : newVolunteer.participation[index] = participate
        }
        else{newVolunteer.participation = participant}

        const updatedVolunteerList = volunteersList.map((v) =>
          v.id === this.props.volunteerToEdit.id
            ? (v = Object.assign({}, newVolunteer, {
                id: this.props.volunteerToEdit.id,
              }))
            : v
        );
        if(updatedVolunteerList)
        this.props.saveGroup(updatedVolunteerList);
      } else {
        newVolunteer.id = uuidv4();

        if(participant.length > 0 && participate.eventId !== null){
          newVolunteer.participation = participant
        }
        volunteersList.push(newVolunteer);
        this.props.saveGroup(volunteersList);
        if (typeof this.props.goBackToList === "function") {
          this.props.goBackToList();
        }
      }
    } else {
      this.setState({
        newVolunteerErrors: errors,
      });
    }
  }

  onContactFieldChange(e) {
    const newVolunteer = { email: e.target.value };
    const volunteersList = this.props.group.volunteers
      ? this.props.group.volunteers.slice()
      : [];
    const volunteerToEdit = this.props.volunteerToEdit;
    const error = validateEmailComparison(
      newVolunteer,
      volunteersList,
      volunteerToEdit
    );

    this.setState({
      emailInvalid: e.target.validity.typeMismatch,
      newVolunteerErrors: error,
    });
  }

  fillFormWithVolunteer(volunteer) {
    this.props.change("name", volunteer.name);
    this.props.change("email", volunteer.email);
    this.props.change("phone", volunteer.phone);
    this.props.change("age", volunteer.age);

    if(this.props.offer){
      const found = volunteer.participation ? volunteer.participation.find((participations) => participations.eventId === this.props.offer.eventId) : undefined;
      if(found !== undefined && found.specialDiets){
        this.props.change("specialDiets", found.specialDiets);
      }
      if(found !== undefined && found.shirtSize){
        this.props.change("shirtSize", found.shirtSize);
      }
      if(found !== undefined && found.substitute){
        this.setState({substituteCheckbox: true})
        this.props.change("substitute", found.substitute)
        
      }
    }
  }

  renderVolunteerFormGroup() {
    if (this.state.readonly) {
      return this.renderVolunteers();
    }

    const includedSpecialDietsTranslated = this.props.offer && this.props.offer.foodOptions
      ? this.props.offer.foodOptions.map((opt) => ({
          id: opt,
          label: this.props.intl.formatMessage({
            id: `food.${opt.toLowerCase()}`,
          }),
        }))
      : null;
    const includedShirtSizesTranslated = this.props.offer && this.props.offer.shirtSizes
      ? this.props.offer.shirtSizes.map((opt) => ({
          id: opt,
          label: this.props.intl.formatMessage({
            id: `shirt-size.${opt.toLowerCase()}`,
          }),
        }))
      : null;

    const styleRow =
      typeof this.props.goBackToList === "function"
        ? { display: "flex", justifyContent: "center" }
        : {};

    const errors = this.state.newVolunteerErrors || {};

    return (
      <form
        noValidate
        onSubmit={this.props.handleSubmit(this.handleVolunteerSubmit)}
      >
        <Row style={styleRow}>
          <Col sm={8}>
            <FormGroup>
              <FormSectionTitle>
                <FormattedMessage {...messages.volunteers.personalInfoTitle} />
              </FormSectionTitle>
              <Field
                name="name"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.name
                )}
                type="text"
                component={Input}
                validate={[required]}
                readonly={this.state.readonly}
              />
              <Field
                name="email"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.email
                )}
                type="email"
                component={Input}
                validate={[required, email]}
                onChange={this.onContactFieldChange}
                readonly={this.state.readonly}
              />
              {errors.email || null}
              <Field
                name="phone"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.phone
                )}
                type="tel"
                component={Input}
                validate={[required]}
                readonly={this.state.readonly}
              />
              <Field
                name="age"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.age
                )}
                type="number"
                component={Input}
                validate={[required, positiveNumbers, integer]}
                readonly={this.state.readonly}
              />
              {this.props.offer && this.props.offer.substitute === "active" ?
              <div>
                <H4><FormattedMessage {...messages.volunteers.form.substituteTitle}/></H4>
                <Checkbox
                  id={this.props.offer && this.props.offer.station ? this.props.offer.station.name : "No Station"}
                  onChange={() => this.setState({substituteCheckbox: !this.state.substituteCheckbox})}
                  label={" " + this.props.intl.formatMessage(messages.volunteers.form.substituteOption)}
                  checked={this.state.substituteCheckbox}
                />
              </div>
              : null}
              {this.state.substituteCheckbox ?
              <div>
                <H4>  </H4>
                <Field
                name="substitute"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.substitute
                )}
                type="text"
                component={Input}
                readonly={this.state.readonly}
                validate={[required]}
                />
              </div>
              : null
              }
            </FormGroup>
            {this.props.offer ? (this.props.offer.foodOptions || this.props.offer.shirtSizes ? (
              <FormGroup>
                <FormSectionTitle>
                  <FormattedMessage {...messages.volunteers.otherInfoTitle} />
                </FormSectionTitle>
                {this.props.offer.foodOptions ? (
                  <>
                    <H4><FormattedMessage {...messages.volunteers.form.foodOptionsTitle}/></H4>
                    <Field
                      name="specialDiets"
                      component={withLoadedProps(ListSelect, {
                        display: "inline-block",
                      })}
                      valueField="id"
                      textField="label"
                      data={includedSpecialDietsTranslated}
                    />
                  </>
                ) : null}
                {this.props.offer.shirtSizes ? (
                  <>
                    <H4><FormattedMessage {...messages.volunteers.form.shirtSizesTitle}/></H4>
                    <Field
                      name="shirtSize"
                      placeholder={this.props.intl.formatMessage(
                        messages.volunteers.form.shirtSize
                      )}
                      component={Select}
                      valueField="id"
                      textField="label"
                      data={includedShirtSizesTranslated}
                      validate={[required]}
                    />
                  </>
                ) : null}
              </FormGroup>
            ) : null) : null}
            {!this.state.readonly ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {typeof this.props.goBackToList === "function" && (
                  <BigGrayButton
                    onClick={() => this.props.goBackToList()}
                    label={this.props.intl.formatMessage(
                      messages.volunteers.form.goBack
                    )}
                  />
                )}
                <BigGreenButton
                  disabled={
                    this.state.buttonDisabled || this.state.newVolunteerErrors
                  }
                  busy={this.state.submitting}
                  label={this.props.intl.formatMessage(
                    messages.volunteers.form.addButton
                  )}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    return <Fragment>{this.renderVolunteerFormGroup()}</Fragment>;
  }
}

const afterSubmit = (result, dispatch) => dispatch(reset("volunteer"));

export default reduxForm({
  form: "volunteer",
  onSubmitSuccess: afterSubmit,
})(injectIntl(VolunteerForm));


import { Auth, API } from 'aws-amplify';

/* export const createGroup = data => new Promise(((resolve, reject) => {
  setTimeout(() => {
    resolve();
  }, 2000);
}));*/

export const createGroup = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: data,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.post('volex-protected', '/groups', request);
    });

export const updateGroup = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: data,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.put('volex-protected', `/groups/${data.id}`, request);
    });

export const getGroupsSummaries = () => 
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', '/groups/summaries', request);
    });

export const fetchGroup = ({id}) => 
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/groups/${id}`, request);
    });

export const confirmGroup = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: {},
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.post('volex-protected', `/groups/${data.id}/confirm`, request);
    });

import { Auth, API } from 'aws-amplify';

export const putGroupAvailabilities = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: data.availabilities,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.put('volex-protected', `/groups/${data.groupId}/availabilities`, request);
    });

export const getGroupAvailability = ({groupId}) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/groups/${groupId}/availabilities`, request);
    });

export const getAvailableGroups = data => 
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      const queryParameters = new URLSearchParams();
      Object.keys(data).forEach((key) => {
        queryParameters.append(key, data[key]);
      });
      return API.get('volex-protected', `/groups/available?${queryParameters.toString()}`, request);
    });

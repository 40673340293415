import React, { Component } from "react";
import { H2, WhiteTable, TableStatus, TableRowWithClick, TableRow, ConfirmationModal, Checkbox } from "../../../../components/index";
import { TableButton, ToggleButton, DeleteButton } from "../../../../components/Buttons/index";
import styled from "styled-components";
import { breakPoints } from "../../../../constants/styling-constants";
import { withRouter } from "react-router";
import messages from "../messages";
import { FormattedMessage, injectIntl } from "react-intl";

const Table = styled(WhiteTable)`
  @media (max-width: ${breakPoints.sm}px) {
    .table-button-col {
      display: none;
    }
    .table-status-col {
      display: none;
    }
  }
`;

const TableRowHeader = TableRow.extend`
  th {
    background: transparent;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 85px;
`;

class EventList extends Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemoveEvent = this.handleRemoveEvent.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.togglePreviousEvents = this.togglePreviousEvents.bind(this);

    this.state = {
      sortField: "date",
      sortDirection: -1,
      eventToBeRemoved: {},
      showRemovalModal: false,
      removalCheckboxChecked: false,
      removalError: false,
      removalPending: false,
      pastEvents: false
    }
  }

  componentDidMount() {
    this.props.getEventsSummaries();
  }

  handleClick(row) {
    this.props.history.push(`/my-pages/events/${row.id}`);
  }

  handleHide() {
    this.setState({
      showRemovalModal: false,
      eventToBeRemoved: {},
      removalPending: false,
      removalError: false,
      removalCheckboxChecked: false,
    });
  }

  handleRemoveEvent(event) {
    if(this.state.removalCheckboxChecked) {
      this.setState({removalPending: true})
      setTimeout(() => this.setState({
        showRemovalModal: false,
        eventToBeRemoved: {},
        removalPending: false,
        removalError: false,
        removalCheckboxChecked: false,
      }), 500);
      this.props.removeEvent(event.id);
    } else {
      this.setState({removalError: true});
    }
  }

  togglePreviousEvents() {
    this.setState({pastEvents: !this.state.pastEvents})
  }

  renderStatus(status) {
    switch (status) {
      case "CONFIRMED":
        return (<TableStatus><FormattedMessage {...messages.statusApproved} /></TableStatus>);
      case "DRAFT":
        return (<TableStatus className="draft"><FormattedMessage {...messages.statusUnconfirmed} /></TableStatus>);
      case "HISTORIC":
        return (<TableStatus className="historic"><FormattedMessage {...messages.statusHistoric} /></TableStatus>);
      default:
        return (<TableStatus className="historic"><FormattedMessage {...messages.statusUnknown} /></TableStatus>);
    }
  }
  getDate(date) {
    return date.getFullYear() + "-" + (date.getMonth()<9 ? "0" : "") + (date.getMonth()+1)  + "-" + (date.getDate() <10 ? "0" : "") + date.getDate();
  }

  renderRow(row, past=false) {
    const buttonTitle = this.props.buttonTitle ? this.props.buttonTitle : messages.myEvents.show;
    return (
      <TableRowWithClick
        key={row.id}
        row={row}
        callback={this.props.handleClick ? this.props.handleClick : this.handleClick}
      >
        <td>{row.name}</td>
        <td className={"right"}>{row.date ? this.getDate(new Date(row.date))  : ""}</td>
        <td className={'table-status-col'}>{this.renderStatus(row.status)}</td>
        <td className="right small-cell table-status-col"><TableButton className={'table-button-col'} style={{visibility: past ? 'hidden' : 'visible'}} label={this.props.intl.formatMessage(buttonTitle)} /></td>
        <td className="no-background small-cell table-status-col">
          <DeleteButton className={row.status === "DRAFT" ? "" : "hidden2"} onClick={(e) => {e.stopPropagation(); this.setState({eventToBeRemoved: row, showRemovalModal: true})}}/>
        </td>
      </TableRowWithClick>
    );
  }
  renderTableHeader() {
    return (
    <TableRowHeader>
      <th onClick={() => this.state.sortField === "name" ? this.setState({sortDirection:this.state.sortDirection*-1}) : this.setState({sortField:"name"})}><FormattedMessage {...messages.myEvents.header.eventTitle} /></th>
      <th className={"right "} onClick={() => this.state.sortField === "date" ? this.setState({sortDirection:this.state.sortDirection*-1}) : this.setState({sortField:"date"})}><FormattedMessage {...messages.myEvents.header.eventDate} /></th>
      <th className={'table-status-col'} onClick={() => this.state.sortField === "status" ? this.setState({sortDirection:this.state.sortDirection*-1}) : this.setState({sortField:"status"})}><FormattedMessage {...messages.myEvents.header.eventStatus} /></th>
      <th></th>
    </TableRowHeader>
    )
  }

  renderEventsTable(events, past=false) {
    
      if (events.length === 0) {
      // User has no events, show no events message
      return (
        <Table>
          <tbody>
            <TableRow>
              <td><FormattedMessage {...messages.myEvents.list.noEventsMessage} /></td>
            </TableRow>
          </tbody>
        </Table>
      );
    }
    return (
      <Table>
        <tbody>
          {this.renderTableHeader()}
          {events.sort((a,b) => a[this.state.sortField] ? -1 : 1).sort((a, b) => a && b && a[this.state.sortField] && b[this.state.sortField] ? this.state.sortDirection * a[this.state.sortField].localeCompare(b[this.state.sortField]) : 0).map(e => this.renderRow(e, past))}
        </tbody>
      </Table>
    );
  }

  render() {
    const title = this.props.title ? this.props.title : messages.myEvents.title;
    const eventList = this.props.eventsSummaries ? this.props.eventsSummaries.filter(event => !event.date ? false : new Date(event.date) > new Date().setDate(new Date().getDate() - 7)) : null;
    const pastList = this.props.eventsSummaries ? this.props.eventsSummaries.filter(event => !eventList.some(e => e.id === event.id)) : null;

    if(pastList){
      pastList.map(event => {if(event.status === "CONFIRMED") {this.props.setHistoric({id: event.id})}});
    }

    //const eventLista = this.props.eventsSummaries ? this.props.eventsSummaries.filter(event => !event.date ? false : event.date.localeCompare(new Date().toISOString()) >= 0) : null;
    //const pastLista = this.props.eventsSummaries ? this.props.eventsSummaries.filter(event => !event.date ? true : event.date.localeCompare(new Date().toISOString()) < 0) : null;
    return (
      <Wrapper>
        <ConfirmationModal
          show={this.state.showRemovalModal}
          handleHide={this.handleHide}
          title={this.props.intl.formatMessage(messages.myEvents.removalModal.title) + " " + this.state.eventToBeRemoved.name}
          text={this.props.intl.formatMessage(messages.myEvents.removalModal.body)}
          theme={'pink'}
          handleConfirm={() => this.handleRemoveEvent(this.state.eventToBeRemoved)}
          children={this.state.removalPending ?
            <i className="fas fa-spinner fa-pulse fa-3x"/> :
            <Checkbox
              id={"checkbox"}
              checked={this.state.removalCheckboxChecked}
              onChange={() => this.setState({removalCheckboxChecked: !this.state.removalCheckboxChecked})}
              label={" " + this.props.intl.formatMessage(messages.myEvents.removalCheckbox)}
              disabled={false}
              className={(this.state.removalError ? "error" : "")}
            />}
        />
        <H2><FormattedMessage {...title} /></H2>
        {(this.props.eventsSummaries) ? this.renderEventsTable(eventList) : <i className="fas fa-spinner fa-pulse" />}
        {(this.props.eventsSummaries) ? <ToggleButton icon={this.state.pastEvents ? "fas fa-chevron-down" : "fas fa-chevron-up"} callback={this.togglePreviousEvents} label={this.props.intl.formatMessage(messages.myEvents.showPrevious)} /> : ""}
        {(pastList && this.state.pastEvents) ? this.renderEventsTable(pastList, true) : ""}
      </Wrapper>
    );
  }
}

export default withRouter(injectIntl(EventList));
